<template>
  <v-card class="pa-0 ma-0" rounded="xl" color="background" elevation="4">
    <v-card-title>
      Set Roster
    </v-card-title>
    <v-card-subtitle>
      active: {{pstate.active}} inactive: {{pstate.inactive}} guests: {{pstate.guest}}
    </v-card-subtitle>

    <v-card-text>
      <v-row>
        <v-col cols="4" class="pa-0 ma-0">
          <h3>Name</h3>
        </v-col>
        <v-col cols="3" class="pa-0 ma-0">
          <h3>Jersey</h3>
        </v-col>
        <v-col cols="auto" class="pa-0 ma-0">
          <h3>Game State</h3>
        </v-col>
      </v-row>

      <v-row v-for="p in playerList" v-bind:key="p.player_id">
        <v-col cols="4" class="py-0 px-0 pb-1">
          {{ p.name }}
        </v-col>
        <v-col cols="3" class="py-0 px-0 pb-1">
          {{ p.jersey_number }}
        </v-col>
        <v-col cols="auto" class="py-0 px-0 pb-1">
          <v-btn rounded="xl" v-if="p.player_status=='Active'" @click="deactivate(p.player_id)" color="green">Active</v-btn>
          <v-btn rounded="xl" v-if="p.player_status!='Active'" @click="activate(p.player_id)" color="red">InActive</v-btn>
          <v-chip v-if="p.guest_player">Guest Player</v-chip>
        </v-col>
      </v-row>
    </v-card-text>

  </v-card>
</template>

<script lang="ts" setup>
import { ref } from '@vue/reactivity'
import { defineEmits, onMounted } from 'vue'
// import { useRoute } from 'vue-router'
import { useGameState } from '../store/index'
const store = useGameState()
// const route = useRoute()
const emits = defineEmits(['roster'])
const playerList = ref([
  {
    player_id: '',
    name: '',
    first_name: '',
    last_name: '',
    jersey_number: 0,
    player_status: '',
    guest_player: false,
    Team: '',
    age: 0,
    gender: ''
  }
])
const pstate = ref({
  active: 0,
  inactive: 0,
  guest: 0
})

const getPlayers = async () => {
  playerList.value = await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/getTeamPlayers/' + store.OrgID + '/' + store.TeamID).then((res) => res.json())
  
  // console.log(playerList.value)
  getNumActive()
}

const deactivate = (pid) => {
  const oidx = playerList.value.findIndex(obj => obj.player_id === pid)
  playerList.value[oidx].player_status = 'Inactive'
  getNumActive()
}

const activate = (pid) => {
  const oidx = playerList.value.findIndex(obj => obj.player_id === pid)
  playerList.value[oidx].player_status = 'Active'
  getNumActive()
}

const getNumActive = () => {
  let a = 0
  let i = 0
  let g = 0
  const roster2 = []

  playerList.value.forEach(function (p) {
    if (p.player_status === 'Active') {
      a++
      roster2.push(p)
    } else {
      i++
    }
    if (p.guest_player) {
      g++
    }
  })

  pstate.value = {
    active: a,
    inactive: i,
    guest: g
  }

  emits('roster', roster2)
}

onMounted(() => {
  getPlayers()
})

</script>

<template>
  <v-card class="pa-4 ma-0">
    <v-card-title class="pa-0 ma-0" >
        Coach Notes
    </v-card-title>
    <v-card-text class="pa-0 ma-0" >
      <v-textarea
        v-model="notes"
        label="Coaches note">
      </v-textarea>
      <v-btn rounded="xl" color="blue" @click="saveNote()">Save</v-btn>
    </v-card-text>
  </v-card>
</template>

<script lang="ts" setup>
import { ref, defineEmits } from 'vue'
import { useGameState } from '../store/index'

const store = useGameState()
const emits = defineEmits(['cb'])
// const props = defineProps(['formationid'])
const notes = ref('')
// const formationid = ref(0)

const saveNote = async () => {
  const logData = {
    GameID: store.GameID,
    Author: store.auth.user.name,
    Note: notes.value,
    GameTime: store.GameTime,
    GameTimestamp: store.lastTimeStamp,
    FormationName: store.teamFormation // formationid.value
  }

  const logs = JSON.stringify(logData)

  await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/gm/addCoachLog', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'BEARER: ' + store.AuthToken },
    body: logs
  })

  emits('cb', true)
}

// onMounted(async () => {
//   const formationData = await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/getFormations/' + store.OrgID + '/' + store.TeamID).then((res) => res.json())
//   formationid.value = formationData.ID
//   console.log(formationid.value)
// })

</script>


import { useRouter } from 'vue-router'


function getCookie(cname) {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  // console.log(decodedCookie)
  const ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export async function getPerms (store) {
  const router = useRouter()
  // console.log(getCookie('Token'))

  if (getCookie('Token') !== '') {
    // console.log('Logged in')
    store.TAC = true
    store.auth.loggedIn = true
    store.AuthToken = getCookie('Token')
    store.auth.user = {
      uid: getCookie('UID'),
      name: getCookie('Name'),
    }
  }  // else {
  //   console.log('Not logged in')
  //   // get data, save into store
  //   router.push('/login')
  //   store.auth.loggedIn = true
  //   store.TAC = true
  //   store.AuthToken = getCookie('Token')
  // }

  if (store.AuthToken !== '') {
    // const permCheck = {
    //   UID: store.auth.user.uid,
    //   OrgID: store.OrgID,
    //   TeamID: store.TeamID
    // }
    // const data = JSON.stringify(permCheck)
    const res = await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/getUserInfo',
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'BEARER: ' + store.AuthToken }
      }).then(response => {
        const data = response.json()
        // console.log(data)

        if (response.status === 401) {
          // store.setUserData([])
          store.auth = {
            loggedIn: false,
            user: {
            name: '',
            uid: '',
            }
          }
          
          router.push('/login')
          // return Promise.reject(response.statusText)
        }
        if (response.status !== 200) {
          // loginError.value = response.status.toString()
          return // Promise.reject(response.statusText)
        }
        return data
      }).catch(error => {
        // loginError.value = error
        console.error(error)
        // return
      })
    
    // console.log(res)
    // res.orgs.forEach(o =>{
    //   console.log(o.name)
    //   console.log(o.Role)
    // })

    store.setOrgs(res.orgs)
    store.auth.user.uid = res.uid
    store.auth.user.profile_pic = res.profile_pic
    store.timeZone = res.TimeZone
    // return { RBAC: 'Viewer' }
    // FIXME
    return { RBAC: 'GlobalAdmin' }
   
  } else {
      console.log('Not logged in')
      // get data, save into store
      router.push('/login')

    // return { RBAC: 'Viewer' }
  }
}

export const PermissionLevels = [
  { Viewer: 1 },
  { Statistician: 3 },
  { Coach: 5 },
  { OrgManager: 8 },
  { GlobalAdmin: 55 }
]

// export function getPermLevel (store) {
//   const p = getPerms(store)
//   console.log(p)
// }

// const checkPermissions = async () => {
//   const perms = await getPerms(store)

//   // console.log(perms)

//   if (perms.RBAC === 'Viewer') {
//     admin.value = false
//   } else {
//     admin.value = true
//   }
// }

<template>
    <v-sheet class="pa-0 ma-0" color="background"  rounded="xl">

      <v-card elevation="0">
        <v-card-title>
          Game Stats
        </v-card-title>
        <v-card-text>
          <v-row>
           <v-col cols="auto">

            <v-col sm="8" xs="12">
              <v-row>
                <v-col class="pa-1 ma-2" align="left" cols="auto">
                  <v-btn rounded="xl" :disabled="!btnEnable" @click="increment('goal_us')" color="green" size="35">+1</v-btn> Score Us <v-btn rounded="xl" :disabled="!btnEnable" @click="increment('remove_goal_us')" color="red" size="35">-1</v-btn>
                </v-col>
                
              </v-row>
              <v-row>
                <v-col class="pa-1 ma-2" align="left" cols="auto">
                  <v-btn rounded="xl" :disabled="!btnEnable" @click="increment('goal_them')" color="green" size="35" type="tonal">+1</v-btn> Score Them <v-btn rounded="xl" :disabled="!btnEnable" @click="increment('remove_goal_them')" color="red" size="35" type="tonal">-1</v-btn>
                </v-col>
                
              </v-row>
            </v-col>

            <v-col sm="3" xs="12">
              <v-spacer></v-spacer>
            </v-col>

            <v-col cols="auto">
              <v-row>
                <v-col cols="auto">
                  <v-row>
                    <v-col class="pa-1 ma-2" align="left" cols="auto">
                      <v-btn rounded="xl" :disabled="!btnEnable" @click="increment('shot')" color="blue" size="small"    type="tonal">Shot Taken</v-btn>
                    </v-col>
                    <v-col class="pa-1 ma-2" align="left" cols="auto">
                      <v-btn rounded="xl" :disabled="!btnEnable" @click="foulCommitted()" color="red" size="small" type="tonal">Foul committed</v-btn>
                    </v-col>
                    <!-- <v-col class="pa-1 ma-2" cols="auto">
                      <v-btn :disabled="!btnEnable" @click="increment('steal')" color="blue" size="small" type="tonal">Steal</v-btn>
                    </v-col>
                    <v-col class="pa-1 ma-2" cols="auto">
                      <v-btn :disabled="!btnEnable" @click="increment('error')" color="orange" size="small" type="tonal">Unforced Error</v-btn>
                    </v-col> -->
                    <v-col class="pa-1 ma-2" cols="auto">
                      <v-btn rounded="xl" :disabled="!btnEnable" @click="increment('offsides')" color="orange" size="small" type="tonal">Offsides</v-btn>
                    </v-col>
                    <v-col class="pa-1 ma-2" cols="auto">
                      <v-btn rounded="xl" @click="logNote()" color="blue" size="small" type="tonal">Add Note</v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="auto">
              <v-row>
                <v-col class="pa-1 ma-2" cols="auto">
                  <v-btn rounded="xl" :disabled="!btnEnable" @click="penaltyKick('us')" color="red" size="small" type="tonal">PK Us</v-btn>
                </v-col>
                <v-col class="pa-1 ma-2" cols="auto">
                  <v-btn rounded="xl" :disabled="!btnEnable" @click="penaltyKick('them')" color="red" size="small" type="tonal">PK Them</v-btn>
                </v-col>
                <v-spacer class="d-none d-sm-flex"></v-spacer>
              </v-row>
            </v-col>

            <v-col sm="8" xs="12">
              <v-row>
                <v-col cols="auto">
                  Assists<br /><v-chip>{{assists}}</v-chip>
                </v-col>
                <v-col cols="auto">
                  Shots<br /><v-chip>{{shots}}</v-chip>
                </v-col>
                <v-col cols="auto">
                  SoG<br /><v-chip>{{ourSOG }}</v-chip>
                </v-col>
                <v-col cols="auto">
                  accuracy<br /><v-chip>{{ ourAccuracy }}%</v-chip>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="auto">
                  Their Shots<br /><v-chip>{{theirShots}}</v-chip>
                </v-col>
                <v-col cols="auto">
                  SoG<br /><v-chip>{{theirSOG}}</v-chip>
                </v-col>
                <v-col cols="auto">
                  accuracy<br /><v-chip>{{ theirAccuracy }}%</v-chip>
                </v-col>
              </v-row>
              <v-spacer></v-spacer>
            </v-col>

          </v-col>
          </v-row>
        </v-card-text>
      </v-card>

    <v-dialog
      v-model="goalDialog"
      max-width="300"
    >
      <v-card class="pa-0 ma-0">
        <v-card-title class="pa-0 ma-4">
          Who Scored the Goal?
        </v-card-title>
        <v-card-text class="pa-0 ma-0">
          <v-card class="pa-0 ma-0">
            <v-card-text class="pa-0 ma-0">
              <v-row v-for="r in InGame" v-bind:key="r.RowID" class="pa-0 ma-0">
                <v-col v-for="c in r.Cols" v-bind:key="c.id" class="pa-0 ma-0">
                  <div @click="scoredGoal(c)">
                    <playerChip :Player="c" />
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="assistDialog"
      max-width="300"
    >
        <v-card class="pa-0 ma-0">
            <v-card-title class="pa-0 ma-4">
                Who Assisted the Goal? <br />
                &nbsp;
                <v-btn rounded="xl" @click="assistedGoal('unassisted')" color="amber" text>Unassisted</v-btn>
            </v-card-title>
            <v-card-text class="pa-0 ma-0">
                <v-card class="pa-0 ma-0">
                    <v-card-text class="pa-0 ma-0">
                        <v-row v-for="r in InGame" v-bind:key="r.RowID" class="pa-0 ma-0">
                            <v-col v-for="c in r.Cols" v-bind:key="c.id" class="pa-0 ma-0">
                                <div @click="assistedGoal(c)">
                                    <playerChip :Player="c" />
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-spacer></v-spacer>
                <v-btn rounded="xl" @click="assistedGoal('unassisted')" color="amber" type="tonal">Unassisted</v-btn>
            </v-card-text>
        </v-card>
    </v-dialog>

    <v-dialog
        v-model="shotDialog"
        max-width="300"
    >
      <v-card class="pa-0 ma-0">
        <v-card-title>
          <h5>Was shot saved by Goalie? {{ shotSaved }}</h5>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-btn rounded="xl" @click="blkResult(true)" color="red" type="tonal">Yes</v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn rounded="xl" @click="blkResult(false)" color="blue" type="tonal">No</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-title class="pa-0 ma-4">
          Who took a shot on goal?
        </v-card-title>
        <v-card-text class="pa-0 ma-0 mb-4">
          <v-card>
            <v-card-text>
              <v-btn rounded="xl" color="orange" @click="logShot({name: 'Opponent'})" size="small" type="tonal">Opponent Took shot</v-btn>
            </v-card-text>
          </v-card>
          <v-card class="pa-0 ma-0 mt-4">
            <v-card-text class="pa-0 ma-0">
                <v-row v-for="r in InGame" v-bind:key="r.RowID" class="pa-0 ma-0">
                  <v-col v-for="c in r.Cols" v-bind:key="c.id" class="pa-0 ma-0">
                    <div @click="logShot(c)">
                      <playerChip :Player="c" />
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-card>
    </v-dialog>

    <v-dialog
        v-model="pkShotDialogThem"
        max-width="300"
    >
      <v-card>
        <v-card-title>
          Penalty shot made?
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-btn rounded="xl" @click="pkresult('them-yes')" color="green" type="tonal">Yes</v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn rounded="xl" @click="pkresult('them-no')" color="orange" type="tonal">No</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="pkShotDialogUs"
        max-width="300"
    >
      <v-card class="pa-0 ma-0">
        <v-card-title class="pa-0 ma-4">
          Penalty shot made? {{ uspkmade }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-btn rounded="xl" @click="uspkmade=true" color="green" type="tonal">Yes</v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn rounded="xl" @click="uspkmade=false" color="orange" type="tonal">No</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-title>
          Who took a shot on goal?
        </v-card-title>
        <v-card-text class="pa-0 ma-0">
          <v-card class="pa-0 ma-0">
            <v-card-text class="pa-0 ma-0">
              <v-row v-for="r in InGame" v-bind:key="r.RowID" class="pa-0 ma-0">
                <v-col v-for="c in r.Cols" v-bind:key="c.id" class="pa-0 ma-0">
                    <div @click="pkresult(c)">
                      <playerChip :Player="c" />
                    </div>
                </v-col>
             </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="foulDialog"
      max-width="300"
    >
      <v-card class="pa-0 ma-0">
        <v-card-title class="pa-0 ma-4">
          Who committed the foul?
        </v-card-title>
        <v-card-text class="pa-0 ma-0">
          <v-card class="pa-0 ma-0">
            <v-card-text>
              <v-select v-model="Foul" label="Type of foul" filled :items="foulList"></v-select>
            </v-card-text>
          </v-card>
          <v-card>
            <v-card-text>
              <v-select v-model="Card" label="Carded?" filled :items="cardList"></v-select>
            </v-card-text>
          </v-card>
          <v-card>
            <v-card-text>
              <v-btn rounded="xl" color="orange" @click="logFoul({name: 'Opponent'})">Foul charged to Opponent</v-btn>
            </v-card-text>
          </v-card>
          <v-card class="pa-0 ma-0" v-if="Foul !== ''">
            <v-card-text class="pa-0 ma-0">
              <v-row v-for="r in InGame" v-bind:key="r.RowID" class="pa-0 ma-0">
                <v-col v-for="c in r.Cols" v-bind:key="c.id" class="pa-0 ma-0">
                  <div @click="logFoul(c)">
                    <playerChip :Player="c" />
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="errorDialog"
      max-width="300">
      <v-card class="pa-0 ma-0">
        <v-card-text>
          <h5>Who committed the un-forced error (turnover)?</h5>
          <v-card class="pa-0 ma-0">
            <v-card-text class="pa-0 ma-0">
              <v-row v-for="r in InGame" v-bind:key="r.RowID" class="pa-0 ma-0">
                <v-col v-for="c in r.Cols" v-bind:key="c.id" class="pa-0 ma-0">
                  <div @click="logError(c)">
                    <playerChip :Player="c" />
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="stealDialog"
      max-width="300">
      <v-card class="pa-0 ma-0">
        <v-card-title class="pa-0 ma-0">
          Who stole the ball?
        </v-card-title>
        <v-card-text>
          <v-card class="pa-0 ma-0">
            <v-card-text class="pa-0 ma-0">
              <v-row v-for="r in InGame" v-bind:key="r.RowID" class="pa-0 ma-0">
                <v-col v-for="c in r.Cols" v-bind:key="c.id" class="pa-0 ma-0">
                  <div @click="logSteal(c)">
                    <playerChip :Player="c" />
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="offsidesDialog"
      max-width="300">
      <v-card class="pa-0 ma-0">
        <v-card-title class="pa-0 ma-4">
          Who was offsides?
        </v-card-title>
        <v-card-text class="pa-0 ma-0">
          <v-card class="pa-0 ma-0">
            <v-card-text class="pa-0 ma-4">
              <v-btn rounded="xl" color="orange" @click="logOffsides({name: 'Opponent'})">Opponent</v-btn>
            </v-card-text>
          </v-card>
          <v-card class="pa-0 ma-0">
            <v-card-text class="pa-0 ma-0">
              <v-row v-for="r in InGame" v-bind:key="r.RowID" class="pa-0 ma-0">
                <v-col v-for="c in r.Cols" v-bind:key="c.id" class="pa-0 ma-0">
                  <div @click="logOffsides(c)">
                    <playerChip :Player="c" />
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showNote"
      max-width="300">
      <CoachesLog @cb="logNote()"/>
    </v-dialog>

  </v-sheet>
</template>

<script setup lang="ts">
import { ref } from '@vue/reactivity'
import { onMounted, computed } from 'vue'
import playerChip from './playerChip.vue'
import { useGameState } from '../store/index'
import { getGameData } from '@/composables/backend'
import CoachesLog from '../components/CoachesLog.vue'
const store = useGameState()

const scoreUs = ref(0)
const scoreThem = ref(0)
const assists = ref(0)
const shots = ref(0)
const theirShots = ref(0)
const goalDialog = ref(false)
const assistDialog = ref(false)
const shotDialog = ref(false)
const foulDialog = ref(false)
const pkShotDialogUs = ref(false)
const pkShotDialogThem = ref(false)
const uspkmade = ref(false)
const foulList = [
  'Hand Ball',
  'Pushing',
  'Tripping',
  'Jumping into opponent',
  'Charging into opponent',
  'Tackle from behind',
  'Tackling prior to ball contact',
  'Holding'
]
const cardList = [
  'Yellow Card',
  'Red Card'
]
const Foul = ref('')
const Card = ref('')
const ourSOG = ref(0)
const theirSOG = ref(0)
const shotSaved = ref(false)
const InGame = ref([{
  RowID: Number,
  Cols: [
    {
      id: Number,
      position: String,
      name: String,
      jersey: Number
    }
  ]
}])
// let subs = store.subs // reactive([])
const scoredPlayer = ref({})
const errorDialog = ref(false)
const stealDialog = ref(false)
const offsidesDialog = ref(false)
const showNote = ref(false)

const ourAccuracy = computed(() => {
  // const accurateShots = scoreUs.value + ourSOG.value
  let accuracy = Number(((ourSOG.value / shots.value) * 100).toFixed(2))
  if (isNaN(accuracy)) {
    accuracy = 0
  }
  return accuracy
})

const theirAccuracy = computed(() => {
  // const accurateShots = scoreThem.value + theirSOG.value
  let accuracy = Number(((theirSOG.value / theirShots.value) * 100).toFixed(2))
  if (isNaN(accuracy)) {
    accuracy = 0
  }
  return accuracy
})

const btnEnable = computed(() => {
  if ((store.gameInProgress) && (!store.timePaused)) {
    return true
  }
  return false
})

const getInGame = async () => {
  // update InGame with store data
  InGame.value = store.InGame
}

const increment = async (item) => {
  getInGame()
  if (item === 'shot') {
    shotDialog.value = true
  } else if (item === 'goal_us') {
    goalDialog.value = true
  } else if (item === 'remove_goal_us') {
    const conf = confirm('Remove goal from us?')
    if (conf) {
      scoreUs.value--
      store.setScoreUs(scoreUs.value)
      // FIXME remove attributed assist (if there was one logged)
      await saveGameChanges('remove_goal_us', '')
    }
  } else if (item === 'goal_them') {
    scoreThem.value++
    theirShots.value++
    // log the goal
    store.setTheirShots(theirShots.value)
    store.setScoreThem(scoreThem.value)
    // set our goalie as the player for attribution
    await saveGameChanges('goal_them', store.InGame[5].Cols['2'].name)
  } else if (item === 'remove_goal_them') {
    const conf = confirm('Remove goal from opponet?')
    if (conf) {
      scoreThem.value--
      store.setScoreThem(scoreThem.value)
      await saveGameChanges('remove_goal_them', '')
    }
  } else if (item === 'error') {
    errorDialog.value = true
  } else if (item === 'steal') {
    stealDialog.value = true
  } else if (item === 'offsides') {
    offsidesDialog.value = true
  }
}

const foulCommitted = async () => {
  foulDialog.value = true
}

const penaltyKick = (who) => {
  if (who === 'us') {
    pkShotDialogUs.value = true
  } else {
    pkShotDialogThem.value = true
  }
}

const pkresult = async (res) => {
  if (res === 'them-yes') {
    scoreThem.value++
    store.setScoreThem(scoreThem.value)
    theirShots.value++
    store.setTheirShots(theirShots.value)
    await saveGameChanges('Penalty Kick made', 'opponent')
    pkShotDialogThem.value = false
    return
  } else if (res === 'them-no') {
    theirShots.value++
    store.setTheirShots(theirShots.value)
    store.setTheirSOG()
    theirSOG.value = store.theirSOG
    await saveGameChanges('Penalty Kick missed', 'opponent')
    pkShotDialogThem.value = false
    return
  } else if (uspkmade.value) {
    scoreUs.value++
    shots.value++
    store.setScoreUs(scoreUs.value)
    store.setOurShots(shots.value)
    await saveGameChanges('Penalty Kick made', res.name)
  } else {
    shots.value++
    store.setOurSOG()
    ourSOG.value = store.ourSOG
    store.setOurShots(shots.value)
    await saveGameChanges('Penalty Kick missed', res.name)
  }
  pkShotDialogUs.value = false
}

const logError = async (p) => {
  errorDialog.value = false

  // console.log(p)
  // console.log(Card.value)
  await saveGameChanges('Unforced Error', p.name)
}

const logSteal = async (p) => {
  stealDialog.value = false

  // console.log(p)
  // console.log(Card.value)
  await saveGameChanges('Steal', p.name)
}

const logOffsides = async (p) => {
  offsidesDialog.value = false

  // console.log(p)
  // console.log(Card.value)
  await saveGameChanges('Offsides', p.name)
}

const logFoul = async (p) => {
  foulDialog.value = false

  // console.log(p)
  // console.log(Card.value)
  await saveGameChanges(Foul.value, p.name)
  if (Card.value !== '') {
    await saveGameChanges(Card.value, p.name)
  }
  // reset the foul/card fields
  Card.value = ''
  Foul.value = ''
}

const scoredGoal = async (p) => {
  scoreUs.value++
  shots.value++ // Shots on goal (saved or scored)
  // shots entirely
  goalDialog.value = false
  assistDialog.value = true
  scoredPlayer.value = p
  // log the goal
  console.log('goal scored by ' + p.name)
  store.setScoreUs(scoreUs.value)
  store.setOurShots(shots.value)
  store.setOurSOG()
  ourSOG.value = store.ourSOG
  await saveGameChanges('goal_us', p.name)
}

const assistedGoal = async (p) => {
  if (p === 'unassisted') {
    // unassisted goal, exit out
    assistDialog.value = false
    // console.log('unassisted goal')
  } else {
    // log the assist
    assistDialog.value = false
    // console.log('assisted by ' + p.name)
    assists.value++
    // console.log(assists.value)
    store.setAssists(assists.value)
    // Write it to DB
    await saveGameChanges('assisted_goal', p.name)
  }
  scoredPlayer.value = {}
}

const logShot = async (p) => {
  // log the shot by somebody
  // console.log('shot taken by ' + p.name)
  if (p.name === 'Opponent') {
    logShotAgainstUs()
    return
  }

  if (shotSaved.value) {
    // log they saved a shot
    shots.value++
    store.setOurShots(shots.value)
    store.setOurSOG()
    await saveGameChanges('logShot-saved', p.name)
    ourSOG.value = store.ourSOG
  } else {
    shots.value++
    store.setOurShots(shots.value)
    await saveGameChanges('logShot', p.name)
  }
  shotSaved.value = false // reset it
  shotDialog.value = false
}

const logShotAgainstUs = async () => {
  // log shots against us (credit goalkeeper)
  // console.log(store.InGame[5].Cols['2'])

  // need to know if shot was saved by goalie.  if so, it's a shot on goal.  otherwise it was a miss, just a shot
  if (shotSaved.value) {
    // log shot saved by our goalie
    store.setTheirSOG()
    theirShots.value++
    store.setTheirShots(theirShots.value)
    // log save by goalie (if it was saved)
    await saveGameChanges('logShotAgainstUs-saved', store.InGame[5].Cols['2'].name)
    theirSOG.value = store.theirSOG
  } else {
    theirShots.value++
    store.setTheirShots(theirShots.value)
    await saveGameChanges('logShotAgainstUs', store.InGame[5].Cols['2'].name)
  }
  shotSaved.value = false // reset it
  shotDialog.value = false
}

const blkResult = async (res) => {
  shotSaved.value = res
}

const saveGameChanges = async (k, v) => {
  const statData = {
    current_game_time: store.GameTime,
    last_timestamp: store.lastTimeStamp,
    gameID: store.GameID,
    teamID: store.TeamID,
    seasonName: store.TeamSeason,
    orgID: store.OrgID,
    player: v,
    data: k,
    our_score: scoreUs.value,
    their_score: scoreThem.value,
    ourGoalie: store.InGame[5].Cols['2'].name
  }
  const stats = JSON.stringify(statData)

  await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/gm/saveStats', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'BEARER: ' + store.AuthToken },
    body: stats
  })

  await saveGameState()
}

const saveGameState = async () => {
  const gameData = {
    current_game_time: store.GameTime,
    last_timestamp: store.lastTimeStamp,
    our_score: scoreUs.value,
    their_score: scoreThem.value,
    game_period: store.GamePeriod,
    assists: store.Assists,
    our_shots: store.ourShots,
    their_shots: store.theirShots,
    ourSOG: store.ourSOG,
    theirSOG: store.theirSOG
  }
  const gameDataJson = JSON.stringify(gameData)

  await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/gm/saveGameData/' + store.OrgID + '/' + store.TeamID + '/' + store.GameID, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'BEARER: ' + store.AuthToken },
    body: gameDataJson
  })
}

const logNote = () => {
  showNote.value = !showNote.value
}

onMounted(async () => {
  await getInGame()
  // subs = store.subs

  // Load game state from DB
  const gd = await getGameData(store)

  // console.log(gd)

  scoreUs.value = gd.our_score
  scoreThem.value = gd.their_score
  assists.value = gd.assists
  shots.value = gd.our_shots
  theirShots.value = gd.their_shots

  ourSOG.value = gd.ourSOG
  theirSOG.value = gd.theirSOG

  // set state
  store.setGamePeriod(gd.game_period)
  store.updateLastTimestamp(gd.last_timestamp)
  store.updateGameTime(gd.current_game_time)
  store.setScoreThem(gd.their_score)
  store.setScoreUs(gd.our_score)
  store.setOurShots(gd.our_shots)
  store.setTheirShots(gd.their_shots)
  store.setAssists(gd.assists)
  store.setOpponent(gd.opponent)
})

</script>

<template>

  <v-container>

    <v-card>
      <v-card-title>
        {{ store.auth.user.name }}'s Settings
      </v-card-title>
    </v-card>

    <v-card>
      <v-card-title>Team Selection</v-card-title>
      <v-card-text>
        <v-row>
          <v-col sm="3" xs="6">
              <v-select
                v-model="org"
                :items="orgs"
                item-title="name"
                item-value="id"
                label="Choose Team Organization"
                @update:model-value="setOrg()"
              ></v-select>
              <v-select v-if="orgSelected"
                v-model="season"
                :items="seasons"
                item-value="Season"
                item-title="Season"
                @update:model-value="chooseSeason()"
                label="Season"
              >
              </v-select>
              <v-select v-if="seasonSelected"
                v-model="team"
                item-title="name"
                item-value="id"
                :items="teams"
                label="Choose Team"
                return-object
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-title>User Preferences</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="2">
            <v-label>Profile Photo</v-label> &nbsp;
            <v-avatar icon="mdi-cog" :image="profilePic" size="64"></v-avatar>
          </v-col>
          <v-col md="3" xs="10">
            <v-file-input
              counter
              multiple
              show-size
              accept="image/*"
              label="Profile Photo Upload"
              @change="uploadProfilePic"
            ></v-file-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="3" xs="10">
            <v-label>Local timezone</v-label>
            <v-select
                v-model="timeZone"
                :items="zones"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="3" xs="6">
            <v-label>Theme Selection</v-label>
            <v-radio-group v-model="theme" @change="changeTheme()">
              <v-radio label="Light mode" value="light"></v-radio>
              <v-radio label="Dark mode" value="dark"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn @click="saveSettings" color="green">Save</v-btn>
      </v-card-actions>
    </v-card>

  <v-dialog
    v-model="showIntro"
    max-width="450"
  >
    <v-card width="350">
      <v-card-title align="center">
        Welcome to <br />my Gameday Soccer app
      </v-card-title>
      <v-card-text>
        <p>To get started, you will need to select a soccer orginization that you wish to view.</p>
        <p>Once selected you will be able to choose the season and team.</p>
        <p>Once your preferences are saved, you will be able to view the "My Team" page where you can view the team's games.</p>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="showIntro = false" color="primary">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  </v-container>

</template>

<script setup lang="ts">
import { ref } from '@vue/reactivity'
import { useRouter } from 'vue-router'
import { onMounted } from 'vue'
import { useGameState } from '../store/index'
const store = useGameState()
const router = useRouter()

const orgs = ref([])
const teams = ref([])
const seasons = ref([])

const orgSelected = ref(false)
const teamSelected = ref(false)
const seasonSelected = ref(false)

const org = ref(0)
const teamID = ref(0)
const team = ref({})
const season = ref('')

const timeZone = ref('')
const zones = ref([])

const theme = ref('light')

const showIntro = ref(false)
const profilePic = ref('')
let profilePicName = ''

const getOrgs = async () => {
  orgs.value = await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/getOrgs').then((res) => res.json())
}

const getSeasons = async () => {
  seasons.value = await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/getSeasons/' + store.OrgID).then((res) => res.json())
}

const updateTeamList = async () => {
  // get teams for this.org and update this.teams
  teams.value = await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/getTeams' + '/' + store.OrgID + '/' + encodeURIComponent(store.TeamSeason)).then((res) => res.json())
}

const changeTheme = () => {
  store.changeTheme(theme.value)
  router.go(0)
}

const setOrg = () => {
  store.setOrg(Number(org.value))
  orgSelected.value = true
  getSeasons()
}

const chooseSeason = () => {
  store.setSeason(season.value)
  seasonSelected.value = true
  console.log('season ' + season.value)
  updateTeamList()
}

const saveSettings = async () => {
  store.setTeamID(Number(team.value.id))
  store.setTeamName(team.value.name)

  if (timeZone.value !== store.timeZone) {
    store.setTimeZone(timeZone.value)
  }

  // const userInfo = await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/getSettings' + '/' + store.auth.user.uid).then((res) => res.json())

  // this is because loading org from store sets it as a string.
  let orgnum = store.OrgID
  if (typeof org.value !== 'string') {
    orgnum = org.value
  }

  // if (userInfo.email === '') {
  //   userInfo.email = store.auth.user.email
  // }

  // if (userInfo.name === '') {
  //   userInfo.name = store.auth.user.name
  // }

  // userInfo.chosen_org_id = Number(orgnum)
  // userInfo.chosen_team_id = Number(team.value.id)

  // userInfo.state = {
  //   OrgID: Number(orgnum),
  //   TeamID: Number(team.value.id),
  //   TeamName: team.value.name,
  //   TeamSeason: season.value,
  //   Theme: theme.value,
  //   ProfilePicName: profilePicName || store.auth.user.photo,
  //   ProfilePicData: profilePic.value,
  //   TimeZone: timeZone.value
  // }
  // commit store values into DB
  // const stateFile = JSON.stringify(userInfo)

  // console.log(stateFile)
  // send to backend
  // try {
  //   await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/saveSettings', {
  //     method: 'POST',
  //     headers: { 'Content-Type': 'application/json', 'Authorization': 'BEARER: ' + store.AuthToken },
  //     body: stateFile
  //   })
  // } finally {
  //   router.back()
  // }
}

const uploadProfilePic = (files) => {
  const uploads = files.target.files
  // console.log(uploads)
  for (let i = 0; i < uploads.length; i++) {
    const fname = uploads[i].name
    const fdata = new FileReader()
    fdata.readAsDataURL(uploads[i])
    fdata.addEventListener('load', () => {
      const imageUrl = fdata.result
      // const imageFile = uploads[i] // this is an image file that can be sent to server...
      // console.log(imageFile)
      profilePic.value = String(imageUrl)
      profilePicName = fname
      // console.log(imageUrl) // Send this to backend for upload to file storage
      // previewImage.value.push(imageUrl)
      // imageData.push(imageFile)
    })
    // console.log('file: ' + fname)
  }
}

onMounted(async () => {
  await getOrgs()

  // get name of current org
  if (store.OrgID > 0) {
    const orgNum = orgs.value.findIndex(item => Number(item.id) === store.OrgID)
    org.value = orgs.value[orgNum].name
  }

  team.value = {
    id: store.TeamID,
    name: store.TeamName
  }
  season.value = store.TeamSeason
  theme.value = store.theme || 'light'

  if (org.value !== 0) {
    orgSelected.value = true
    getSeasons()
  }

  if (store.TeamID !== 0) {
    teamID.value = store.TeamID
    teamSelected.value = true
  }

  if (season.value !== '') {
    seasonSelected.value = true
    updateTeamList()
  }

  timeZone.value = store.timeZone

  // complete list
  // zones.value = Intl.supportedValuesOf('timeZone')
  zones.value = [
    'America/Puerto_Rico',
    'America/New_York',
    'America/Detroit',
    'America/Chicago',
    'America/Denver',
    'America/Boise',
    'America/Phoenix',
    'America/Los_Angeles',
    'America/Juneau',
    'Pacific/Honolulu'
  ]
  // Don't show intro if org is already selected
  if (store.OrgID <= 0) {
    showIntro.value = true
  }

  profilePic.value = store.profilePic
})

</script>

<template>
  <v-sheet class="pa-0 ma-0" color="background">

    <!-- <v-card class="pa-0 mb-4">
      <v-card-text> -->

        <v-card class="pa-4 mb-4" rounded="xl">
          <v-card-subtitle>
            Score
          </v-card-subtitle>
          <v-card-text class="text-left">
            <v-row>
              <v-col>
                <scoreboard :t1="store.TeamName" :t1score="scoreUs" :t2="opponent" :t2score="scoreThem" :time="gametime" :period="gamePeriod"/>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card class="pa-4 mb-4" rounded="xl">
          <v-card-subtitle>
            Stats
          </v-card-subtitle>
          <v-card-text class="text-left">
            <v-row>
              <v-col cols="6">
                Our shots: {{ ourShots }}
              </v-col>
              <v-col cols="6">
                Their shots: {{ theirShots }}
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                Our shots on goal: {{ ourSOG }}
              </v-col>
              <v-col cols="6">
                Their shots on goal: {{ theirSOG }}
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                Our Accuracy: {{ Number(((ourSOG / ourShots) * 100).toFixed(2)) || 0 }}
              </v-col>
              <v-col cols="6">
                Their Accuracy: {{ Number(((theirSOG / theirShots) * 100).toFixed(2)) || 0 }}
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="auto">
                Our Assists: {{ assists }}
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

      <!-- </v-card-text>
    </v-card> -->

    <v-card class="pa-4 mb-4" v-if="showLog" rounded="xl">
      <v-card-title class="pa-0 ma-0">
        Game Log
      </v-card-title>
      <v-card-text class="pa-0 ma-0">
        <v-data-table
          :headers="logHeaders"
          :items="logs"
          class="elevation-1 py-0 px-0"
          mobile-breakpoint="0"
          :items-per-page="5"
          :fixed-header=false
        >
        </v-data-table>
      </v-card-text>
    </v-card>

    <GameMedia :mediaFiles="media" v-if="showMedia"/>

    <v-bottom-navigation
      grow
      height="75"
      elevation="0">
        <v-btn rounded="xl" @click="showMedia = !showMedia">
          <v-badge :content="numPictures" color="red" v-if="numPictures > 0">
            <v-icon size="x-large">mdi-folder-multiple-image</v-icon>
          </v-badge>
          <v-icon v-if="numPictures === 0" size="x-large">mdi-folder-multiple-image</v-icon>
          Media
        </v-btn>

        <v-btn rounded="xl" disabled>
          <v-badge :content="numMessages" color="red" v-if="numMessages > 0">
            <v-icon size="x-large">mdi-chat</v-icon>
          </v-badge>
          <v-icon v-if="numMessages === 0" size="x-large">mdi-chat</v-icon>
          Chat
        </v-btn>

        <v-btn rounded="xl" @click="showLog = !showLog">
          <v-badge :content="numLogs" color="red" v-if="numLogs > 0">
            <v-icon size="x-large">mdi-file-document-edit</v-icon>
          </v-badge>
          <v-icon v-if="numLogs === 0" size="x-large">mdi-file-document-edit</v-icon>
          Game Log
        </v-btn>
    </v-bottom-navigation>

  </v-sheet>
</template>

<script setup lang="ts">
import { ref } from '@vue/reactivity'
// import { useRoute } from 'vue-router'
import { useGameState } from '../store/index'
import { onMounted, onUnmounted } from 'vue'
import scoreboard from '@/components/scoreboardChips.vue'
import GameMedia from '../components/GameMedia.vue'
const store = useGameState()
// const route = useRoute()

const logHeaders = [
  { title: 'Game Time', key: 'GameTime', align: 'start', sortable: true },
  { title: 'Event', key: 'GameEvent', align: 'start', sortable: false }
]
const ourShots = ref(0)
const theirShots = ref(0)
const assists = ref(0)
const gametime = ref('')
const gamePeriod = ref('')
const scoreUs = ref(0)
const scoreThem = ref(0)
const media = ref([])
const showMedia = ref(false)
const showLog = ref(true)
const logs = ref([])
let GameLogs = {}
const numMessages = ref(0)
const numPictures = ref(0)
const numLogs = ref(0)
const ourSOG = ref(0)
const theirSOG = ref(0)
const opponent = ref('')

// const gameStats = ref({})

// refresh logs every 10 seconds
GameLogs = window.setInterval(
  function () {
    getGameLogs()
  }, 10000)

const getGameLogs = async () => {
  logs.value = await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/gm/getGameLog/' + store.GameID).then((res) => res.json())
  if (logs.value !== null) {
  // if (logs.value.length > 0) {
    numLogs.value = logs.value.length
  }

  const gd = await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/gm/getGameData/' + store.GameID).then((res) => res.json())

  gametime.value = gd.current_game_time
  gamePeriod.value = gd.game_period
  ourShots.value = gd.our_shots
  theirShots.value = gd.their_shots
  assists.value = gd.assists
  scoreUs.value = gd.our_score
  scoreThem.value = gd.their_score
  ourSOG.value = gd.ourSOG
  theirSOG.value = gd.theirSOG
  opponent.value = gd.opponent

  media.value = await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/gm/getGameMedia/' + store.GameID, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'BEARER: ' + store.AuthToken },
      }).then((res) => res.json())
  if (media.value !== null) {
    numPictures.value = media.value.length
  }
}

onMounted(() => {
  getGameLogs()
})

onUnmounted(() => {
  clearInterval(GameLogs)
})
</script>

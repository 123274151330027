<template>
<v-container class="pa-0 ma-2">

  <v-container style="position: -webkit-sticky; position: sticky; top: 50px; z-index: 2;" fluid class="pa-0 ma-0 pb-5">
    <v-sheet rounded="xl">
      <Timer v-if="!viewonly"/>
    </v-sheet>
  </v-container>

  <v-sheet fluid v-if="!viewonly" rounded="xl">
    <GameData2 />
  </v-sheet>

  <v-sheet fluid v-if="!viewonly" rounded="xl" class="pa-0 mt-2" color="background">
    <GameLogView />
  </v-sheet>

  <v-sheet fluid  v-if="viewonly" rounded="xl">
    <GameLogStats />
  </v-sheet>
</v-container>
</template>

<script setup lang="ts">
import GameData2 from '@/components/GameData2.vue'
import GameLogView from '@/components/GameLogView.vue'
import Timer from '@/components/Timer_comp.vue'
import GameLogStats from '@/components/GameLogStats.vue'
import { ref } from '@vue/reactivity'
import { useRoute } from 'vue-router'
import { useGameState } from '@/store/index'
import { onMounted, watch, onUnmounted } from 'vue'
import { getPerms } from '@/composables/userPerms'
import { getGameData } from '@/composables/backend'
import { logActivity } from '@/composables/telemetry'
const store = useGameState()
const route = useRoute()

const gametime = ref('00:00:00')
const viewonly = ref(false)
let GameSaver = {}

watch(store, () => {
  gametime.value = store.GameTime
})

GameSaver = window.setInterval(
  function () {
    if ((store.GameID !== '_none_') && (!store.ViewOnly) && (store.gameInProgress)) {
      // write game data to DB every 10 seconds
      const gameData = {
        current_game_time: store.GameTime,
        last_timestamp: store.lastTimeStamp,
        // our_score: store.scoreUs,
        // their_score: store.scoreThem,
        game_period: store.GamePeriod,
        timePaused: Boolean(store.timePaused)
      }
      const gameDataJson = JSON.stringify(gameData)

      fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/gm/saveGameData/' + store.OrgID + '/' + store.TeamID + '/' + store.GameID, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'BEARER: ' + store.AuthToken },
        body: gameDataJson
      })
    } else {
      // viewonly just pulls data from the backend
      getGameDataContent()
    }
    // update activity log
    logActivity(store, route.name, 'gameLog.vue', store.GameID)
  }, 10000)

const getGameDataContent = async () => {
  const data = await getGameData(store)

  // set store data if it's not set
  if (store.Opponent !== data.opponent) {
    store.setOpponent(data.opponent)
  }

  if (store.GamePeriod !== data.game_period) {
    store.setGamePeriod(data.game_period || 'Not started')
  }

  if (store.lastTimeStamp !== data.last_timestamp) {
    store.updateLastTimestamp(data.last_timestamp)
  }
  if (store.GameTime !== data.current_game_time) {
    store.updateGameTime(data.current_game_time || '00:00:00')
  }
  if (store.scoreUs !== data.our_score) {
    store.setScoreUs(data.our_score)
  }
  if (store.scoreThem !== data.their_score) {
    store.setScoreThem(data.their_score)
  }
  if (store.Assists !== data.assists) {
    store.setAssists(data.assists)
  }
  if (store.ourShots !== data.our_shots) {
    store.setOurShots(data.our_shots)
  }
  if (store.theirShots !== data.their_shots) {
    store.setTheirShots(data.their_shots)
  }

  if (viewonly) {
    gametime.value = data.current_game_time
  }
}

// Pull data from DB, update state
onMounted(async () => {
  store.setActivePage(route.name)
  await getGameDataContent()
  gametime.value = store.GameTime
  viewonly.value = store.ViewOnly
  await getPerms(store)
  logActivity(store, route.name, 'gameLog.vue', store.GameID)
})

onUnmounted(() => {
  clearInterval(GameSaver)
  store.resetGameStats()

  // localStorage.removeItem('gameState.GameID')
  // // localStorage.removeItem('gameState.GameTime')
  // localStorage.removeItem('gameState.ViewOnly')
  // localStorage.removeItem('gameState.ViewOnlyLog')
  // localStorage.removeItem('gameState.scoreUs')
  // localStorage.removeItem('gameState.scoreThem')
  // localStorage.removeItem('gameState.Assists')
  // localStorage.removeItem('gameState.ourShots')
  // localStorage.removeItem('gameState.theirShots')
  // localStorage.removeItem('gameState.Opponent')
  // localStorage.removeItem('gameState.GamePeriod')
})

</script>

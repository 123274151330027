<template>
  <v-sheet color="background" class="pa-0 ma-0">

    <v-card v-if="showList" class="pa-0 ma-0 mb-4">
      <v-card-title class="pa-0 ma-0">Manage Season Active Players</v-card-title>
      <v-card-text class="pa-0 ma-0">
        <v-data-table
          :headers="playerHeaders"
          :items="seasonPlayers"
          class="elevation-1 py-0 px-0"
          mobile-breakpoint="0"
          :items-per-page="15"
          :fixed-header=true
          dense
          :search="search"
          :custom-filter="searchPlayer"
        >

        <template v-slot:top>
          <v-toolbar flat>
            <div class="d-flex w-100">
              <v-text-field
                v-model="search"
                label="Player Search"
                class="mx-4"
              ></v-text-field>
            </div>
          </v-toolbar>
        </template>

        <template v-slot:[`item.player`]="{ item }">
          <v-text-field v-model="editedPlayer.name" :hide-details="true" dense single-line :autofocus="true" v-if="item.id === editedPlayer.id"></v-text-field>
          <span v-else>{{item.name}}</span>
        </template>

        <template v-slot:[`item.actions`]="{ item }">

          <v-icon color="green" @click="editPlayer(item)">
            mdi-pencil
          </v-icon>
          &nbsp;
          <v-icon color="amber" @click="playerRating(item)">
            mdi-list-status
          </v-icon>
          &nbsp;
          <v-icon color="blue" @click="playerStats(item)">
            mdi-chart-line
          </v-icon>

        </template>

      </v-data-table>
      </v-card-text>

    </v-card>

    <v-card v-if="showList" class="pa-0 ma-0 mb-4">
      <v-card-title class="pa-0 ma-0">Bulk player import</v-card-title>
      <v-card-text class="pa-0 ma-0">
        <v-btn @click="toggleTemplateShow()">example csv</v-btn>
        <!-- csv loader file: <link >Download</link> -->
        <template>
          <v-alert
            type="success"
            title="Player import"
            text="uploadResult"
            closable
            >
          </v-alert>
        </template>

        <v-file-input
          v-model="csvUpload"
          :loading="csvProcessing"
          accept="text/csv"
          label="Bulk .csv upload"
          @change="uploadBulkPlayers"
        ></v-file-input>
      </v-card-text>
    </v-card>

    <v-card v-if="showList" class="pa-0 ma-0">
      <v-card-title>Manage Player Pool</v-card-title>
      <v-card-text>
        <v-data-table
          v-model="selectedPlayers"
          :headers="playerHeaders"
          :items="allPlayers"
          class="elevation-1 pa-0 ma-0"
          mobile-breakpoint="0"
          :items-per-page="15"
          :fixed-header=true
          dense
          :search="search"
          :custom-filter="searchPlayer"
          show-select
          :single-select=false
          item-key="player_id"
        >

        <template v-slot:top>
          <v-toolbar flat>
            <div class="d-flex w-75">
              <v-text-field
                v-model="search"
                label="Player Search"
                class="mx-4"
              ></v-text-field>
              <v-spacer></v-spacer>
            </div>
            <div class="d-flex w-50">
              <v-select v-model="groupBy1res" :items="groupBy1" label="Group by age" v-on:change="group()"></v-select>
              <v-spacer></v-spacer>
            </div>
            <div class="d-flex w-50">
              <v-select v-model="groupBy2res" :items="groupBy2" label="Group by Gender" v-on:change="group()"></v-select>
              <v-spacer></v-spacer>
            </div>
            <div class="ml-auto">
              <v-btn
                color="primary"
                @click="addPlayer">
                <v-icon dark>mdi-plus</v-icon>Add Player
              </v-btn>

              <v-btn
                color="green"
                @click="activatePlayer">
                <v-icon dark>mdi-arrow-up</v-icon>Activate Selected Players
              </v-btn>
            </div>
          </v-toolbar>
        </template>

        <template v-slot:[`item.player`]="{ item }">
          <v-text-field v-model="editedPlayer.name" :hide-details="true" dense single-line :autofocus="true" v-if="item.id === editedPlayer.id"></v-text-field>
          <span v-else>{{item.name}}</span>
        </template>

        <template v-slot:[`item.actions`]="{ item }">

          <v-icon color="green" @click="editPlayer(item)">
            mdi-pencil
          </v-icon>
          &nbsp;
          <v-icon color="amber" @click="playerRating(item)">
            mdi-list-status
          </v-icon>
          &nbsp;
          <v-icon color="blue" @click="playerStats(item)">
            mdi-chart-line
          </v-icon>

        </template>

      </v-data-table>
      </v-card-text>

    </v-card>

    <v-dialog
      v-model="showTemplate"
      scrollable>
      <v-card>
        <v-card-title>
          Bulk import csv template
        </v-card-title>
        <v-card-text>
          <p>Create a .csv file with the following fields and populate with your data</p>
          <pre>
            first_name,last_name,birthdate,jersey_number,gender,guardian1,guardian2,medical_conditions,
            joe, test,2012-jun-24,98,M,parent@email.com,,,
            jane, test,2013-aug-24,99,F,parent@email.com,,peanut allergy,
          </pre>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-card v-if="showEdit" class="pa-0 mt-4 ma-0">
      <v-card-title>
        {{ action }}
      </v-card-title>
      <v-card-text class="pa-0 ma-0">

          <v-row>
            <v-col cols="12" sm="3" xs="10">
              <v-text-field v-model="firstName" label="First Name" filled></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" xs="10">
              <v-text-field v-model="lastName" label="last name" filled></v-text-field>
            </v-col>
            <v-col cols="6" sm="3" xs="5">
              <v-text-field v-model="jerseyNumber" label="Jersey Number" filled type="number"></v-text-field>
            </v-col>
            <v-col cols="6" sm="3" xs="5">
              <v-select v-model="seasonStatus" label="Status for Season" filled :items="statuses" ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6" sm="3" xs="6">
              <v-label>Birth Date</v-label>
              <v-chip @click="pickDate = !pickDate">{{birthdate}}</v-chip>
              <VueDatePicker v-model="birthdate" :enable-time-picker="false" inline text-input inline-with-input v-if="pickDate" @update:model-value="setDate()" />
            </v-col>
            <v-col cols="6" sm="3" xs="6">
              <v-select v-model="gender" label="Gender" :items="genders" @change="getAllPlayerList()" filled></v-select>
            </v-col>
            <v-col cols="6" sm="3" xs="6">
              <v-text-field v-model="guardian1" label="Guardian" filled></v-text-field>
            </v-col>
            <v-col cols="6" sm="3" xs="6">
              <v-text-field v-model="guardian2" label="Guardian" filled></v-text-field>
            </v-col>
            <v-col cols="6" sm="3" xs="6">
              <v-text-field v-model="medicalConditions" label="Medical Conditions" filled></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>

      </v-card-text>
      <v-card-actions class="pa-0 ma-0">
        <v-btn @click="cancelEdit">Cancel</v-btn>
        <v-btn @click="save">Save</v-btn>
      </v-card-actions>
    </v-card>

    <!-- <v-card v-if="showEdit" class="pa-0 ma-0">
      <v-card-title>
        Player Report Card (in season and post season grading)
      </v-card-title>
      <v-card-text>
        insert player report card component here when it's ready
      </v-card-text>
    </v-card> -->

    <v-card v-if="showStats" class="pa-0 ma-0">
      <v-card class="pb-4">
        <v-card-title>{{ player }} {{ season }} Stats</v-card-title>
        <v-card-text>
          <v-data-table
            :headers="statHeaders"
            :items="stats"
            class="elevation-1 py-0 px-0"
            mobile-breakpoint="0"
            :items-per-page="15"
            :fixed-header=true
          >
          </v-data-table>
          <v-card-title>Time (minutes) in field by position</v-card-title>
          <v-data-table
            :headers="stat2Headers"
            :items="stats"
            class="elevation-1 py-0 px-0"
            mobile-breakpoint="0"
            :items-per-page="15"
            :fixed-header=true
          >
          </v-data-table>
          <v-card-title>Other stats</v-card-title>
          <v-data-table
            :headers="otherStatHeaders"
            :items="stats"
            class="elevation-1 py-0 px-0"
            mobile-breakpoint="0"
            :items-per-page="15"
            :fixed-header=true
          >
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="hideStats()" color="blue">Close</v-btn>
        </v-card-actions>
      </v-card>

      <v-spacer></v-spacer>

      <v-card class="pa-0 pb-4 ma-0">
        <v-card-title>{{ player }} Stats by season</v-card-title>
        <v-card-text>
          <v-data-table
            :headers="statHeadersBySeason"
            :items="stats"
            class="elevation-1 py-0 px-0"
            mobile-breakpoint="0"
            :items-per-page="15"
            :fixed-header=true
          >
          </v-data-table>
          <v-card-title>Time (minutes) in field by position</v-card-title>
          <v-data-table
            :headers="stat2HeadersBySeason"
            :items="stats"
            class="elevation-1 py-0 px-0"
            mobile-breakpoint="0"
            :items-per-page="15"
            :fixed-header=true
          >
          </v-data-table>
          <v-card-title>Other stats</v-card-title>
          <v-data-table
            :headers="otherSeasonStatHeaders"
            :items="stats"
            class="elevation-1 py-0 px-0"
            mobile-breakpoint="0"
            :items-per-page="15"
            :fixed-header=true
          >
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="hideStats()" color="blue">Close</v-btn>
        </v-card-actions>
      </v-card>

      <v-spacer></v-spacer>

      <v-card class="pa-0 pb-4 ma-0">
        <v-card-title>{{ player }} Career Stats</v-card-title>
        <v-card-text>
          <v-data-table
            :headers="statHeadersCareer"
            :items="stats"
            class="elevation-1 py-0 px-0"
            mobile-breakpoint="0"
            :items-per-page="15"
            :fixed-header=true
          >
          </v-data-table>
          <v-card-title>Time (minutes) in field by position</v-card-title>
          <v-data-table
            :headers="stat2HeadersCareer"
            :items="stats"
            class="elevation-1 py-0 px-0"
            mobile-breakpoint="0"
            :items-per-page="15"
            :fixed-header=true
          >
          </v-data-table>
          <v-card-title>Other stats</v-card-title>
          <v-data-table
            :headers="otherCareerStatHeaders"
            :items="stats"
            class="elevation-1 py-0 px-0"
            mobile-breakpoint="0"
            :items-per-page="15"
            :fixed-header=true
          >
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="hideStats()" color="blue">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-card>

  </v-sheet>
</template>

<script lang="ts" setup>
import { ref } from '@vue/reactivity'
// import { useRoute, useRouter } from 'vue-router'
import { useGameState } from '../store/index'
import { onMounted } from 'vue-demi'
const store = useGameState()
// const route = useRoute()
// const router = useRouter()

const playerHeaders = [
  { title: 'Full Name', key: 'name', align: 'start', sortable: true },
  { title: 'Team', key: 'Team', align: 'start', sortable: true },
  { title: 'Birth Date', key: 'birth_date', align: 'start', sortable: true },
  { title: 'Age', key: 'age', align: 'start', sortable: true },
  { title: 'Gender', key: 'gender', align: 'start', sortable: true },
  { title: 'Jersey #', key: 'jersey_number', align: 'start', sortable: true },
  { title: 'Active', key: 'player_status', align: 'start', sortable: true },
  { title: 'Guest', key: 'guest_player', align: 'start', sortable: true },
  { title: 'actions', key: 'actions', sortable: false, width: '150px' }
]
const seasonPlayers = ref([])
const allPlayers = ref([])
const statHeaders = [
  { title: 'Goals', key: 'CurrentSeasonGameStats.Totals.Goals', align: 'start', sortable: true },
  { title: 'Assists', key: 'CurrentSeasonGameStats.Totals.Assists', align: 'start', sortable: true },
  { title: 'Shots on Goal', key: 'CurrentSeasonGameStats.Totals.Shotsongoal', align: 'start', sortable: true },
  { title: 'Games', key: 'CurrentSeasonGameStats.Totals.GamesPlayed', align: 'start', sortable: true },
  { title: 'Minutes Played', key: 'CurrentSeasonGameStats.Totals.Timeingame', align: 'start', sortable: true },
  { title: 'Yellow Cards', key: 'CurrentSeasonGameStats.Totals.Yellowcards', align: 'start', sortable: true },
  { title: 'Red Cards', key: 'CurrentSeasonGameStats.Totals.Redcards', align: 'start', sortable: true }
  // { title: 'Goals', title: 'Goals.Value', align: 'start', sortable: true },
]
const statHeadersCareer = [
  { title: 'Goals', key: 'CareerStats.Goals', align: 'start', sortable: true },
  { title: 'Assists', key: 'CareerStats.Assists', align: 'start', sortable: true },
  { title: 'Shots on Goal', key: 'CareerStats.Shotsongoal', align: 'start', sortable: true },
  { title: 'Games', key: 'CareerStats.GamesPlayed', align: 'start', sortable: true },
  { title: 'Minutes Played', key: 'CareerStats.Timeingame', align: 'start', sortable: true },
  { title: 'Yellow Cards', key: 'CareerStats.Yellowcards', align: 'start', sortable: true },
  { title: 'Red Cards', key: 'CareerStats.Redcards', align: 'start', sortable: true }
  // { title: 'Goals', title: 'Goals.Value', align: 'start', sortable: true },
]
const statHeadersBySeason = [
  { title: 'Goals', key: 'StatsBySeason.Totals.Goals', align: 'start', sortable: true },
  { title: 'Assists', key: 'StatsBySeason.Totals.Assists', align: 'start', sortable: true },
  { title: 'Shots on Goal', key: 'StatsBySeason.Totals.Shotsongoal', align: 'start', sortable: true },
  { title: 'Games', key: 'StatsBySeason.Totals.GamesPlayed', align: 'start', sortable: true },
  { title: 'Minutes Played', key: 'StatsBySeason.Totals.Timeingame', align: 'start', sortable: true },
  { title: 'Yellow Cards', key: 'StatsBySeason.Totals.Yellowcards', align: 'start', sortable: true },
  { title: 'Red Cards', key: 'StatsBySeason.Totals.Redcards', align: 'start', sortable: true }
  // { title: 'Goals', title: 'Goals.Value', align: 'start', sortable: true },
]
const stats = ref([])
const stat2Headers = [
  { title: 'Goalie', key: 'CurrentSeasonGameStats.Totals.Time_p1', align: 'start', sortable: true },
  { title: 'Sweeper', key: 'CurrentSeasonGameStats.Totals.Time_p2', align: 'start', sortable: true },
  { title: 'Left Backer', key: 'CurrentSeasonGameStats.Totals.Time_p3', align: 'start', sortable: true },
  { title: 'Right Backer', key: 'CurrentSeasonGameStats.Totals.Time_p4', align: 'start', sortable: true },
  { title: 'Stopper', key: 'CurrentSeasonGameStats.Totals.Time_p5', align: 'start', sortable: true },
  { title: 'Left Mid', key: 'CurrentSeasonGameStats.Totals.Time_p6', align: 'start', sortable: true },
  { title: 'Center Mid', key: 'CurrentSeasonGameStats.Totals.Time_p7', align: 'start', sortable: true },
  { title: 'Right Mid', key: 'CurrentSeasonGameStats.Totals.Time_p8', align: 'start', sortable: true },
  { title: 'Striker', key: 'CurrentSeasonGameStats.Totals.Time_p9', align: 'start', sortable: true },
  { title: 'p10', key: 'CurrentSeasonGameStats.Totals.Time_p10', align: 'start', sortable: true },
  { title: 'p11', key: 'CurrentSeasonGameStats.Totals.Time_p11', align: 'start', sortable: true }
]
const stat2HeadersCareer = [
  { title: 'Goalie', key: 'CareerStats.Time_p1', align: 'start', sortable: true },
  { title: 'Sweeper', key: 'CareerStats.Time_p2', align: 'start', sortable: true },
  { title: 'Left Backer', key: 'CareerStats.Time_p3', align: 'start', sortable: true },
  { title: 'Right Backer', key: 'CareerStats.Time_p4', align: 'start', sortable: true },
  { title: 'Stopper', key: 'CareerStats.Time_p5', align: 'start', sortable: true },
  { title: 'Left Mid', key: 'CareerStats.Time_p6', align: 'start', sortable: true },
  { title: 'Center Mid', key: 'CareerStats.Time_p7', align: 'start', sortable: true },
  { title: 'Right Mid', key: 'CareerStats.Time_p8', align: 'start', sortable: true },
  { title: 'Striker', key: 'CareerStats.Time_p9', align: 'start', sortable: true },
  { title: 'p10', key: 'CareerStats.Time_p10', align: 'start', sortable: true },
  { title: 'p11', key: 'CareerStats.Time_p11', align: 'start', sortable: true }
]
const stat2HeadersBySeason = [
  { title: 'Goalie', key: 'StatsBySeason.Totals.Time_p1', align: 'start', sortable: true },
  { title: 'Sweeper', key: 'StatsBySeason.Totals.Time_p2', align: 'start', sortable: true },
  { title: 'Left Backer', key: 'StatsBySeason.Totals.Time_p3', align: 'start', sortable: true },
  { title: 'Right Backer', key: 'StatsBySeason.Totals.Time_p4', align: 'start', sortable: true },
  { title: 'Stopper', key: 'StatsBySeason.Totals.Time_p5', align: 'start', sortable: true },
  { title: 'Left Mid', key: 'StatsBySeason.Totals.Time_p6', align: 'start', sortable: true },
  { title: 'Center Mid', key: 'StatsBySeason.Totals.Time_p7', align: 'start', sortable: true },
  { title: 'Right Mid', key: 'StatsBySeason.Totals.Time_p8', align: 'start', sortable: true },
  { title: 'Striker', key: 'StatsBySeason.Totals.Time_p9', align: 'start', sortable: true },
  { title: 'p10', key: 'StatsBySeason.Totals.Time_p10', align: 'start', sortable: true },
  { title: 'p11', key: 'StatsBySeason.Totals.Time_p11', align: 'start', sortable: true }
]
const otherStatHeaders = [
  { title: 'Shots', key: 'CurrentSeasonGameStats.Totals.ShotsTaken', align: 'start', sortable: true },
  { title: 'Shots on Goal', key: 'CurrentSeasonGameStats.Totals.Shotsongoal', align: 'start', sortable: true },
  { title: 'Saves', key: 'CurrentSeasonGameStats.Totals.Saves', align: 'start', sortable: true },
  { title: 'Goals allowed', key: 'CurrentSeasonGameStats.Totals.ScoredAgainst', align: 'start', sortable: true },
  { title: 'Steals', key: 'CurrentSeasonGameStats.Totals.Steals', align: 'start', sortable: true },
  { title: 'Turnovers', key: 'CurrentSeasonGameStats.Totals.UnforcedErrors', align: 'start', sortable: true },
  { title: 'Offsides', key: 'CurrentSeasonGameStats.Totals.Offsides', align: 'start', sortable: true },
  { title: 'Fouls', key: 'CurrentSeasonGameStats.Totals.Fouls', align: 'start', sortable: true },
  { title: 'Yellow Cards', key: 'CurrentSeasonGameStats.Totals.Yellowcards', align: 'start', sortable: true },
  { title: 'Red Cards', key: 'CurrentSeasonGameStats.Totals.Redcards', align: 'start', sortable: true }
]
const otherSeasonStatHeaders = [
  { title: 'Shots', key: 'StatsBySeason.Totals.ShotsTaken', align: 'start', sortable: true },
  { title: 'Shots on Goal', key: 'StatsBySeason.Totals.Shotsongoal', align: 'start', sortable: true },
  { title: 'Saves', key: 'StatsBySeason.Totals.Saves', align: 'start', sortable: true },
  { title: 'Goals allowed', key: 'StatsBySeason.Totals.ScoredAgainst', align: 'start', sortable: true },
  { title: 'Steals', key: 'StatsBySeason.Totals.Steals', align: 'start', sortable: true },
  { title: 'Turnovers', key: 'StatsBySeason.Totals.UnforcedErrors', align: 'start', sortable: true },
  { title: 'Offsides', key: 'StatsBySeason.Totals.Offsides', align: 'start', sortable: true },
  { title: 'Fouls', key: 'StatsBySeason.Totals.Fouls', align: 'start', sortable: true },
  { title: 'Yellow Cards', key: 'StatsBySeason.Totals.Yellowcards', align: 'start', sortable: true },
  { title: 'Red Cards', key: 'StatsBySeason.Totals.Redcards', align: 'start', sortable: true }
]
const otherCareerStatHeaders = [
  { title: 'Shots', key: 'CareerStats.ShotsTaken', align: 'start', sortable: true },
  { title: 'Shots on Goal', key: 'CareerStats.Shotsongoal', align: 'start', sortable: true },
  { title: 'Saves', key: 'CareerStats.Saves', align: 'start', sortable: true },
  { title: 'Goals allowed', key: 'CareerStats.ScoredAgainst', align: 'start', sortable: true },
  { title: 'Steals', key: 'CareerStats.Steals', align: 'start', sortable: true },
  { title: 'Turnovers', key: 'CareerStats.UnforcedErrors', align: 'start', sortable: true },
  { title: 'Offsides', key: 'CareerStats.Offsides', align: 'start', sortable: true },
  { title: 'Fouls', key: 'CareerStats.Fouls', align: 'start', sortable: true },
  { title: 'Yellow Cards', key: 'CareerStats.Yellowcards', align: 'start', sortable: true },
  { title: 'Red Cards', key: 'CareerStats.Redcards', align: 'start', sortable: true }
]
const player = ref('')
const editedPlayer = {
  id: 0,
  name: ''
}
const search = ref('')
const groupBy1 = ref([10, 11, 12, 13])
const groupBy1res = ref(0)
const groupBy2 = ref(['M', 'F', 'ALL'])
const groupBy2res = ref('ALL')
const showList = ref(true)
const showEdit = ref(false)
const showStats = ref(false)
const reallyEdit = ref(false)
const editPlayerName = ref('')
const action = ref('Add Player')
const selectedPlayers = ref([])
const season = ref('')

const pickDate = ref(false)
const genders = [
  'M',
  'F'
]
const statuses = [
  'Active',
  'Injured',
  'Invited',
  'Withdrawn',
  'Accepted',
  'Declined'
]
const playerID = ref('')
const firstName = ref('')
const lastName = ref('')
const jerseyNumber = ref(0)
const seasonStatus = ref('Active')
const gender = ref('')
const birthdate = ref('Set birthdate')
const csvUpload = ref()
const uploadResult = ref('')
const csvProcessing = ref(false)
const guardian1 = ref('')
const guardian2 = ref('')
const medicalConditions = ref('')
const showTemplate = ref(false)
// const birthdateDisplay = ref('')

// watch: {
//   selectedPlayers: function(value){
//   this.playerCount = value.length
// }

const activatePlayer = () => {
  console.log(selectedPlayers)
  const data = {
    SeasonID: store.TeamSeason,
    OrgID: store.OrgID,
    Players: selectedPlayers.value
  }
  const dataJson = JSON.stringify(data)

  fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/gm/activatePlayers', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'BEARER: ' + store.AuthToken },
    body: dataJson
  })

  refreshPlayers()
}

const setDate = () => {
  pickDate.value = !pickDate.value

  const ye = new Intl.DateTimeFormat('en', { year: 'numeric', timeZone: store.timeZone }).format(birthdate.value)
  const mo = new Intl.DateTimeFormat('en', { month: 'short', timeZone: store.timeZone }).format(birthdate.value)
  const da = new Intl.DateTimeFormat('en', { day: 'numeric', timeZone: store.timeZone }).format(birthdate.value)
  birthdate.value = ye + '-' + mo + '-' + da

  console.log(birthdate.value)
}

const getSeasonPlayerList = async () => {
  seasonPlayers.value = await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/gm/getOrgPlayersBySeason' + '/' + store.OrgID + '/' + store.TeamSeason).then((res) => res.json())
  // const tmp = []
  // allPlayers.value.forEach(p => {
  //   if (p.player_status === 'Active') {
  //     tmp.push(p)
  //   }
  // })
  // seasonPlayers.value = tmp
}

const getAllPlayerList = async () => {
  allPlayers.value = await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/gm/getOrgPlayers' + '/' + store.OrgID).then((res) => res.json())
  // console.log(allPlayers.value)

  await getSeasonPlayerList()
}

const searchPlayer = (value, search) => {
  return value != null &&
      search != null &&
      typeof value === 'string' &&
      value.toString().toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) !== -1
}

const addPlayer = () => {
  showEdit.value = true
  showList.value = false
  action.value = 'Add Player'
}

const editPlayer = async (item) => {
  // const index = this.allPlayers.indexOf(item);
  // console.log('Edit '+item.name)
  // set edit flag
  // await this.$store.commit('togglePlayerEdit', item.name);

  action.value = 'Edit ' + item.raw.name
  editPlayerName.value = item.raw.name
  firstName.value = item.raw.first_name
  lastName.value = item.raw.last_name
  jerseyNumber.value = item.raw.jersey_number
  gender.value = item.raw.gender
  birthdate.value = item.raw.birth_date || 'click to set'
  seasonStatus.value = item.raw.player_status
  playerID.value = item.raw.player_id
  guardian1.value = item.raw.guardian1
  guardian2.value = item.raw.guardian2
  medicalConditions.value = item.raw.medical_conditions

  reallyEdit.value = true
  showEdit.value = true
  showList.value = false
}

const cancelEdit = () => {
  showEdit.value = false
  showList.value = true
  reallyEdit.value = false
}

const save = async () => {
  // perform save
  const Gamedata = {
    SeasonName: store.TeamSeason,
    OrgID: Number(store.OrgID),
    PlayerID: playerID.value,
    FirstName: firstName.value,
    LastName: lastName.value,
    JerseyNumber: Number(jerseyNumber.value),
    SeasonStatus: seasonStatus.value,
    BirthDate: birthdate.value,
    Gender: gender.value,
    Guardian1: guardian1.value,
    Guardian2: guardian2.value,
    MedicalConditions: medicalConditions.value,
    TeamID: store.TeamID,
    SeasonID: store.TeamSeason,
    Edit: reallyEdit.value
  }
  const dataJson = JSON.stringify(Gamedata)
  // console.log(dataJson)

  await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/gm/addPlayer', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'BEARER: ' + store.AuthToken },
    body: dataJson
  }).then((res) => res.json())

  showEdit.value = false
  showList.value = true
  reallyEdit.value = false
}

const toggleTemplateShow = () => {
  showTemplate.value = !showTemplate.value
}

const playerStats = async (item) => {
  // const index = seasonPlayers.value.indexOf(item.raw)
  const pid = item.raw.player_id // seasonPlayers[index].player_id
  // reset the array
  stats.value = []

  console.log(item.raw)
  const data = await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/gm/getPlayerStats' + '/' + pid + '/' + store.TeamSeason + '/' + store.OrgID).then((res) => res.json())
  stats.value.push(data)

  player.value = item.raw.name
  season.value = store.TeamSeason
  showStats.value = true
  showList.value = false
}

const group = () => {
  console.log('group ')
}

const playerRating = (item) => {
  // item.name
  alert('not implemented' + item)
}

const hideStats = () => {
  showStats.value = false
  showList.value = true
}

const refreshPlayers = async () => {
  await getAllPlayerList()
}

const uploadBulkPlayers = (files) => {
  csvProcessing.value = true
  const uploads = files.target.files

  // This will always be just one file
  for (let i = 0; i < uploads.length; i++) {
    const fname = uploads[i].name
    const fdata = new FileReader()
    fdata.readAsDataURL(uploads[i])
    fdata.addEventListener('load', async () => {
      const imageUrl = fdata.result
      const m = {
        Filename: fname,
        FileData: imageUrl,
        GameID: store.GameID,
        UserID: store.auth.user.uid,
        OrgID: store.OrgID
      }
      // console.log(m)
      const ipt = await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/gm/bulkPlayerImport', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'BEARER: ' + store.AuthToken },
        body: JSON.stringify(m)
      })

      if (ipt.ok) {
        uploadResult.value = 'Players have been imported'
      } else {
        uploadResult.value = 'ERROR importing players'
      }
      csvUpload.value = ''
    })
  }
  csvProcessing.value = false
}

onMounted(() => {
  refreshPlayers()
})
</script>

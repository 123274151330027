<template>
  <v-sheet color="background" class="pa-0 ma-0">

    <v-card class="ma-4 pa-4" rounded="xl">
      <v-sheet color="background" rounded="xl">
        <v-card-title class="pa-0 ma-2">{{team.name}}</v-card-title>
        <v-card-text class="pa-0 ma-2">
          <v-row class="pa-0 ma-0">
            <v-col cols="auto"  v-if="teamSelected" class="pa-0 ma-2">
              <v-chip class="ma-2" color="green" variant="flat"  @click="createGame()" v-if="admin">New Game</v-chip>
              <v-chip class="ma-2" color="blue" variant="flat"  @click="teamStats()" >Team Season Stats</v-chip>
              <v-chip class="ma-2" color="blue" variant="flat"  @click="formationEditor()" v-if="admin">Formation Editor</v-chip>
            </v-col>
          </v-row>
        </v-card-text>
      </v-sheet>
    </v-card>

    <FormationAdd v-if="formationDisplay" :NumPlayers="numPlayers" />

    <v-card v-if="teamSelected" class="pa-4 ma-4 justify-center" rounded="xl" elevation="0">
      <v-sheet color="background" rounded="xl">
        <v-card-title class="pa-4 ma-4">
          Game Schedule
        </v-card-title>
        <v-card-text class="justify-center">
          <v-row>
            <v-col v-for="g in teamGames" v-bind:key="g.game_id">
              <v-card class="pa-1 mb-4 justify-center" rounded="xl" min-width="200" max-width="265">
                <v-card-text>
                  <!-- <v-row>
                    <v-col v-if="!g.scrimmage">
                      Match vs <br/><b>{{ g.opponent }}</b>
                    </v-col>
                    <v-col v-if="g.scrimmage">
                      <b>Scrimmage</b> vs <br/><b>{{ g.opponent }}</b>
                    </v-col>
                    <v-col>
                      <v-chip><v-icon>mdi-clock</v-icon> {{ g.current_game_time }} </v-chip>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="pa-0 ma-0">
                      {{ g.game_date }}
                    </v-col>
                  </v-row> -->

                  <v-row>
                    <v-col cols="12">
                      <b v-if="g.scrimmage">** Scrimmage ** <br/></b>
                      {{ g.game_date }}
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <scoreboard :t1="team.name" :t1score="g.our_score" :t2="g.opponent" :t2score="g.their_score" :time="g.current_game_time" :finished="g.game_final"/>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-chip class="ma-2" variant="flat"  color="green" @click="watchGame(g.game_id, false)">Spectate</v-chip>
                      <v-chip class="ma-2" variant="flat"  @click="setGame(g.game_id)" color="blue" v-if="admin">Log Game</v-chip>
                      <v-chip class="ma-2" variant="flat"  @click="coachesNotes(g.game_id)" color="blue" v-if="admin">Coaches Notes</v-chip>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-sheet>
    </v-card>

    <v-card v-if="teamSelected" class="pa-4 ma-4 justify-center" rounded="xl" elevation="0">
      <v-sheet color="background" rounded="xl">
        <v-card-title class="pa-4 ma-4">
          Previous game outcomes
        </v-card-title>
        <v-card-text class="justify-center">
          <v-row>
            <v-col v-for="g in teamGamesDone" v-bind:key="g.game_id">
              <v-card class="pa-1 mb-4 justify-center" rounded="xl" min-width="200" max-width="265">
                <v-card-text class="justify-center">
                  <!-- <v-row>
                    <v-col cols="8">
                      <v-row>
                        <v-col v-if="!g.scrimmage">
                          Match vs <br/><b>{{ g.opponent }}</b>
                        </v-col>
                        <v-col v-if="g.scrimmage">
                          <b>Scrimmage</b> vs <br/><b>{{ g.opponent }}</b>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          {{ g.game_date }}
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="4">
                      {{ g.our_score }} - {{ g.their_score}}
                      <v-chip class="ma-2" variant="flat" v-if="g.our_score == g.their_score" color="orange">Draw</v-chip>
                      <v-chip class="ma-2" variant="flat" v-if="g.our_score > g.their_score" color="green">Win</v-chip>
                      <v-chip class="ma-2" variant="flat" v-if="g.our_score < g.their_score" color="red">Loss</v-chip>
                    </v-col>
                  </v-row> -->

                  <v-row>
                    <v-col cols="12">
                      <b v-if="g.scrimmage">** Scrimmage ** <br/></b>
                      {{ g.game_date }}
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <scoreboard :t1="team.name" :t1score="g.our_score" :t2="g.opponent" :t2score="g.their_score" :time="g.current_game_time" :finished="g.game_final"/>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-chip class="ma-2" variant="flat" color="blue" @click="watchGame(g.game_id, true)">Log</v-chip>
                      <v-chip class="ma-2" variant="flat" color="blue"  @click="gameStats(g.game_id)">Stats</v-chip>
                      <v-chip class="ma-2" variant="flat"  @click="coachesNotes(g.game_id)" color="blue" v-if="admin">Coaches Notes</v-chip>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-sheet>
    </v-card>

    <v-dialog
      v-model="showCoachNotes"
      width="auto"
    >
      <CoachNotes :gameid="store.GameID" @cb="coachesNotes(store.GameID)" />
    </v-dialog>

  </v-sheet>
</template>

<script setup lang="ts">
import { reactive, ref } from '@vue/reactivity'
import { computed, onMounted } from 'vue-demi'
import { useRouter, useRoute } from 'vue-router'
import FormationAdd from '../components/FormationAdd.vue'
import { useGameState } from '../store/index'
import { getPerms } from '../composables/userPerms'
import CoachNotes from '../components/CoachesNotes.vue'
import { logActivity } from '@/composables/telemetry'
import scoreboard from '../components/scoreboardChips.vue'

const store = useGameState()
const router = useRouter()
const route = useRoute()
const showCoachNotes = ref(false)

const teamSelected = computed(() => {
  if ((store.TeamID !== '') && (store.TeamName !== '')) {
    return true
  }
})
const team = reactive({
  name: '',
  id: ''
})
const teamGames = ref([{
  game_id: String,
  opponent: String,
  game_date: String,
  our_score: Number,
  their_score: Number,
  game_field: String
}])
const teamGamesDone = ref([{
  game_id: String,
  opponent: String,
  game_date: String,
  our_score: Number,
  their_score: Number,
  game_field: String
}])

const admin = ref(false)
const formationDisplay = ref(false)
let numPlayers = ref(0)

const getTeamInformation = async () => {
  const data = await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/getTeamInfo' + '/' + store.OrgID + '/' + store.TeamID).then((res) => res.json())
  // console.log(data)
  numPlayers = data.max_players
}

const checkPermissions = async () => {
  const perms = await getPerms(store)

  // console.log(perms)

  if (perms.RBAC === 'Viewer') {
    admin.value = false
  } else {
    admin.value = true
  }
}

const listTeamGames = async () => {
  // get upcoming games for this.org and this.team and update this.teamGames
  teamGames.value = await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/gm/getTeamGames/' + store.OrgID + '/' + store.TeamID).then((res) => res.json())

  // teamSelected = true
  // get old game results
  teamGamesDone.value = await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/gm/getTeamGameResults/' + store.OrgID + '/' + store.TeamID).then((res) => res.json())
}

const setGame = (id) => {
  // set the game id and route there
  store.setGameID(id)
  store.setViewOnly(false)
  router.push({ path: '/gamelog' })
}

// const editGame = (id) => {
//   // set the game id and route there
//   store.setGameID(id)
//   store.setViewOnly(false)
//   store.setMassEdit(true)
//   router.push({ path: '/gamelog' })
// }

const watchGame = (id, log) => {
  // set the game id and route there
  store.setGameID(id)
  store.setViewOnly(true)
  store.setViewOnlyLog(log)
  router.push({ path: '/gamelog' })
}

const gameStats = (id) => {
  store.setGameID(id)
  router.push({ path: '/gameStats' })
}

const createGame = () => {
  router.push({ path: '/createGame' })
}

const teamStats = () => {
  router.push({ path: '/teamStats' })
}

const formationEditor = () => {
  formationDisplay.value = !formationDisplay.value
}

const coachesNotes = (gid) => {
  store.setGameID(gid)
  showCoachNotes.value = !showCoachNotes.value
}

onMounted(async () => {
  await checkPermissions()
  team.id = store.TeamID //.toString()
  team.name = store.TeamName //.toString()

  store.setActivePage(route.name)

  // Reset state for the game
  store.resetGameStats()
  // store.setGamePeriod('Not Started')
  // store.updateLastTimestamp(0)
  // store.updateGameTime('00:00:00')
  // store.changeGameInProgress(false)
  // store.setGamePaused('false')
  // store.setHalfTime('false')
  // store.setScoreThem(0)
  // store.setScoreUs(0)
  // store.setOurShots(0)
  // store.setTheirShots(0)
  // store.setAssists(0)
  // store.setOpponent('')
  // store.setGameID('')

  // this.getOrgs()
  // unset some things in the store
  // FIXME ??
  // localStorage.removeItem('gameState.GamePeriod')
  // localStorage.removeItem('gameState.GameTime')
  // localStorage.removeItem('gameState.GameID')

  // if ((team.id.toString() !== '') && (team.name.toString() !== '')) {
  if ((team.id !== '') && (team.name !== '')) {
    await listTeamGames()
  }
  await getTeamInformation()

  logActivity(store, route.name, 'myTeam.vue', '')
})

</script>

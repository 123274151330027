<template>
  <v-card class="pa-0 ma-4 mb-4" rounded="xl">
    <v-card-title class="pa-0 ma-0" >
        Create a base formation
    </v-card-title>
    <v-card-text class="pa-0 ma-4">
      <v-sheet color="white" rounded="xl" class="pa-4">
        <v-row>
            <v-col md="4" lg="2" cols="4">
                <v-select v-model="numPlayers" label="Max positions"  min-width="75" max-width="100" variant="underlined" :items="numPlayersList" ></v-select>
            </v-col>
            <v-col sm="6" cols="8">
                <v-text-field v-model="formationName" label="Formation Name" min-width="200" max-width="350" variant="underlined" :rules="[rules.required, CheckUniqueness]"></v-text-field>
            </v-col>
        </v-row>
      </v-sheet>
    </v-card-text>
      <v-card-text class="pa-0 ma-0" v-if="formationName != ''">
        <p>Click on tile to enable and assign to the formation.</p>
        <h3>{{calcPostionsLeft}} left to complete out of {{numPlayers}}</h3>
        <v-row v-for="r in emptyFormation" v-bind:key="r.RowID" class="pa-4 ma-0" >
          <v-col cols="1">
            <v-spacer></v-spacer>
          </v-col>
            <v-col v-for="c, idx in r.Cols" v-bind:key="c.id" class="pa-2 ma-0" cols="2" >
                <div @click="choosePosition(r.RowID, idx)" v-if="calcPostionsLeftMethod() > 0">
                    <FormationPlayerChip :Player="c" />
                </div>
                <div v-else>
                  <FormationPlayerChip :Player="c" />
                </div>
            </v-col>
        </v-row>
        <v-spacer></v-spacer>
        <v-btn rounded="xl" @click="resetFormation()" color="amber">Reset Formation</v-btn>
        &nbsp;
        <v-btn rounded="xl" @click="saveFormation()" color="blue" :disabled="calcPostionsLeft != 0 && formationNameUnique">Save Formation</v-btn>
        <v-spacer></v-spacer>
      </v-card-text>
  </v-card>

  <v-dialog v-model="renameDialog" width="450px">
    <form>
    <v-card class="pa-2">
      <v-card-text>
        <v-text-field label="Position Name" v-model="posName" filled></v-text-field>

        <v-label>
          Position exertion factor
        </v-label>
        <v-slider
          v-model="exertionLevel"
          show-ticks="always"
          step=".25"
          min=".5"
          max="6"
        ></v-slider>
        <v-label>
          Suggested time until rotation (minutes): {{subTimeCalc}}
        </v-label>
        <v-spacer></v-spacer>
        <v-btn rounded="xl" @click="editPosition()" color="blue">Save Changes</v-btn>
        &nbsp;
        <v-btn rounded="xl" @click="cancelDialog()" color="amber">Cancel</v-btn>
        <v-spacer></v-spacer>
      </v-card-text>
    </v-card>
    </form>

  </v-dialog>
</template>

<script lang="ts" setup>
import { ref } from '@vue/reactivity'
import { computed, onMounted, defineProps } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useGameState } from '../store/index'
import FormationPlayerChip from './formationPlayerChip.vue'

const store = useGameState()
const router = useRouter()
const route = useRoute()

const props = defineProps(['Org', 'NumPlayers', 'ID'])

// const props = {
//   Org: Boolean,
//   NumPlayers: Number
// }

const rules = {
  required: value => !!value || 'Required.'
}
const numPlayers = ref(0)
const numPlayersList = [4, 7, 9, 11]
const formationName = ref('')
const emptyFormation = ref([
  {
    RowID: 5,
    Cols: [
      {
        id: 25,
        position: 'pick',
        ProfilePic: 'v.png'
      },
      {
        id: 26,
        position: 'pick',
        ProfilePic: 'v.png'
      },
      {
        id: 27,
        position: 'pick',
        ProfilePic: 'v.png'
      },
      {
        id: 28,
        position: 'pick',
        ProfilePic: 'v.png'
      },
      {
        id: 29,
        position: 'pick',
        ProfilePic: 'v.png'
      }
    ]
  },
  {
    RowID: 4,
    Cols: [
      {
        id: 20,
        position: 'pick',
        ProfilePic: 'v.png'
      },
      {
        id: 21,
        position: 'pick',
        ProfilePic: 'v.png'
      },
      {
        id: 22,
        position: 'pick',
        ProfilePic: 'v.png'
      },
      {
        id: 23,
        position: 'pick',
        ProfilePic: 'v.png'
      },
      {
        id: 24,
        position: 'pick',
        ProfilePic: 'v.png'
      }
    ]
  },
  {
    RowID: 3,
    Cols: [
      {
        id: 15,
        position: 'pick',
        ProfilePic: 'v.png'
      },
      {
        id: 16,
        position: 'pick',
        ProfilePic: 'v.png'
      },
      {
        id: 17,
        position: 'pick',
        ProfilePic: 'v.png'
      },
      {
        id: 18,
        position: 'pick',
        ProfilePic: 'v.png'
      },
      {
        id: 19,
        position: 'pick',
        ProfilePic: 'v.png'
      }
    ]
  },
  {
    RowID: 2,
    Cols: [
      {
        id: 10,
        position: 'pick',
        ProfilePic: 'v.png'
      },
      {
        id: 11,
        position: 'pick',
        ProfilePic: 'v.png'
      },
      {
        id: 12,
        position: 'pick',
        ProfilePic: 'v.png'
      },
      {
        id: 13,
        position: 'pick',
        ProfilePic: 'v.png'
      },
      {
        id: 14,
        position: 'pick',
        ProfilePic: 'v.png'
      }
    ]
  },
  {
    RowID: 1,
    Cols: [
      {
        id: 5,
        position: 'pick',
        ProfilePic: 'v.png'
      },
      {
        id: 6,
        position: 'pick',
        ProfilePic: 'v.png'
      },
      {
        id: 7,
        position: 'pick',
        ProfilePic: 'v.png'
      },
      {
        id: 8,
        position: 'pick',
        ProfilePic: 'v.png'
      },
      {
        id: 9,
        position: 'pick',
        ProfilePic: 'v.png'
      }
    ]
  },
  {
    RowID: 0,
    Cols: [
      {
        id: 0,
        position: 'pick',
        ProfilePic: 'v.png',
        hide: true
      },
      {
        id: 1,
        position: 'pick',
        ProfilePic: 'v.png',
        hide: true
      },
      {
        id: 2,
        position: 'Goalie',
        ProfilePic: 'v.png'
      },
      {
        id: 3,
        position: 'pick',
        ProfilePic: 'v.png',
        hide: true
      },
      {
        id: 4,
        position: 'pick',
        ProfilePic: 'v.png',
        hide: true
      }
    ]
  }
])
let editRow = 0
let editCol = 0
const renameDialog = ref(false)
const posName = ref('')
const positionsSelected = ref(1)
const positionCordinates = ref([])
const exertionLevel = ref(0.50)
const formations = []
const template = ref(false)
const formationNameUnique = ref(false)

const calcPostionsLeft = computed(() => {
  return numPlayers.value - positionsSelected.value
  // calcPostionsLeftMethod()
})

const subTimeCalc = computed(() => {
  const exertionFactor = exertionLevel.value / 4
  // console.log('exertion factor: '+exertionFactor)
  const suggestedTime = 30
  // loop over each minute of a half
  for (let i = 1; i <= 30; i++) {
    // console.log( exertionFactor * i)
    if ((exertionFactor * i) >= 8) {
      return i
    }
  }
  return suggestedTime
})

const getBaseFormations = async () => {
  // get updated current state
  // formations.values = await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/gm/getBaseFormations/' + store.OrgID + '/all').then((res) => res.json())
  formations.values = await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/gm/getFormations/' + store.OrgID + '/all').then((res) => res.json())
}

const CheckUniqueness = (name) => {
  let unique = 'Valid formation name'
  formations.values.forEach(function (v) {
    if (v.Name === name) {
      unique = 'Name is not unique'
      formationNameUnique.value = false
    }
    formationNameUnique.value = true
  })
  return unique
}

const calcPostionsLeftMethod = () => {
  return numPlayers.value - positionsSelected.value
}

const choosePosition = (r, c) => {
  editRow = Math.abs(r - 5)
  editCol = c
  renameDialog.value = true
}

const editPosition = () => {
  const old = emptyFormation.value[editRow].Cols[editCol]
  console.log(old)
  old.position = posName.value
  old.ExertionFactor = exertionLevel.value / 4
  emptyFormation.value[editRow].Cols[editCol] = old
  const pos = {
    X: Math.abs(editRow - 5),
    Y: editCol,
    Name: posName.value
  }
  positionCordinates.value.push(pos)
  renameDialog.value = false
  positionsSelected.value++
  posName.value = ''
}

const resetFormation = () => {
  emptyFormation.value = [
    {
      RowID: 5,
      Cols: [
        {
          id: 25,
          position: 'pick',
          ProfilePic: 'v.png'
        },
        {
          id: 26,
          position: 'pick',
          ProfilePic: 'v.png'
        },
        {
          id: 27,
          position: 'pick',
          ProfilePic: 'v.png'
        },
        {
          id: 28,
          position: 'pick',
          ProfilePic: 'v.png'
        },
        {
          id: 29,
          position: 'pick',
          ProfilePic: 'v.png'
        }
      ]
    },
    {
      RowID: 4,
      Cols: [
        {
          id: 20,
          position: 'pick',
          ProfilePic: 'v.png'
        },
        {
          id: 21,
          position: 'pick',
          ProfilePic: 'v.png'
        },
        {
          id: 22,
          position: 'pick',
          ProfilePic: 'v.png'
        },
        {
          id: 23,
          position: 'pick',
          ProfilePic: 'v.png'
        },
        {
          id: 24,
          position: 'pick',
          ProfilePic: 'v.png'
        }
      ]
    },
    {
      RowID: 3,
      Cols: [
        {
          id: 15,
          position: 'pick',
          ProfilePic: 'v.png'
        },
        {
          id: 16,
          position: 'pick',
          ProfilePic: 'v.png'
        },
        {
          id: 17,
          position: 'pick',
          ProfilePic: 'v.png'
        },
        {
          id: 18,
          position: 'pick',
          ProfilePic: 'v.png'
        },
        {
          id: 19,
          position: 'pick',
          ProfilePic: 'v.png'
        }
      ]
    },
    {
      RowID: 2,
      Cols: [
        {
          id: 10,
          position: 'pick',
          ProfilePic: 'v.png'
        },
        {
          id: 11,
          position: 'pick',
          ProfilePic: 'v.png'
        },
        {
          id: 12,
          position: 'pick',
          ProfilePic: 'v.png'
        },
        {
          id: 13,
          position: 'pick',
          ProfilePic: 'v.png'
        },
        {
          id: 14,
          position: 'pick',
          ProfilePic: 'v.png'
        }
      ]
    },
    {
      RowID: 1,
      Cols: [
        {
          id: 5,
          position: 'pick',
          ProfilePic: 'v.png'
        },
        {
          id: 6,
          position: 'pick',
          ProfilePic: 'v.png'
        },
        {
          id: 7,
          position: 'pick',
          ProfilePic: 'v.png'
        },
        {
          id: 8,
          position: 'pick',
          ProfilePic: 'v.png'
        },
        {
          id: 9,
          position: 'pick',
          ProfilePic: 'v.png'
        }
      ]
    },
    {
      RowID: 0,
      Cols: [
        {
          id: 0,
          position: 'pick',
          ProfilePic: 'v.png',
          hide: true
        },
        {
          id: 1,
          position: 'pick',
          ProfilePic: 'v.png',
          hide: true
        },
        {
          id: 2,
          position: 'Goalie',
          ProfilePic: 'v.png'
        },
        {
          id: 3,
          position: 'pick',
          ProfilePic: 'v.png',
          hide: true
        },
        {
          id: 4,
          position: 'pick',
          ProfilePic: 'v.png',
          hide: true
        }
      ]
    }
  ]
  formationName.value = ''
  numPlayers.value = props.NumPlayers
  positionsSelected.value = 1
}

const saveFormation = async () => {
  // save emptyFormation, formationName to the DB
  const formation = {
    Name: formationName.value,
    Matrix: emptyFormation.value,
    OrgID: store.OrgID,
    TeamID: store.TeamID,
    Template: template.value,
    NumPlayers: numPlayers.value,
    Edit: false
  }
  const data = JSON.stringify(formation)
  // console.log(data)
  await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/gm/saveFormation', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'BEARER: ' + store.AuthToken },
    body: data
  })

  router.push({ path: '/myTeam' })
  // console.log(positionCordinates.value)
  resetFormation()
  cancelDialog()
}

const cancelDialog = () => {
  renameDialog.value = false
}

onMounted(() => {
  store.setActivePage(route.name)
  // save goalie to positions
  const pos = {
    X: 0,
    Y: 2,
    Name: 'Goalie'
  }
  positionCordinates.value.push(pos)

  if (props.Org) {
    template.value = true
  }
  if (props.NumPlayers > 0) {
    numPlayers.value = props.NumPlayers
  }
  if (props.ID > 0) {
    // editing an existing formation
    // Need to get the data from the backend.
    console.log('needs attention')
    console.log(props.ID)
  }
  // console.log(props)
  getBaseFormations()
})

</script>

<template>
    <v-card class="ma-0 pa-0">
        <v-card-title>
            {{Player.name}} Information
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="mb-4">
              <playerChip :Player="Player" viewall="true" @cb="subOut"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="auto" md="4" cols="12" class="pa-2 ma-0" align="center">
              <v-btn rounded="xl" @click="close()" color="green">close</v-btn>
            </v-col>
            <v-col sm="auto" md="4" cols="12" class="pa-2 ma-0" align="center">
              <v-btn rounded="xl" @click="subOut()" color="blue">Sub Out</v-btn>
            </v-col>
            <v-col sm="auto" md="4" cols="12" class="pa-2 ma-0" align="center">
              <v-btn rounded="xl" @click="toggleHealth()" color="red">{{injured}}</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <!-- <v-card-actions>
            <v-btn rounded="xl" @click="close()">close</v-btn>
            <v-btn rounded="xl" @click="subOut()" color="blue">Sub Out</v-btn>
            <v-btn rounded="xl" @click="toggleHealth()">{{injured}}</v-btn>
        </v-card-actions> -->
    </v-card>
</template>

<script lang="ts" setup>
import { ref } from '@vue/reactivity'
import { defineProps, defineEmits } from 'vue'
import playerChip from './playerChip.vue'

const props = defineProps(['Player'])
const emits = defineEmits(['cb'])

const injured = ref('Injured')

const subOut = () => {
  console.log(props.Player)
  emits('cb', 'subout', props.Player)
  // this.$emit.cb"subout", this.Player)
}

const close = () => {
  emits('cb', 'close')
}

const toggleHealth = () => {
  if (injured.value === 'Injured') {
    injured.value = 'Healthy'
    // props.Player.Healthy.value = true
  } else {
    // props.Player.Healthy.value = false
    injured.value = 'Injured'
  }
  // FIXME save new state for player
}

</script>

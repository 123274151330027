<template>
    <!-- <v-sheet class="pt-0 ma-0" color="background" rounded="xl"> -->

        <v-card class="pa-2 ma-0" elevation="0" rounded="xl">
            <v-card-title class="pa-0 pb-2 ma-0">
                Formation {{formation}} <v-icon @click="changeFormation = !changeFormation">mdi-file-document-edit</v-icon>
            </v-card-title>
            <!-- Draw the grid of players in their positions -->
            <v-card-text class="pa-0 ma-0">
                <v-row v-for="r in matrix" v-bind:key="r.RowID" class="pa-0 ma-0">
                    <v-col v-for="c in r.Cols" v-bind:key="c.id" class="pa-0 ma-0" align="center" xs="4">
                        <playerInGame :Player="c" :InGame="matrix" :Bench="Bench" @cb="commitSubs"/>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-card class="pa-0 mt-4" v-if="subs2commit" elevation="0" rounded="xl">
            <v-card-title class="pa-0 ma-0">
                Pending subs
            </v-card-title>
            <v-card-text class="pa-0 ma-0">
              <v-row v-for="s, index in subList" v-bind:key="s.OldPositionID">
                <v-col class="pa-2 ma-2">
                  {{s.NewPlayer}} From {{s.OldPlayerNewPosition}} IN for {{s.OldPlayer}} @ {{s.NewPosition}} <v-icon @click="cancelSub(index)" color="red">mdi-delete</v-icon>
                </v-col>
              </v-row>
              <v-row>
                <v-col sm="5" md="2" cols="5" offset-md="4" align="center">
                  <v-btn rounded="xl" color="red" @click="resetSubs()">Reset Subs</v-btn>
                </v-col>
                <v-col sm="5" md="2" cols="5" align="center">
                  <v-btn rounded="xl" color="amber" @click="saveSubs()">{{ numSubs}} Subs Entered</v-btn>
                </v-col>
              </v-row>
            </v-card-text>
        </v-card>

        <v-spacer></v-spacer>

        <v-sheet fluid rounded="xl">
          <PlayerTime :matrix="matrix" :bench="Bench" />
        </v-sheet>

        <v-card class="pa-4 mt-4" elevation="0" rounded="xl">
          <v-card-title class="pa-0 ma-0">
            Game Log
          </v-card-title>
          <v-card-text class="pa-0 ma-0">
            <v-data-table
              :headers="logHeaders"
              :items="logs"
              class="elevation-0 py-0 px-0"
              mobile-breakpoint="0"
              :items-per-page="5"
              :fixed-header=false
            >
            </v-data-table>
          </v-card-text>
        </v-card>

        <v-dialog
          v-model="changeFormation">
          <v-card>
            <v-card-text>
              <v-select
                ref="fixme-001"
                v-select-dirty-fix="'fixme-001'"
                v-model="newFormation"
                :items="formations"
                label="Choose Formation"
                item-title="Name"
                item-value="ID"
                return-object
              ></v-select>
            </v-card-text>
          </v-card>
        </v-dialog>
    <!-- </v-sheet> -->
</template>

<script setup lang="ts">
import { ref } from '@vue/reactivity'
import { onMounted, watch, onUnmounted } from 'vue'
import playerInGame from './playerInGame.vue'
import PlayerTime from '@/components/PlayerTime.vue'
import { useGameState } from '../store/index'
const store = useGameState()

const matrix = ref([])
const subList = ref([])
const Bench = ref([])
const subs2commit = ref(false)
const numSubs = ref(0)
const formation = ref('-unset-')
const logHeaders = [
  { title: 'Game Time', key: 'GameTime', align: 'start', sortable: true },
  { title: 'Event', key: 'GameEvent', align: 'start', sortable: false }
]
const logs = ref([])
const changeFormation = ref(false)
const newFormation = ref('')
const formations = ref([])
let GameLogs = {}

watch(store.subs, async () => {
  if (store.subs.length > 0) {
    await commitSubs()
  }
})

watch(newFormation, async () => {
  saveFormationChange()
})

GameLogs = window.setInterval(
  function () {
    getGameLogs()
  }, 30000)

const getGameData = async () => {
  // get updated current state
  const data = await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/gm/getGameData/' + store.GameID).then((res) => res.json())
  formation.value = data.formation
}

const getInGame = async () => {
  const data = await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/gm/getInGamePlayers/' + store.GameID).then((res) => res.json())
  store.setInGame(data)
  // matrix.value = data
  matrix.value = await updateList(data, false)
}

const getBench = async () => {
  const data = await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/gm/getBenchPlayers/' + store.GameID).then((res) => res.json())
  store.setOnBench(data)
  // Bench.value = data
  // update bench so that pending subs can't be chosen again (change icon)
  Bench.value = await updateList(data, true)
}

const getGameLogs = async () => {
  logs.value = await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/gm/getGameLog/' + store.GameID).then((res) => res.json())
}

const commitSubs = async () => {
  subList.value = store.subs
  numSubs.value = subList.value.length
  if (numSubs.value > 0) {
    subs2commit.value = true
  }
  // const b = await updateList(Bench.value, true)
  // Bench.value = b
  // const m = await updateList(matrix.value, false)
  // matrix.value = m
  matrix.value = []
  await getInGame()
  Bench.value = []
  await getBench()
  await getGameLogs()
}

const saveSubs = async () => {
  // save subs to database (not temp)
  const subs = {
    OrgID: store.OrgID,
    TeamID: store.TeamID,
    GameID: store.GameID,
    GameTime: store.GameTime,
    GameTimestamp: store.lastTimeStamp,
    Season: store.TeamSeason,
    SubList: store.subs
  }
  const dataJson = JSON.stringify(subs)

  // console.log(dataJson)
  await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/gm/setInGamePlayers', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'BEARER: ' + store.AuthToken },
    body: dataJson
  })

  resetSubs()
}

const resetSubs = async () => {
  store.subPush([])
  subs2commit.value = false
  // reset the arrays
  await commitSubs()
}

const cancelSub = async (index) => {
  // remove index from the subList array
  subList.value.splice(index, 1)
  store.subPush(subList)
  // reset the arrays
  await commitSubs()
}

// ### vuetify select temp fix ###
// https://github.com/vuetifyjs/vuetify/issues/16777
const vSelectDirtyFix = {
  mounted: function (el, binding, vnode) {
    const name = binding.value
    const refs = binding.instance.$refs
    const sel = refs[name]
    if (sel) {
      binding.instance.$nextTick(() => {
        sel.menu = true
        setTimeout(function () {
          sel.menu = false
        }, 200)
      })
    }
  }
}

const saveFormationChange = async () => {
  const changes = {
    GameID: store.GameID,
    CurrentGameTime: store.GameTime,
    LastTimestamp: store.lastTimeStamp,
    FormationID: newFormation.value.ID
  }
  const dataJson = JSON.stringify(changes)

  await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/gm/updateGame', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'BEARER: ' + store.AuthToken },
    body: dataJson
  })

  store.setTeamFormation(newFormation.value.ID)

  // re-draw formation
  await getGameData()
  await getInGame()
  await getGameLogs()
  // hide form
  changeFormation.value = false
}

const updateList = async (arr: [], bench: boolean) => {
  // not very efficient
  for (const s of store.subs) {
    // find person in array and change profile pic as an indicator of subbing in/out
    for (const row of arr) {
      for (const p of row.Cols) {
        if (bench) {
          if (s.OldPositionID === p.id) {
            p.ProfilePic = 'swap.png'
            p.Subing = true
            break
          }
        } else {
          if (p.position === s.NewPosition) {
            p.ProfilePic = 'swap.png'
            p.Subing = true
            break
          }
        }
      }
    }
  }
  return arr
}

// call method to pull in game and bench players
onMounted(async () => {
  // reset the arrays
  await commitSubs()
  // getInGame()
  // getBench()
  await getGameData()
  // if (Array.isArray(store.subs)) {
  //   if (store.subs.length > 0) {
  //     commitSubs()
  //   }
  // }
  formations.value = await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/gm/getFormations/' + store.OrgID + '/all' ).then((res) => res.json())

  await getGameLogs()
})

onUnmounted(() => {
  clearInterval(GameLogs)
})

</script>

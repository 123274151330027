<template>
    <v-card v-if="Player.position != null" align="center" class="pa-0 ma-0" flat @click="choosePlayer(Player)">
        <v-card-text class="pa-0 ma-0">

              <v-row class="pa-0 ma-0">
                <v-col  class="pa-0 ma-0" align="center">
                  <v-avatar size="40" color="blue">
                    <v-img :src="profilePic"  :alt="Player.position"></v-img>
                  </v-avatar>
                  <v-avatar size="20" color="red" v-if="playerHealth">
                    <v-icon>mdi-hospital-box</v-icon>
                  </v-avatar>
                </v-col>
              </v-row>

              <v-row class="pa-0 ma-0" v-if="!formationBuilder">
                <v-col class="pa-0 ma-3" align="center" v-if="Player.name != undefined">
                  {{Player.name}}
                  <div v-if="incomingPlayer !== '' && !viewall"> {{  incomingPlayer }} is inbound</div>
                  <div v-if="Player.medical_conditions">  <v-icon color="red">mdi-hospital</v-icon>
                  <v-chip x-small outlined v-if="viewall">{{ Player.medical_conditions }}</v-chip></div><br />#{{Player.jersey}}
                </v-col>
              </v-row>

              <v-row class="pa-0 ma-0">
                <v-col class="pa-0 ma-0 ml-3 mr-3" align="center">
                  <h6>{{Player.position}}</h6>
                </v-col>
              </v-row>

              <v-row class="pa-0 ma-0" v-if="!formationBuilder">
                <v-col class="pa-0 ma-0" align="center">
                  <v-chip class="ma-1" :color="tig" x-small outlined v-if="viewall" variant="flat">Time in game {{PlayerTimeInGame || "0"}}</v-chip>
                  <v-chip class="ma-1" :color="tir" x-small outlined v-if="viewall" variant="flat">Time in rotation {{PlayerrotationTime || "0"}}</v-chip>
                  <v-chip class="ma-1" :color="tir" x-small outlined v-if="!viewall" variant="flat">{{PlayerrotationTime || "0"}}</v-chip>
                </v-col>
              </v-row>

        </v-card-text>
    </v-card>
</template>

<script lang="ts" setup>
import { onMounted, onUnmounted, defineProps, defineEmits, computed } from 'vue'
import { ref } from '@vue/reactivity'
import { useGameState } from '../store/index'
const store = useGameState()

const props = defineProps(['Player', 'viewall', 'formationBuilder'])
const emits = defineEmits(['cb'])
const PlayerrotationTime = ref(0)
const PlayerTimeInGame = ref(0)
const incomingPlayer = ref('')

let updateTime = {}

// let props: {
//   Player: {
//     id: number,
//     position: string,
//     name: string,
//     jersey: number,
//     Subbing: boolean,
//     timeInGame: string,
//     timeInRotation: string,
//     Healthy: boolean,
//     ProfilePic: string
//   },
//   viewall: boolean,
//   formationBuilder: boolean
// }
// const emits = {
//   cb: {}
// }

const choosePlayer = (p) => {
  emits('cb', p)
}

const profilePic = computed(() => {
  if (props.Player.Subbing) {
    return 'swap.png'
  } else {
    return props.Player.ProfilePic || 'soccerball.png'
  }
})

const tig = computed(() => {
  if (PlayerTimeInGame.value > 35) {
    return 'amber'
  } else if (PlayerTimeInGame.value > 45) {
    return 'red'
  }
  return 'green'
})

const tir = computed(() => {
  // to be changed later to leverage exertion factor, etc.
  if (props.Player.position !== 'bench') {
    if (PlayerrotationTime.value >= 5) {
      return 'amber'
    } else if (PlayerrotationTime.value >= 10) {
      return 'red'
    }
  } else {
    // bench player color changes with rest
    if (PlayerrotationTime.value >= 2) {
      return 'green'
    } else if (PlayerrotationTime.value >= 1) {
      return 'amber'
    }
  }
  return 'green'
})

const playerHealth = computed(() => {
  if ((props.Player.Healthy) || (props.Player.Healthy === undefined)) {
    return false
  }
  return true
})

const timeCalcs = () => {
  // timeInRotation is a timestamp from last change (sub in/out)
  // need to compare it to current timestamp / 1000 / 60 to get minutes in field
  const ts = store.lastTimeStamp
  const rotationTime = Math.round(((ts - props.Player.timeInRotation) / 1000) / 60)
  PlayerrotationTime.value = rotationTime

  // set time in game to be a running clock like time in rotation
  // props.Player.timeInGame
  if (props.Player.timeInRotation === '0') {
    PlayerTimeInGame.value = Math.round((ts / 1000) / 60)
  } else {
    PlayerTimeInGame.value = Number(props.Player.timeInGame) + rotationTime
  }
}

const getSubPlayer = (p) => {
  store.subs.forEach(element => {
    if (element.PositionY === p.id) {
      incomingPlayer.value = element.NewPlayer
    }
  })
}

onMounted(async () => {
  timeCalcs()
  getSubPlayer(props.Player)
})

updateTime = window.setInterval(
  function () {
    timeCalcs()
  }, 30000)

onUnmounted(() => {
  clearInterval(updateTime)
})
</script>

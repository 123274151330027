<template>
  <v-container class="pa-0 ma-0">

    <v-card v-if="!showEdit" class="pa-0 ma-0">
      <v-card-text class="pa-0 ma-0">
        <v-data-table
          :headers="teamHeaders"
          :items="teams"
          class="elevation-1 py-0 px-0"
          :items-per-page="15"
          mobile-breakpoint="0"
          :fixed-header=true
          dense
        >

        <template v-slot:top>
          <v-toolbar flat>
            <div class="d-flex w-100">
              <v-btn
                color="primary"
                @click="addTeam">
                <v-icon dark>mdi-plus</v-icon>Add Team
              </v-btn>
            </div>
          </v-toolbar>
        </template>

        <template v-slot:[`item.tname`]="{ item }">
          <v-text-field v-model="editedTeam.name" :hide-details="true" dense single-line :autofocus="true" v-if="item.id === editedTeam.id"></v-text-field>
          <span v-else>{{item.name}}</span>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon color="green" class="mr-3" @click="editTeam(item.raw.id, item.raw.name)">
            mdi-pencil
          </v-icon>
          <v-icon color="red" @click="deleteTeam(item.raw.id, item.raw.name)">
            mdi-delete
          </v-icon>
        </template>

      </v-data-table>
      </v-card-text>
    </v-card>

    <v-card v-if="showEdit" class="pa-0 ma-0">
        <v-card-title>
            {{ action }}
        </v-card-title>
        <v-card-text>

          <v-row>
            <v-col cols="3">
              <v-text-field v-model="editTeamName" label="Team Name" filled></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field v-model="headCoach" label="Head Coach" filled></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field v-model="assistantCoaches" label="Assistant Coach(es)" filled></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3">
              <v-text-field v-model="minage" label="Minimum Age" filled @change="getPlayerList()" type="number"></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field v-model="maxage" label="Maximum Age" filled @change="getPlayerList()" type="number"></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-select v-model="gender" label="Gender" :items="genders" @change="getPlayerList()" filled></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3">
              <v-select v-model="numPlayers" label="Max players on field" filled @change="getFormations()" :items="numPlayersList" ></v-select>
            </v-col>
            <v-col cols="3">
              <v-select v-model="normalFormation" label="Normal Formation" :items="formations" filled item-text="Name"></v-select>
            </v-col>
            <v-col cols="3">
              <v-text-field v-model="normalTimeOfGame" label="Game Length" filled type="number"></v-text-field>
            </v-col>
          </v-row>

        </v-card-text>

        <v-card-text v-if="selectPlayers">
          Choose Players for this team
          <v-row>
            <v-col>
              {{playerCount}} players
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-chip v-for="p in selectedPlayers" v-bind:key="p.name">{{p.name}}</v-chip>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <!-- <v-table>
                <thead>
                  <tr>
                    <th>
                      Name
                    </th>
                    <th>
                      Status
                    </th>
                    <th>
                      Guest Player?
                    </th>
                    <th>
                      Team
                    </th>
                    <th>
                      Age
                    </th>
                    <th>
                      Gender
                    </th>
                    <th>
                      Birthday
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="t in players" v-bind:key="t.id">
                    <td>
                      {{t.name}}
                    </td>
                    <td>
                        {{t.player_status}}
                    </td>
                    <td>
                      {{t.guest_player}}
                    </td>
                    <td>
                      {{t.Team}}
                    </td>
                    <td>
                      {{t.age}}
                    </td>
                    <td>
                      {{t.gender}}
                    </td>
                    <td>
                      {{t.birth_date}}
                    </td>
                  </tr>
                </tbody>
              </v-table> -->

              <v-data-table
                v-model="selectedPlayers"
                :headers="playerHeaders"
                :items="players"
                class="elevation-1 py-0 px-0"
                mobile-breakpoint="0"
                :items-per-page="15"
                :fixed-header=true
                dense
                show-select
                :single-select=false
                item-key="player_id"
                :sort-by="[{key: 'teamOrder', order: 'asc'}]"
              ></v-data-table>
            </v-col>
          </v-row>

        </v-card-text>

        <v-card-actions>
          <v-btn @click="cancelEdit()">Cancel</v-btn>
          <v-btn @click="save()">Save</v-btn>
        </v-card-actions>
    </v-card>

  </v-container>
</template>

<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue'
// import { useRoute, useRouter } from 'vue-router'
import { useGameState } from '../store/index'
const store = useGameState()
// const route = useRoute()
// const router = useRouter()

const teamHeaders = [
  { title: 'Team Name', key: 'name', align: 'start', sortable: true },
  { title: 'Head Coach', key: 'head_coach', align: 'start', sortable: true },
  { title: 'Assistant Coach(es)', key: 'assistant_coaches', align: 'start', sortable: true },
  { title: 'Min Age', key: 'min_age', align: 'start', sortable: true },
  { title: 'Max Age', key: 'max_age', align: 'start', sortable: true },
  { title: 'Gender', key: 'gender', align: 'start', sortable: true },
  { title: 'Roster Size', key: 'num_players', align: 'start', sortable: true },
  { title: 'Actions', key: 'actions', sortable: false, width: '100px' }
]

const teams = ref([{
  name: '',
  id: 0,
  head_coach: '',
  assistant_coaches: '',
  min_age: 0,
  max_age: 0,
  gender: '',
  max_players: 0,
  normal_time_of_game: 0,
  num_players: 0
}])
const editedTeam = {
  id: 0,
  name: ''
}
const showEdit = ref(false)
const editTeamName = ref('')
const action = ref('Add Team')
const assistantCoaches = ref('')
const headCoach = ref('')
const genders = [
  'M',
  'F',
  'CoEd'
]
const gender = ref('CoEd')
const minage = ref(4)
const maxage = ref(0)
const formations = ref([])
const normalFormation = ref('')
const numPlayers = ref(11)
const numPlayersList = [4, 7, 9, 11]
const normalTimeOfGame = ref(50)
const reallyEdit = ref(false)
const teamid = ref(0)
const selectPlayers = ref(false)
const playerHeaders = [
  { title: 'Full Name', key: 'name', align: 'start', sortable: true },
  { title: 'Team', key: 'Team', align: 'start', sortable: true },
  { title: 'Age', key: 'age', align: 'start', sortable: true },
  { title: 'Gender', key: 'gender', align: 'start', sortable: true },
  { title: 'Jersey #', key: 'jersey_number', align: 'start', sortable: true },
  { title: 'Active', key: 'active', align: 'start', sortable: true },
  { title: 'Guest', key: 'guest_player', align: 'start', sortable: true },
  { title: 'Actions', key: 'actions', sortable: false, width: '150px' }
]
const players = ref([{
  player_id: '',
  name: '',
  first_name: '',
  player_status: '',
  guest_player: 0,
  Team: '',
  age: 0,
  gender: '',
  birth_date: ''
}])
const selectedPlayers = ref([])
const playerCount = ref(0)

watch(selectedPlayers, () => {
  playerCount.value = selectedPlayers.value.length
})

const getFormations = async () => {
  // get updated current state
  formations.value = await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/gm/getBaseFormations/' + store.OrgID + '/' + numPlayers.value).then((res) => res.json())
}

const getPlayerList = async () => {
  // this wasn't very flexible
  // if (this.minage != 0) {
  //   this.maxage = (parseInt(this.minage) + 2)
  // }
  if ((maxage.value === 0) || (minage.value === 0) || gender.value === '') {
    return
  }
  if (normalFormation.value === '') {
    if (maxage.value < 8) {
      numPlayers.value = 4
    } else if (maxage.value < 10) {
      numPlayers.value = 7
    } else if (maxage.value < 12) {
      numPlayers.value = 9
    } else if (maxage.value >= 12) {
      numPlayers.value = 11
    }
  }

  players.value = await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/gm/getOrgPlayersByAgeAndSeason' + '/' + store.OrgID + '/' + gender.value + '/' + minage.value + '/' + maxage.value + '/' + encodeURIComponent(store.TeamSeason)).then((res) => res.json())
}

const updateTeamList = async () => {
  // get teams for this.org and update this.teams
  teams.value = await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/gm/getTeams' + '/' + store.OrgID + '/' + encodeURIComponent(store.TeamSeason)).then((res) => res.json())
}

const addTeam = () => {
  showEdit.value = true
  action.value = 'Add Team'
}

const editTeam = async (id, name) => {
  // const index = teams.value.indexOf(item)
  // console.log(item)
  // console.log(item.value)
  console.log(id)
  console.log(name)

  action.value = 'Edit ' + name // item.name
  editTeamName.value = name // item.name
  teamid.value = id // item.id

  const data = await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/gm/getTeamInfo' + '/' + store.OrgID + '/' + id).then((res) => res.json())

  headCoach.value = data.head_coach
  assistantCoaches.value = data.assistant_coaches
  minage.value = data.min_age
  maxage.value = data.max_age
  gender.value = data.gender
  normalFormation.value = data.normal_formation
  numPlayers.value = data.max_players
  normalTimeOfGame.value = data.normal_time_of_game
  console.log('formation: ' + normalFormation.value)
  getPlayerList()

  // add currently selected players for the team into the selectedPlayers array
  selectedPlayers.value = await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/gm/getTeamPlayers' + '/' + store.OrgID + '/' + id).then((res) => res.json())

  // selectedPlayers.value = [{ player_id: '1aa1349a-5637-11ed-a978-0242ac110002' }]

  selectPlayers.value = true
  showEdit.value = true
  reallyEdit.value = true
}

const deleteTeam = async (item) => {
  // const index = teams.value.indexOf(item)
  const del = confirm('Are you sure you want to delete the team ' + item.name + '?')

  if (del) {
    await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/gm/removeTeam/' + item.id, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'BEARER: ' + store.AuthToken } // ,
      // body: dataJson
    }).then((res) => res.json())

    // FIXME check for errors?
    showEdit.value = false
    selectPlayers.value = false
    reallyEdit.value = false
    updateTeamList()
  }
}

const cancelEdit = () => {
  showEdit.value = false
  selectPlayers.value = false
  reallyEdit.value = false
}

const save = async () => {
  // perform save
  const Gamedata = {
    SeasonID: store.TeamSeason,
    OrgID: store.OrgID,
    TeamName: editTeamName.value,
    TeamID: Number(teamid.value),
    HeadCoach: headCoach.value,
    AssistantCoaches: assistantCoaches.value,
    MinAge: Number(minage.value),
    MaxAge: Number(maxage.value),
    Gender: gender.value,
    Players: selectedPlayers.value,
    Edit: reallyEdit.value,
    NumPlayers: Number(numPlayers.value),
    NormalFormation: normalFormation.value,
    NormalTimeOfGame: Number(normalTimeOfGame.value)
  }
  const dataJson = JSON.stringify(Gamedata)

  console.log(dataJson)

  await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/gm/addTeam', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'BEARER: ' + store.AuthToken },
    body: dataJson
  }).then((res) => res.json())

  // FIXME check for errors?
  showEdit.value = false
  selectPlayers.value = false
  reallyEdit.value = false
}

onMounted(() => {
  updateTeamList()
  getFormations()
})

</script>

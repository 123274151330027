<template>

<div>
  <v-container fluid>
    <v-row>
      <v-col sm="12" md="12" xs="12">
        <v-card class="py-0 px-0">
          <v-card-title>Team Game Stats vs {{ Opponent }}</v-card-title>
          <v-card-text>
            Score: {{score}}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            Game Stats
          </v-card-title>
          <v-card-text>
            <v-data-table 
              :items="GameStats"
              :headers="GameStatsHeaders"
              class="elevation-0 py-0 px-0"
              :items-per-page="15"
            >
              <template v-slot:item.Playerid="{ item }">
                {{ item.Playerid }}
                <v-icon
                  class="me-2"
                  size="small"
                  @click="showPlayerStats(item)"
                >
                  mdi-magnify
                </v-icon>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col sm="12" md="12" xs="12">
        <v-card class="py-0 px-0">
          <v-row>
            <v-col>
              <v-card-title>Goalie Stats</v-card-title>
              <v-data-table
                :headers="goalieStatHeaders"
                :items="goalieStats"
                class="elevation-0 py-0 px-0"
                :items-per-page="15"
              ></v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="admin">
      <v-col sm="12" md="12" xs="12">
        <v-card class="py-0 px-0">
          <v-sheet color="background">
          <v-row>
            <v-col>
              <v-card>
              <v-card-title>Time in Positions</v-card-title>
              <v-data-table
                :headers="tipHeaders"
                :items="timeInPosition"
                class="elevation-0 py-0 px-0"
                :items-per-page="15"
              ></v-data-table>
            </v-card>
            </v-col>
          </v-row>
        </v-sheet>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="admin">
      <v-col sm="12" md="12" xs="12">
        <v-card class="py-0 px-0">
          <v-sheet color="background">
          <v-row>
            <v-col>
              <v-card>
              <v-card-title>+/- by position</v-card-title>
              <v-data-table
                :headers="plusMinusHeaders"
                :items="GameStats"
                class="elevation-0 py-0 px-0"
                :items-per-page="15"
              ></v-data-table>
            </v-card>
            </v-col>
          </v-row>
        </v-sheet>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="individualStats"
    >
      <PlayerStats :Player="individualPlayer" :singleGame="true"/>
    </v-dialog>

  </v-container>

</div>

</template>

<script lang="ts" setup>
import { ref } from '@vue/reactivity'
import { useRoute } from 'vue-router'
import { useGameState } from '../store/index'
import { onMounted } from 'vue-demi'
import { getPerms } from '../composables/userPerms'
import { getGameData } from '../composables/backend'
const store = useGameState()
const route = useRoute()
import PlayerStats from '@/components/PlayerStats.vue'

const GameStats = ref([])
const GameStatsHeaders = ref([
  { title: 'Name', value: 'Playerid', sortable: true},
  { title: 'Goals', value: 'Goals', sortable: true},
  { title: 'Assists', value: 'Assists', sortable: true},
  { title: 'Shots on Goal', value: 'Shotsongoal', sortable: true},
  { title: 'Shots Taken', value: 'ShotsTaken', sortable: true},
  { title: 'Time in Game', value: 'Timeingame', sortable: true},
  { title: 'Overall +/-', value: 'PlusMinus.Total', sortable: true}
])
const individualStats = ref(false)
const individualPlayer = ref({})

const goalieStats = ref([])

const plusMinusHeaders = ref([
  { title: 'Name', value: 'Playerid', sortable: true},
  { title: 'Total', key: 'PlusMinus.Total', sortable: true },
  // { title: 'Average', key: 'PlusMinus.Average', sortable: true },
  { title: 'Goalie', key: 'PlusMinus.P1', sortable: true },
  { title: 'Left Backer', key: 'PlusMinus.P2', sortable: true },
  { title: 'Right Backer', key: 'PlusMinus.P3', sortable: true },
  { title: 'Left Mid', key: 'PlusMinus.P4', sortable: true },
  { title: 'Center Mid', key: 'PlusMinus.P5', sortable: true },
  { title: 'Right Mid', key: 'PlusMinus.P6', sortable: true },
  { title: 'Striker', key: 'PlusMinus.P7', sortable: true },
  { title: '--', key: 'PlusMinus.P8', sortable: true },
  { title: '--', key: 'PlusMinus.P9', sortable: true },
  { title: '--', key: 'PlusMinus.P10', sortable: true },
  { title: '--', key: 'PlusMinus.P11', sortable: true },
])

const score = ref('0-0')
const timeInPosition = ref([])
const assists = ref([])
const goals = ref([])

const tipHeaders = [
  { title: 'Player', key: 'Playerid', align: 'start', sortable: true },
  { title: 'Striker', key: 'Time_p9', sortable: true },
  { title: 'Left Mid', key: 'Time_p8', sortable: true },
  { title: 'Center Mid', key: 'Time_p7', sortable: true },
  { title: 'Right Mid', key: 'Time_p6', sortable: true },
  { title: 'Stopper', key: 'Time_p5', sortable: true },
  { title: 'Left Backer', key: 'Time_p4', sortable: true },
  { title: 'Right Backer', key: 'Time_p3', sortable: true },
  { title: 'Sweeper', key: 'Time_p2', sortable: true },
  { title: 'Goalie', key: 'Time_p1', sortable: true },
  { title: 'In Game Time', key: 'Timeingame', align: 'start', sortable: true },
  { title: 'Total Bench Time', key: 'Timeonbench', align: 'start', sortable: true }
]
const Opponent = ref('')
const goalieStatHeaders = [
  { title: 'Player', key: 'Playerid', sortable: true },
  { title: 'Goals allowed', key: 'ScoredAgainst', ortable: true },
  { title: 'Goals stopped', key: 'Saves', sortable: true },
  { title: 'Time at Goalie', key: 'Time_p1', sortable: true },
]
const admin = ref(false)

const showPlayerStats = (player) => {
  console.log('Show player stats for ' + player)
  individualStats.value = true //!individualStats.value
  individualPlayer.value = player
}

const getTeamStats = async () => {
  const data = await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/gm/getGameStats/' + store.OrgID + '/' + store.TeamID + '/' + store.GameID).then((res) => res.json())

  // console.log(data)
  GameStats.value = data

  timeInPosition.value = data
  filterNoAssists(data)
  filterNoGoals(data)

  filterGoalieStats(data)
}

const filterGoalieStats = (d) => {
  const newdata = []
  for (let n = 0; n < d.length; n++) {
    if (d[n].Time_p1 > 0 && d[n].Playerid !== '') {
      newdata.push(d[n])
    }
  }
  goalieStats.value = newdata
}

const filterNoGoals = (d) => {
  const newdata = []
  for (let n = 0; n < d.length; n++) {
    if (d[n].Goals > 0 && d[n].Playerid !== '') {
      newdata.push(d[n])
    }
  }
  goals.value = newdata
}
const filterNoAssists = (d) => {
  const newdata = []
  for (let n = 0; n < d.length; n++) {
    if (d[n].Assists > 0 && d[n].Playerid !== '') {
      newdata.push(d[n])
    }
  }
  assists.value = newdata
}

const getGameInfo = async () => {
  const data = await getGameData(store)

  Opponent.value = data.opponent
  score.value = data.our_score + ' - ' + data.their_score
}

const checkPermissions = async () => {
  const perms = await getPerms(store)

  // console.log(perms)

  if (perms.RBAC === 'Viewer') {
    admin.value = false
  } else {
    admin.value = true
  }
}

onMounted(async () => {
  await checkPermissions()
  await getTeamStats()
  store.setActivePage(route.name)
  await getGameInfo()
})

</script>

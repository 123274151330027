<template>
  <v-app >
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant="miniVariant"
      :clipped="clipped"
      fixed
      app
    >
      <v-list>
        <v-list-item
          key="Home"
          to="/"
          router
          exact
        >
          <v-list-item-action>
            <v-icon>mdi-home</v-icon>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item-action>
        </v-list-item>
        <v-list-item>
          <v-select 
              v-model="Org"
              :items="store.Orgs"
              item-title="name"
              item-value="orgID"
              @update:model-value="setOrg"
              label="Current Org"
              rounded
            ></v-select>
        </v-list-item>
      </v-list>

      <v-list>
        <v-list-item>
          <v-btn 
            :href="tourl"
            variant="text"
            text="Tryouts"
          />
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list>
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          :to="item.to"
          router
          exact
          density="compact"
        >
          <v-list-item-action>
            <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
            <v-avatar v-if="item.avatar" icon="mdi-cog" :image="profilePic" size="32"></v-avatar>
            &nbsp; <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-action>

          <div v-if="item.title === 'My Teams'">
            <v-list-item
              v-for="(item, n) in teams"
              :key="item.Season"
              exact
              density="compact"
            >
              <v-list-subheader>
                Season {{ item.Season }}
              </v-list-subheader>
              <v-divider></v-divider>
              <v-list-item
                v-for="(item, i) in teams[n].Teams"
                :key="i"
                exact
              >
                <!-- <v-list-item-action> -->
                  <!-- <v-list-item  -->
                  <div @click="setTeam(item.name, item.id)">
                    {{ item.name }}
                  </div>
                  <!-- <v-icon>mdi-soccer</v-icon> &nbsp; <v-list-item-title @click="setTeam(item.name, item.id)">{{ item.name }}</v-list-item-title> -->
                <!-- </v-list-item-action> -->
              </v-list-item>
            </v-list-item>
          </div>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      :clipped-left="clipped"
      fixed
      app
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />

      <v-app-bar-nav-icon @click="goback()"><v-icon>mdi-arrow-left-bold</v-icon></v-app-bar-nav-icon>

      <v-toolbar-title>{{ title }}</v-toolbar-title>

      <v-menu>
        <template v-slot:activator="{ props }">
          <v-avatar v-bind="props" icon="mdi-cog" :image="profilePic"></v-avatar>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-title @click="mySettings">My Settings</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title @click="logout">Log Out</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title @click="about">About</v-list-item-title>
          </v-list-item>
          <!-- <v-list-item>
            <v-list-item-title @click="login">Login</v-list-item-title>
          </v-list-item> -->
        </v-list>
      </v-menu>

    </v-app-bar>

    <!-- <v-app-bar v-model="isDev">
      <v-alert
        type="success"
        title="Development Environment"
        text="This environment is for development testing only."
        density="compact"
        variant="tonal"
        class="pa-2 ma-0 mt-7 mb-2"
        ></v-alert>
    </v-app-bar> -->

    <v-main
    width="95%">
      <!-- <v-container> -->
        <router-view />

        <v-dialog
        v-model="termsAndConditionsShow"
        persistent
        scrollable>
          <termsAndConditions @cb="showTerms()" />
        </v-dialog>

      <!-- </v-container> -->
    </v-main>

    <v-footer
      app
      class="ma-0"
    >
      <span>&copy; {{ new Date().getFullYear() }} CMV Software LLC</span>
      <v-spacer></v-spacer>
      <span @click="showTerms()">Terms and Conditions</span>
      &nbsp; &nbsp; &nbsp; &nbsp;
    </v-footer>

  </v-app>
</template>

<script setup lang="ts">
import { reactive, ref } from '@vue/reactivity'
import { useRouter, RouterView } from 'vue-router'
import { useGameState } from './store/index'
import { onMounted } from 'vue'
import { useTheme } from 'vuetify' // 'vuetify/lib/framework.mjs'
import { getPerms } from './composables/userPerms'
import termsAndConditions from './components/TermsAndConditions.vue'
import { useRoute } from 'vue-router'

const store = useGameState()
const router = useRouter()
const route = useRoute()

const clipped = false
const drawer = ref(true)
const fixed = true
const showLogin = ref(false)
const profilePic = ref('')
const items = reactive([
  // {
  //   order: 1,
  //   icon: 'mdi-home',
  //   title: 'Home',
  //   to: '/'
  // },
  {
    order: 2,
    icon: 'mdi-soccer',
    title: 'My Teams',
    to: '/myTeam'
  },
  {
    order: 100,
    avatar: true,
    // icon: 'mdi-cog',
    title: 'My Settings',
    to: '/mySettings'
  }
])
const teams = ref([
  {
    Season: '',
    Teams: [{
      name: '',
      id: ''
    }]
  }
])
const miniVariant = false
const title = ref('Game Manager')
const termsAndConditionsShow = ref(false)
const Org = ref('')
// const isDev = ref(false)

const tourl = process.env.VUE_APP_TRYOUTS

const setOrg = (o) => {
  // console.log('setting org to '+o)
  store.setOrg(o)
  router.go(0)
}

const login = () => {
  // window.open('https://auth.teamrallypoint.com:3002/login?redirect_uri=https://gamemanager.teamrallypoint.com:8081/', '_self')
  window.open(process.env.VUE_APP_AUTH_REDIRECT + '/login?redirect_uri=' + process.env.VUE_APP_URL, '_self')
}

function delete_cookie(name) {
  document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=.teamrallypoint.com; Secure';
}

const logout = () => {
  store.saveUser(null)
  // localStorage.removeItem('gameState.auth')
  store.auth.loggedIn = false
  localStorage.removeItem('gameState.AuthToken')
  delete_cookie('Token')
  console.log('logging out')
  // console.log(document.cookie)
  router.push({ path: '/' })
}

const about = () => {
  router.push({ path: '/about' })
}

const goback = () => {
  router.back()
}

const mySettings = () => {
  router.push({ path: '/mySettings' })
}

const showTerms = () => {
  termsAndConditionsShow.value = !termsAndConditionsShow.value
}

const setTeam = async (t, tid) => {
  // console.log(t)
  // console.log(tid)

  store.setTeamID(tid)
  store.setTeamName(t)

  console.log(route)
  if (route.path === '/myTeam') {
    router.go(0)
  } else {
    router.push('/myTeam')
  }
}

const checkPermissions = async () => {
  const res = await getPerms(store)
  profilePic.value = store.auth.user.profile_pic
  if ((res.RBAC === 'OrgManager') || (res.RBAC === 'GlobalAdmin')) {
    // items.push(
    //   {
    //     order: 3,
    //     icon: 'mdi-account-group',
    //     title: 'My Org',
    //     to: '/myOrg'
    //   }
    // )
  }
  // sort items
  items.sort((a, b) => a.order - b.order)
}

onMounted(async () => {
  checkPermissions()

  if (!store.TAC && store.loggedIn) {
    showTerms()
  }

  if (!store.loggedIn) {
    showLogin.value = true
  }

  const theme = useTheme()
  if (theme.global.name.value !== store.theme) {
    theme.global.name.value = store.theme
  }

  // if (process.env.NODE_ENV === 'development') {
  //   isDev.value = true
  // }
  if (store.OrgID === '') {
    if (store.Orgs.length > 0) {
      Org.value = store.Orgs[0].OrgID
    } else {
      // Default Org
      Org.value = 'aaabbbcccdddeeefff'
    }
  } else {
    Org.value = store.OrgID
  }

  const teamList = await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/getTeamInfo' + '/' + store.OrgID + '/' + 'null').then((res) => res.json())

  if (typeof(teamList) == 'object') {
    let n = 0    
    teamList.forEach(season => {
      teams.value[n].Teams.length = 0 // null out the array
      season.Teams.forEach(team => {
        teams.value[n].Season = season.Season
        if (team.sport === 'Soccer') {
          teams.value[n].Teams.push(team)
        }
      })
      n++
    })
  }
})

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>

<template>
  <v-card></v-card>
</template>

<script setup lang="ts">
import { ref } from '@vue/reactivity'
import { useRouter } from 'vue-router'
import { getAuth, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from 'firebase/auth'
import { useGameState } from '../store/index'
const store = useGameState()
const router = useRouter()
// const route = useRoute()
// const firebase = {}
const email = ref('')
const password = ref('')
const error = ref('')
// const emailAuth = ref(false)
const showLogin = ref(false)

if (store.auth.loggedIn === true) {
  // check if breadcrumb is in store, else use .back()
  if (typeof store.activePage !== 'undefined') {
    router.push(store.activePage)
  } else {
    router.back()
  }
} else {
  // window.open('https://auth.teamrallypoint.com:3002/login?redirect_uri=https://gamemanager.teamrallypoint.com:8081/', '_self')
  // window.open('https://auth.teamrallypoint.com/login?redirect_uri=https://gamemanager.teamrallypoint.com/', '_self')
  window.open(process.env.VUE_APP_AUTH_REDIRECT + '/login?redirect_uri=' + process.env.VUE_APP_URL, '_self')
  // showLogin.value = true
}

const loginUser = () => {
  const auth = getAuth()
  signInWithEmailAndPassword(auth, email.value, password.value)
    .then((firebaseUser) => {
      const adduser = {
        email: firebaseUser.user.email,
        name: firebaseUser.user.displayName,
        uid: firebaseUser.user.uid
      }
      loadSettings(adduser)
    })
    .catch((error) => {
      error.value = error.message
    })
}

// const googleAuth = () => {
//   const provider = new GoogleAuthProvider()

//   const auth = getAuth()
//   signInWithPopup(auth, provider)
//     .then((firebaseUser) => {
//       // console.log(firebaseUser)
//       const adduser = {
//         email: firebaseUser.user.email,
//         name: firebaseUser.user.displayName,
//         uid: firebaseUser.user.uid,
//         photo: firebaseUser.user.photoURL
//       }
//       loadSettings(adduser)
//     }).catch(error => {
//       console.log(error)
//       error.value = error.message
//     })
// }

// const signUp = () => {
//   router.push('/signup')
// }

// const logout = () => {
//   // This should be run from default.vue now
//   store.commit('SET_USER', null)
//   localStorage.removeItem('gameState.auth')
//   router.go()
// }

const loadSettings = async (adduser) => {
  // console.log(adduser)

  // restore a user's preferences.
  const settings = await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/getSettings' + '/' + adduser.uid).then((res) => res.json())

  // need to tell backend about new user, attempt to match email with player
  // if settings are empty, do this ^^
  // console.log('settings:')
  // console.log(settings)

  if (settings.OrgID <= 0 || settings.name === '') {
    const setup = await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/userSetup' + '/' + adduser.uid + '/' + adduser.email).then((res) => res.json())

    if (setup.OrgID > 0) {
      // set org
      store.setOrg(setup.OrgID)
    }
    if (setup.TeamID > 0) {
      // set team
      store.setTeamID(setup.TeamID)
    }
  }

  // console.log('settings: ')
  // console.log(settings)

  if (!settings.TAC) {
    // force Terms and Conditions to show
    store.setTAC(false)
  }

  if (settings.state.OrgID > 0) {
    store.setOrg(settings.state.OrgID)
  }

  if (settings.state.TeamID > 0) {
    store.setTeamID(settings.state.TeamID)
  }

  if (settings.state.TeamName !== '') {
    store.setTeamName(settings.state.TeamName)
  }

  if (settings.state.TeamSeason !== '') {
    store.setSeason(settings.state.TeamSeason)
  }

  if (settings.state.TimeZone !== '') {
    store.setTimeZone(settings.state.TimeZone)
  }

  if (settings.state.Theme !== '') {
    store.changeTheme(settings.state.Theme)
  }

  store.setProfilePic(settings.profilePic)

  store.saveUser(adduser)

  // if (store.OrgID === '') {
  //   router.push('/mySettings')
  // } else {
  //   router.push('/myTeam')
  // }
}
</script>

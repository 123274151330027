<template>
  <v-card class="pa-4 ma-0">
    <v-sheet color="background">
      <v-card-title class="pa-0 ma-0" >
          Coach Notes
      </v-card-title>
      <v-card-text class="pa-0 ma-0" >
        <v-row>
          <v-col>
            <v-btn rounded="xl" @click="toggleAutomated()" color="blue">{{btnPrefix}} automated notes</v-btn>
          </v-col>
        </v-row>
        <!-- <v-row>
          <v-col>
            Author
          </v-col>
          <v-col>
            Game Time
          </v-col>
          <v-col>
            Formation Name
          </v-col>
        </v-row> -->
        <v-row v-for="l in logs" v-bind:key="l.GameTimestamp">

          <v-card class="pa-0 ma-4">
            <v-card-text>
            <v-col cols="12">
              <v-row>
                <v-col md="4" xs="4">
                  <v-label>Author</v-label> <br />
                  {{ l.Author }}
                </v-col>
                <v-col md="4" xs="4">
                  <v-label>Game Time</v-label> <br />
                  {{ l.GameTime }}
                </v-col>
                <v-col md="4" xs="4">
                  <v-label>Formation</v-label> <br />
                  {{ l.FormationName }}
                </v-col>
              </v-row   >

              <v-row>
                <v-col md="12" xs="12">
                  <v-label>Note: </v-label><br />
                  {{ l.Note }}
                </v-col>
              </v-row>
              <v-row>
                <v-divider></v-divider>
              </v-row>
              <v-row>
                <v-col align="center">
                  Formation at the time
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-row>
                    <v-col align="center">
                      <v-label v-if="l.P11 != ''">P11</v-label> {{ l.P11 }}
                      <v-label v-if="l.P10 != ''">P10</v-label> {{ l.P10 }}
                  <v-label v-if="l.P9 != ''">P9</v-label> {{ l.P9 }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col align="center">
                      <v-label v-if="l.P8 != ''">P8</v-label> {{ l.P8 }}
                      <v-label v-if="l.P7 != ''">P7</v-label> {{ l.P7 }}
                      <v-label v-if="l.P6 != ''">P6</v-label> {{ l.P6 }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col align="center">
                      <v-label v-if="l.P5 != ''">P5</v-label> {{ l.P5 }}
                      <v-label>P4</v-label> {{ l.P4 }}
                      <v-label>P3</v-label> {{ l.P3 }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col align="center">
                      <v-label>P2</v-label>{{  l.P2 }}
                    <v-label>P1</v-label>{{  l.P1 }}
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-card-text>
        </v-card>
      </v-row>
      <v-row>
        <v-spacer></v-spacer>
      </v-row>
        <v-btn rounded="xl" color="blue" @click="close()">Close</v-btn>
      </v-card-text>
    </v-sheet>
  </v-card>
</template>

<script lang="ts" setup>
import { ref, defineEmits, defineProps, onMounted } from 'vue'
// import { useGameState } from '../store/index'
// const store = useGameState()
const props = defineProps(['gameid'])
const emits = defineEmits(['cb'])
const logs = ref([])
const showAutomated = ref(true)
const btnPrefix = ref('Hide')
import { useGameState } from '@/store/index'
const store = useGameState()

const close = async () => {
  emits('cb', true)
}

const toggleAutomated = async () => {
  showAutomated.value = !showAutomated.value
  if (showAutomated.value) {
    btnPrefix.value = 'Hide'
  } else {
    btnPrefix.value = 'Show'
  }

  const raw = await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/gm/getCoachLog/' + props.gameid, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'BEARER: ' + store.AuthToken },
  }).then((res) => res.json())

  logs.value = []

  if (!showAutomated.value) {
    raw.forEach(element => {
      if (element.Author !== 'automated') {
        logs.value.push(element)
      }
    })
  } else {
    // show all
    logs.value = raw
  }
}

onMounted(async () => {
  logs.value = await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/gm/getCoachLog/' + props.gameid, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'BEARER: ' + store.AuthToken },
  }).then((res) => res.json())
})
</script>

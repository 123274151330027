<template class="pa-0 ma-0">
  <v-card class="ma-4 pa-4" rounded="xl" elevation="0">
    <v-card-title>
      Time in game
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col offset="4">Time in Game</v-col>
        <v-col>Time in current rotation</v-col>
      </v-row>

     <v-row v-for="c in matrix" v-bind:key="c.RowID">
      <v-col v-if="showCol(c)">
        <v-row v-for="p in c.Cols" v-bind:key="p.id">
          <v-row v-if="p.name != undefined">
            <v-col>
              {{ p.name }}
            </v-col>
            <v-col>
              {{ gameTime(p.timeInRotation, p.timeInGame) }}
            </v-col>
            <v-col>
              {{ rotationTime(p.timeInRotation) }}
            </v-col>
          </v-row>
        </v-row>
      </v-col>
     </v-row>
     <!-- <br/> -->
     <v-row v-for="c in bench" v-bind:key="c.RowID">
      <v-col v-if="showCol(c)">
        <v-row v-for="p in c.Cols" v-bind:key="p.id">
          <v-row v-if="p.name != undefined">
            <v-col>
              {{ p.name }}
            </v-col>
            <v-col>
              {{ gameTime(p.timeInRotation, p.timeInGame) }}
            </v-col>
            <v-col>
              {{ rotationTime(p.timeInRotation) }}
            </v-col>
          </v-row>
        </v-row>
      </v-col>
     </v-row>
    </v-card-text>
  </v-card>
</template>

<script lang="ts" setup>
import { ref } from '@vue/reactivity'
import { defineProps, onMounted } from 'vue'
import { useGameState } from '../store/index'
const store = useGameState()
const props = defineProps(['matrix', 'bench'])
const data = ref([])

const showCol = (p) => {
  let show = false
  p.Cols.forEach(col =>{
    // console.log(col.name)
    if (col.name != undefined) {
      show = true
    }
  })
  return show
}

const rotationTime = (tir) => {
  // timeInRotation is a timestamp from last change (sub in/out)
  // need to compare it to current timestamp / 1000 / 60 to get minutes in field
  const ts = store.lastTimeStamp
  const rotationTime = Math.round(((ts - tir) / 1000) / 60)
  return rotationTime
}

const gameTime = (tir, tig) => {
  // set time in game to be a running clock like time in rotation
  // props.Player.timeInGame
  const ts = store.lastTimeStamp
  if (tir === '0') {
    return Math.round((ts / 1000) / 60)
  } else {
    return Number(tig) + rotationTime(tir)
  }
}
</script>

<template>

  <v-sheet 
    color="sidebar" 
    class="pa-4 ma-4" 
    rounded="xl"
    width="95%"
  >
    <v-card class="pb-4 ma-0" color="background" elevation="0" rounded="xl" min-width="260">
      <v-card-title>
        Games in Progress
      </v-card-title>
      <v-card-text>
        <v-sheet color="sidebar" rounded="xl" class="pa-4 ma-0">
          <v-row>
            <v-col v-for="g in games.OrgCurrent" v-bind:key="g.T1" align="center" sm="12" md="auto" cols="auto">
              <v-card class="pa-4 ma-0" min-width="200" max-width="265" rounded="xl" color="background" elevation="4">
                <scoreboard :t1="g.T1" :t1score="g.T1Score" :t2="g.T2" :t2score="g.T2Score" :time="g.Time" :finished="false"/>
                <v-chip class="mt-4" @click="watchGame(g.GameID)">Follow Game</v-chip>
              </v-card>
            </v-col>
          </v-row>
        </v-sheet>
      </v-card-text>
    </v-card>

    <v-spacer>&nbsp;</v-spacer>

    <v-card class="pb-4 ma-0" color="background" elevation="0" rounded="xl" min-width="265">
      <v-card-title>
        Recently finished Games
      </v-card-title>
      <v-card-text>
        <v-sheet color="sidebar" rounded="xl" class="pa-4 ma-0">
          <v-row>
            <v-col v-for="g in games.OrgRecent" v-bind:key="g.T1" align="center" >
              <v-card class="pa-4 ma-0" min-width="200" max-width="265" rounded="xl" color="background" elevation="4">
                <scoreboard :t1="g.T1" :t1score="g.T1Score" :t2="g.T2" :t2score="g.T2Score" :time="g.Time" :finished="true"/>
                <v-chip class="mt-4" @click="watchGame(g.GameID)">Game Log</v-chip>
              </v-card>
            </v-col>
          </v-row>
        </v-sheet>
      </v-card-text>
    </v-card>

  </v-sheet>

</template>
<script lang="ts" setup>
import { ref } from '@vue/reactivity'
import scoreboard from '../components/scoreboardChips.vue'
import { useGameState } from '../store/index'
import { useRouter, useRoute } from 'vue-router'
import { onMounted } from 'vue'
import { logActivity } from '@/composables/telemetry'

const router = useRouter()
const route = useRoute()
const store = useGameState()

const games = ref({})

const watchGame = (gid) => {
  store.setGameID(gid)
  store.setViewOnly(true)
  store.setViewOnlyLog(false)
  router.push({ path: '/gamelog' })
}

const listTeamGames = async () => {
  // console.log(process.env.VUE_APP_API_BACKEND_URL)
  games.value = await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/gm/getHomepageGames/' + store.OrgID,
  {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'BEARER: ' + store.AuthToken }
  }
  ).then((res) => res.json())
}

onMounted(async () => {
  await listTeamGames()
  logActivity(store, route.name, 'indexPage.vue', '')
})

</script>

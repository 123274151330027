<template>
  <v-card v-if="show" align="center" :color="tileColor" class="pa-0 ma-0">
    <v-card-text class="pa-0 ma-0">
      <v-row class="pa-0 ma-0">
          <v-col  class="pa-0 ma-0">
              <v-avatar size="40" color="#8E34EE">
                  <v-img src="soccerball.png"></v-img>
              </v-avatar>
          </v-col>
      </v-row>

      <v-row  class="pa-0 ma-0">
          <v-col class="pa-0 ma-0">
              <v-chip outlined x-small>
                  {{Player.position}}
              </v-chip>
          </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script lang="ts" setup>
import { defineProps, computed } from 'vue'

const props = defineProps(['Player'])

const tileColor = computed(() => {
  if (props.Player.position !== 'pick') {
    return '#030F84'
  } else {
    return '#03840F'
  }
})

const show = computed(() => {
  if ((props.Player.hide === false) || (typeof props.Player.hide === 'undefined')) {
    return true
  }
  return false
})
</script>

<template>
  <v-sheet>
    <v-card class="pa-4 ma-0">
      <v-card-title>
        Terms and Conditions
      </v-card-title>
      <v-card-text>
        <p>
            Welcome to Team Rally Point.
        </p>
        <br />
        <p>Please read the following terms and conditions carefully before using this site. By accessing or using the site, you agree to be bound by these terms and conditions. If you do not agree with any of these terms and conditions, please do not use this site.
        </p>
        <br />
        <ol>
          <li>
            User Content
            <p>
              You may upload photos and videos to our website, subject to the terms and conditions set out herein.
            </p>
            <br />
            <p>
              You are solely responsible for all user-generated content you submit to our website. You warrant that you are the owner or have permission from the owner of any and all content submitted by you to our website.
            </p>
            <br />
            <p>
              You agree that we may use any user-generated content  for any purpose, including but not limited to, promotion, advertising, and marketing of our website.
            </p>
            <br />
            <p>
              You agree to indemnify and hold us harmless from and against any and all claims, damages, liabilities, costs, and expenses arising from or in connection with your user-generated content.
            </p>
          </li>
          <br />
          <li>
            Player Statistics
            <p>
              We provide a service that tracks player statistics. These statistics are generated by us based on the information that we collect from the games you play.
            </p>
            <br/>
            <p>
              We make no warranties or representations as to the accuracy or completeness of the player statistics provided by us.
            </p>
            <br />
            <p>
              You agree that we may use the player statistics generated by us for any purpose, including but not limited to, promotion, advertising, and marketing of our website.
            </p>
          </li>
          <br />
          <li>
            Privacy
            <p>
              We take your privacy seriously. Our Privacy Policy sets out the terms on which we process any personal data we collect from you, or that you provide to us. By using our website, you consent to such processing, and you warrant that all data provided by you is accurate.
            </p>
          </li>
          <br />
          <li>
            Intellectual Property
            <p>
              All intellectual property rights in our website and any content displayed on our website are owned by us or our licensors.
            </p>
            <br />
            <p>
              You may not copy, reproduce, distribute, or create derivative works based on our website or any content displayed on our website.
            </p>
          </li>
          <br />
          <li>
            User Conduct
            <p>
              You agree that you will not use our Site to:
              <br />
              Upload, share or distribute any User Content that is illegal, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, libelous, invasive of another's privacy, hateful, or racially, ethnically or otherwise objectionable;
Upload, share or distribute any User Content that infringes any patent, trademark, trade secret, copyright, or other intellectual property or proprietary right of any party;
Upload, share or distribute any User Content that contains software viruses or any other computer code, files or programs designed to interrupt, destroy, or limit the functionality of any computer software or hardware or telecommunications equipment;
Impersonate any person or entity or falsely state or otherwise misrepresent your affiliation with a person or entity;
Harass, stalk, or otherwise interfere with the use or enjoyment of our Site by any other user;
Engage in any activity that could damage, disable, overburden, or impair our Site or interfere with any other party's use and enjoyment of our Site.
            </p>
          </li>
          <br />
          <li>
            Third-Party Content
            <p>
              Our Site may contain links to third-party websites or resources. You acknowledge and agree that we are not responsible for the availability or accuracy of such websites or resources, and we do not endorse or warrant the content, products, or services on or available from such websites or resources
            </p>
          </li>
          <br />
          <li>
            Termination
            <p>
              We reserve the right to terminate your access to our website at any time without notice.
            </p>
            <br />
            <p>
              We may also terminate or suspend your access to our website if we believe that you have breached any of these terms and conditions.
            </p>
          </li>
          <br />
          <li>
            Limitation of Liability
            <p>
              To the fullest extent permitted by law, we shall not be liable for any loss or damage, whether direct, indirect, or consequential, arising from or in connection with your use of our website.
            </p>
            <br />
            <p>
              We shall not be liable for any loss or damage resulting from any interruption or delay in the operation of our website, or from any virus or other malicious software that may infect your computer or other device.
            </p>
          </li>
          <br />
          <li>
            Changes to Terms and Conditions
            <p>
              We reserve the right to modify or amend these terms and conditions at any time without notice. Any changes to these terms and conditions will be posted on our website.
            </p>
          </li>
          <br />
          <li>
            Contact Information
            <p>
              If you have any questions about these terms and conditions, please contact us at support@teamrallypoint.com.
            </p>
          </li>
        </ol>
        <br />
        <v-btn rounded="xl" @click="agree()" color="green">I agree</v-btn>
        &nbsp; &nbsp;
        <v-btn rounded="xl" @click="disagree()" color="red">I disagree</v-btn>
      </v-card-text>
    </v-card>
  </v-sheet>
</template>

<script lang="ts" setup>
import { defineEmits } from 'vue'
import { useGameState } from '../store/index'
const store = useGameState()
const emits = defineEmits(['cb'])

const agree = async () => {
  // log the response to the DB
  const permCheck = {
    UID: store.auth.user.uid
  }
  const data = JSON.stringify(permCheck)
  // console.log(data)
  await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/gm/acceptTAC',
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'BEARER: ' + store.AuthToken },
      body: data
    }).then((res) => res.json())
  store.setTAC(true)
  emits('cb', true)
}

const disagree = () => {
  // FIXME
  // need to log the response to the DB
  emits('cb', true)
}

</script>

export async function getGameData (store) {
  return await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/gm/getGameData/' + store.GameID).then((res) => res.json())
}

export async function toggleGameTime (store, state) {
  const gtd = {
    GameID: store.GameID,
    State: state,
    Statistician: store.auth.user.uid,
    TimeStamp: Number(store.lastTimeStamp)
  }
  const dataJson = JSON.stringify(gtd)
  return await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/gm/gameTimeState', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'BEARER: ' + store.AuthToken },
    body: dataJson
  }).then((res) => res.json())
}

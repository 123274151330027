<template>
  <v-row>
    <v-col cols="12">
      <v-row v-if="!props.finished">
        <v-col cols="12" class="pa-0 ma-0 pr-2" align="right">
          {{ $props.period }}
          <v-chip :color="pickColor()"><v-icon>mdi-clock</v-icon> {{$props.time}}</v-chip>
        </v-col>
      </v-row>
      <v-row v-if="props.finished">
        <v-col cols="12" class="pa-0 ma-0 pr-2" align="right">
          <!-- <v-chip><v-icon>mdi-clock</v-icon> Final</v-chip> -->
          <v-chip class="ma-2" variant="flat" v-if="t1score == t2score" color="orange">Tie</v-chip>
          <v-chip class="ma-2" variant="flat" v-if="t1score > t2score" color="green">Win</v-chip>
          <v-chip class="ma-2" variant="flat" v-if="t1score < t2score" color="red">Loss</v-chip>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="11" class="pa-0 ma-0" align="left">
          <strong v-if="teamHighlight(t1score, t2score)">{{ t1 }}</strong>
          <div v-if="!teamHighlight(t1score,t2score)">{{ t1 }}</div>
        </v-col>
        <v-col cols="1" class="pa-0 ma-0 pr-2" align="right">
          {{ t1score }}
        </v-col>
      </v-row>
      <v-row>
        <v-divider></v-divider>
      </v-row>
      <v-row>
        <v-col cols="11" class="pa-0 ma-0" align="left">
          <strong v-if="teamHighlight(t2score,t1score)">{{ t2 }}</strong>
          <div v-if="!teamHighlight(t2score,t1score)">{{ t2 }}</div>
        </v-col>
        <v-col cols="1" class="pa-0 ma-0 pr-2" align="right">
          {{ t2score }}
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script lang="ts" setup>
import { defineProps } from 'vue'
const props = defineProps(['t1', 't1score', 't2', 't2score', 'time', 'finished', 'period'])

const teamHighlight = (t1, t2) => {
  if (t1 > t2) {
    return true
  } else if (t1 === t2) {
    return true
  }
  return false
}

const pickColor = () => {
  if (props.time == undefined) {
    return 'gray'
  }

  const ts = props.time.split(':')
  if (ts[1] >= '40' || ts[0] > '00') {
    return 'red'
  } else if (ts[1] >= '30') {
    return 'orange'
  }
  return 'gray'
}
</script>

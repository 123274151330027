<template>
  <v-container class="pa-0 ma-0">

    <v-card v-if="!showEdit" class="pa-0 ma-0">
        <v-card-text class="pa-0 ma-0">
            <v-data-table
              :headers="seasonHeaders"
              :items="seasons"
              class="elevation-1 py-0 px-0"
              :items-per-page="15"
              mobile-breakpoint="0"
              :fixed-header=false
            >

                <template v-slot:top>
                  <v-toolbar flat>
                    <div class="d-flex w-100">
                      <v-btn
                        color="primary"
                        @click="addSeason">
                        <v-icon dark>mdi-plus</v-icon>Add Season
                      </v-btn>
                    </div>
                  </v-toolbar>
                </template>

                <template v-slot:[`item.sname`]="{ item }">
                  <v-text-field v-model="editedSeason.name" :hide-details="true" dense single-line :autofocus="true" v-if="item.id === editedSeason.id"></v-text-field>
                  <span v-else>{{item.name}}</span>
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon color="green" class="mr-3" @click="editSeason(item)">
                    mdi-pencil
                  </v-icon>
                  <v-icon color="red" @click="deleteSeason(item)">
                    mdi-delete
                  </v-icon>
                </template>

            </v-data-table>
        </v-card-text>
    </v-card>

    <v-card v-if="showEdit" class="pa-0 ma-0">
        <v-card-title>
            {{ action }}
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="editSeasonName" label="Season Name" filled></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="cancelEdit">Cancel</v-btn>
          <v-btn @click="save">Save</v-btn>
        </v-card-actions>
    </v-card>

  </v-container>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
// import { useRoute } from 'vue-router'
import { useGameState } from '../store/index'
import { onMounted } from 'vue-demi'
const store = useGameState()
// const route = useRoute()

const seasonHeaders = [
  { title: 'Season', key: 'Season', align: 'start', sortable: true, width: '100px' },
  { title: 'Actions', key: 'actions', value: 'actions', sortable: false, width: '50px' }
]
const seasons = ref([])
const editedSeason = {
  id: 0,
  name: ''
}
const showEdit = ref(false)
const editCurrent = ref(false)
const seasonID = ref(0)
const editSeasonName = ref('')
const action = ref('Add Season')

const updateSeasonList = async () => {
  // get seasons for this.org and update this.seasons
  seasons.value = await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/gm/getSeasons/' + store.OrgID).then((res) => res.json())
}

const addSeason = () => {
  showEdit.value = true
  action.value = 'Add Season'
}

const editSeason = (item) => {
  action.value = 'Edit ' + item.raw.Season
  editSeasonName.value = item.raw.Season
  seasonID.value = item.raw.ID
  showEdit.value = true
  editCurrent.value = true
}

const deleteSeason = (item) => {
  const del = confirm('Are you sure you want to delete this item?')
  if (del) {
    alert('delete ' + item.raw.Season)
  }
}

const cancelEdit = () => {
  showEdit.value = false
  editCurrent.value = false
}

const save = async () => {
  // perform save
  const Gamedata = {
    NewSeason: editSeasonName.value,
    OrgID: store.OrgID,
    SeasonID: Number(seasonID.value),
    Edit: editCurrent.value
  }
  const dataJson = JSON.stringify(Gamedata)

  await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/gm/createSeason', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'BEARER: ' + store.AuthToken },
    body: dataJson
  })

  showEdit.value = false
  editCurrent.value = false
}

onMounted(() => {
  updateSeasonList()
})
</script>

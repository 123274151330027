<template>
  <v-container class="pa-0 ma-0">

    <v-card v-if="!showEdit" class="pa-0 ma-0">
      <v-card-title class="pa-0 ma-0">
        Org Users
      </v-card-title>
      <v-card-text class="pa-0 ma-0">
        <v-data-table
          :headers="usersHeaders"
          :items="users"
          class="elevation-1 py-0 px-0"
          mobile-breakpoint="0"
          :items-per-page="15"
          :fixed-header=true
          dense
          :search="search"
          :custom-filter="searchUser"
        >

        <template v-slot:top>
          <v-toolbar flat>
            <div class="d-flex w-100">
              <v-text-field
                v-model="search"
                label="User Search"
                class="mx-4"
              ></v-text-field>
            </div>
          </v-toolbar>
        </template>

        <template v-slot:[`item.team_admins`]="{item}">
          <div v-for="(team, id) in item.team_admins" v-bind:key="id">
            <div v-if="team.Value">
              {{team.TeamName}}
            </div>
          </div>
        </template>

        <template v-slot:[`item.username`]="{ item }">
          <v-text-field v-model="editedUser.name" :hide-details="true" dense single-line :autofocus="true" v-if="item.id === editedUser.id"></v-text-field>
          <span v-else>{{item.name}}</span>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon color="green" class="mr-3" @click="editUser(item)">
            mdi-pencil
          </v-icon>
        </template>

      </v-data-table>
      </v-card-text>
    </v-card>

    <v-card v-if="showEdit" class="pa-0 ma-0">
      <v-card-title>
        {{ action }}
      </v-card-title>
      <v-card-text>
        User Permissions

        <v-row>
          <v-col cols="3" v-if="RBAC == 'GlobalAdmin'">
            <v-checkbox v-model="userAdmin" label="Site Admin" @click="adminWarning('site')"></v-checkbox>
          </v-col>
          <v-col cols="3">
            <v-checkbox v-model="userOrgAdmin" label="Org Admin" @click="adminWarning('org')"></v-checkbox>
          </v-col>
          <v-col cols="3">
            <v-label>Team Admin</v-label>
            <!-- <v-checkbox v-for="t in user_admin_teams" v-bind:key="t.TeamID" :label="t.TeamName" v-bind:id="t.TeamID" ></v-checkbox> -->
            <v-checkbox v-for="t in userAdminTeams" v-bind:key="t.TeamID" :label="t.TeamName" v-bind:id="t.TeamID" @change="toggleSelected(t)" :input-value="t.Value"></v-checkbox>

          </v-col>

        </v-row>

      </v-card-text>
      <v-card-actions>
        <v-btn @click="cancelEdit">Cancel</v-btn>
        <v-btn @click="save">Save</v-btn>
      </v-card-actions>
    </v-card>

</v-container>
</template>

<script lang="ts" setup>
import { ref } from '@vue/reactivity'
// import { useRoute } from 'vue-router'
import { useGameState } from '../store/index'
import { onMounted } from 'vue-demi'
import { getPerms } from '../composables/userPerms'
const store = useGameState()
// const route = useRoute()

const usersHeaders = [
  { title: 'Full Name', key: 'name', align: 'start', sortable: true },
  { title: 'Email', key: 'email', align: 'start', sortable: true },
  { title: 'Role', key: 'RBAC', align: 'start', sortable: true },
  { title: 'Site Admin', key: 'admin_perms', align: 'start', sortable: true },
  { title: 'Org Admin', key: 'org_admin', align: 'start', sortable: true },
  { title: 'Admin on Team(s)', key: 'team_admins', align: 'start', sortable: true },
  { title: 'Signup Date', key: 'signup_date', align: 'start', sortable: true },
  { title: 'Last Activity', key: 'last_active_date', align: 'start', sortable: true },
  { title: 'Account Active', key: 'active', align: 'start', sortable: true },
  { title: 'Actions', key: 'actions', sortable: false, width: '100px' }
]
const users = ref([])
const editedUser = {
  id: 0,
  name: ''
}
const search = ref('')
const action = ref('Add User')
const editUserName = ref('')
const showEdit = ref(false)
const userAdmin = ref(false)
const userOrgAdmin = ref(false)
const userUID = ref('')
const userAdminTeams = ref([])
const userActive = ref(true)
const teamID = ref(0)
const RBAC = ref('viewer')

const getUserList = async () => {
  users.value = await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/gm/getOrgUsers' + '/' + store.OrgID + '/' + store.TeamSeason).then((res) => res.json())
}

const toggleSelected = (item) => {
  // toggle the value
  item.Value = !item.Value
  // re-build the array, with the updated entries, update this.user_admin_teams
  const newitem = Object.entries(item.raw)
  const update = Object.fromEntries(newitem.map(o => [o.TeamID, o]))
  userAdminTeams.value.map(o => update[o.TeamID] || o)
}

// const createSeason = () => {
//   alert('not implemented')
// }

const searchUser = (value, search) => {
  return value != null &&
    search != null &&
    typeof value === 'string' &&
    value.toString().toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) !== -1
}

// const addUser = () => {
//   showEdit.value = true
// }

const editUser = async (item) => {
  // set edit flag
  console.log(item.raw)
  action.value = 'Edit ' + item.raw.name
  editUserName.value = item.raw.name
  userAdmin.value = item.raw.admin_perms
  userOrgAdmin.value = item.raw.org_admin
  userUID.value = item.raw.uid
  // this.user_teams = item.teams
  userAdminTeams.value = item.raw.team_admins
  userActive.value = item.raw.active
  teamID.value = item.raw.TeamID
  RBAC.value = item.raw.RBAC

  showEdit.value = true

  console.log('userAdmin ' + userAdmin.value)
}

const cancelEdit = () => {
  showEdit.value = false
}

const save = async () => {
  // perform save
  const UserData = {
    UID: userUID.value,
    Admin_Perms: userAdmin.value,
    Org_Admin: userOrgAdmin.value,
    Chosen_Org_ID: Number(store.OrgID),
    Chosen_Team_ID: Number(teamID),
    OrgID: Number(store.OrgID),
    team_admins: userAdminTeams.value
    // State: store
  }
  const dataJson = JSON.stringify(UserData)
  // console.log(dataJson)

  await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/gm/saveSettings', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'BEARER: ' + store.AuthToken },
    body: dataJson
  }).then((res) => res.json())

  showEdit.value = false
  action.value = 'Add User'
}

const adminWarning = (type) => {
  if (type === 'site') {
    return confirm('Are you sure you want to give this user global admin access to the entire site?')
  }
  if (type === 'org') {
    return confirm('Granting this access allows the user to make player and team modifications.  Are you sure?')
  }
}

const checkPermissions = async () => {
  RBAC.value = await getPerms(store).RBAC
  // if ((res.RBAC !== 'OrgManager') && (res.RBAC !== 'GlobalAdmin')) {
  //   alert('Sorry, you don\'t have permission to be here..')
  //   router.push({ path: '/' })
  // }
}

onMounted(() => {
  getUserList()
  checkPermissions()
})

</script>

import { defineStore } from 'pinia'

export const useGameState = defineStore('gameState', {
  state: () => {
    return {
      AuthToken: '',
      OrgID: '',
      Orgs: [{
        name: '',
        orgID: '',
      }],
      TeamID: '',
      TeamName: '',
      GameID: '',
      TeamSeason: '',
      lastTimeStamp: 0,
      timePaused: false,
      gameInProgress: false,
      halfTime: '',
      auth: {
        user: {
          uid: '',
          profile_pic: '',
        },
        loggedIn: false,
        strategy: ''
      },
      ViewOnly: false,
      ViewOnlyLog: false,
      GameTime: '00:00:00',
      GamePeriod: '',
      massEditGame: false, // might not be needed
      activePage: '/',
      subs: [],
      theme: 'light',
      scoreUs: 0,
      scoreThem: 0,
      Assists: 0,
      ourShots: 0,
      ourSOG: 0,
      theirShots: 0,
      theirSOG: 0,
      Opponent: '',
      InGame: [],
      OnBench: [],
      profilePic: '',
      timeZone: 'America/Chicago',
      TAC: false,
      teamFormation: '',
      teamFormationSize: 7
    }
  },
  getters: {
    getOrgID: ({ OrgID }) => OrgID,
    getTeamID: ({ TeamID }) => TeamID,
    loggedIn (): boolean {
      return this.auth.loggedIn
    },
    getGameTime: ({ GameTime }) => GameTime
  },
  actions: {
    resetGameStats () {
      this.GameID = ''
      this.timePaused = false
      this.gameInProgress = false
      this.halfTime = ''
      this.GameTime = ''
      this.GamePeriod = ''
      this.scoreUs = 0
      this.scoreThem = 0
      this.Assists = 0
      this.ourShots = 0
      this.ourSOG = 0
      this.theirShots = 0
      this.theirSOG = 0
      this.Opponent = ''
      this.InGame = []
      this.OnBench = []
      this.teamFormation = ''
      this.lastTimeStamp = 0
    },
    // add changers to state in here:
    setOrg (payload) {
      this.OrgID = payload
    },
    setSeason (payload) {
      this.TeamSeason = payload
    },
    setTeamName (payload) {
      this.TeamName = payload
    },
    setTeamID (payload) {
      this.TeamID = payload
    },
    setActivePage (payload) {
      this.activePage = payload
    },
    setGameID (payload) {
      this.GameID = payload
    },
    setViewOnly (payload) {
      this.ViewOnly = payload
    },
    setViewOnlyLog (payload) {
      this.ViewOnlyLog = payload
    },
    setMassEdit (payload) {
      this.massEditGame = payload
    },
    setTAC (payload) {
      this.TAC = payload
    },
    setGamePeriod (payload) {
      this.GamePeriod = payload
      // add to the game log
      const statData = {
        current_game_time: this.GameTime,
        last_timestamp: this.lastTimeStamp,
        gameID: this.GameID,
        teamID: this.TeamID,
        seasonName: this.TeamSeason,
        orgID: this.OrgID,
        // player: ,
        data: payload,
        our_score: this.scoreUs,
        their_score: this.scoreThem
      }
      const stats = JSON.stringify(statData)

      if ((payload !== 'Not Started' && this.gameInProgress) || payload === 'Final') {
        fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/gm/saveStats', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'Authorization': 'BEARER: ' + this.AuthToken },
          body: stats
        })
      }
      if (payload === 'Halftime' || payload === 'Final') {
        const body = {
          last_timestamp: this.lastTimeStamp,
          gameID: this.GameID,
          teamID: this.TeamID,
          seasonName: this.TeamSeason,
          orgID: this.OrgID
        }
        const bdyJson = JSON.stringify(body)
        // console.log(bdyJson)
        // signal to backend to re-calc in-game, in position times
        fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/gm/triggerTimeCalc', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'Authorization': 'BEARER: ' + this.AuthToken },
          body: bdyJson
        })
      }
    },
    updateLastTimestamp (payload) {
      this.lastTimeStamp = payload
    },
    updateGameTime (payload) {
      this.GameTime = payload
    },
    changeGameInProgress (payload) {
      this.gameInProgress = payload
    },
    setGamePaused (payload) {
      this.timePaused = payload
    },
    setHalfTime (payload) {
      this.halfTime = payload
    },
    subPush (payload) {
      if (payload.length === 0) {
        this.subs.length = 0
      } else {
        this.subs = payload
      }
    },
    saveUser (payload) {
      let li = true
      if (payload === null) {
        li = false
      }
      this.auth = { user: payload, loggedIn: li, strategy: '' }

      // const bdyJson = JSON.stringify(payload)
      // console.log(bdyJson)

      // fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/saveSettings', {
      //   method: 'POST',
      //   headers: { 'Content-Type': 'application/json', 'Authorization': 'BEARER: ' + store.AuthToken },
      //   body: bdyJson
      // })
    },
    changeTheme (payload) {
      this.theme = payload
    },
    setScoreUs (payload) {
      this.scoreUs = payload
    },
    setScoreThem (payload) {
      this.scoreThem = payload
    },
    setOpponent (payload) {
      this.Opponent = payload
    },
    setOurShots (payload) {
      this.ourShots = payload
    },
    setTheirShots (payload) {
      this.theirShots = payload
    },
    setAssists (payload) {
      this.Assists = payload
    },
    setInGame (payload) {
      this.InGame = payload
    },
    setOnBench (payload) {
      this.OnBench = payload
    },
    setOurSOG () {
      this.ourSOG = this.ourSOG + 1
    },
    setTheirSOG () {
      this.theirSOG = this.theirSOG + 1
    },
    setProfilePic (data) {
      this.profilePic = data
    },
    setTimeZone (data) {
      this.timeZone = data
    },
    setTeamFormation (data) {
      this.teamFormation = data
    },
    setOrgs (data) {
      this.Orgs = data
    }
  },
  persist: true
})


// export default createStore({
//   state: {
//   },
//   getters: {
//   },
//   mutations: {
//   },
//   actions: {
//   },
//   modules: {
//   }
// })

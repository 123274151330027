<template>
  <v-sheet class="pa-0 mt-4" color="background">
    <v-card class="pa-0 pb-4 ma-0" rounded="xl">
      <v-card-title class="pa-0 ma-0">
        Game Media
      </v-card-title>
      <v-card-text class="pa-0 ma-0">
        Share your in-game pictures and videos here!
      </v-card-text>
      <!-- <v-card-actions class="pa-0 ma-0"> -->
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="5">
          <v-btn rounded="xl" @click="toggleMediaUpload()" color="green">Upload Media</v-btn>
        </v-col>
        <v-col cols="5">
          <v-btn rounded="xl" disabled @click="toggleLiveStream()" color="yellow">Live Stream</v-btn>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
      <!-- </v-card-actions> -->
    </v-card>

    <v-card v-if="uploadMedia" class="pa-0 pt-4 pb-4 mt-4 ma-0" rounded="xl">
      <v-card-text class="pa-0 ma-0" rounded="xl">
        <v-row>
          <v-col>
            <v-file-input
              :loading="imageProcessing"
              counter
              multiple
              show-size
              accept="image/*"
              label="Photo Upload"
              @change="uploadMediaFiles"
            ></v-file-input>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-divider></v-divider>
          </v-col>
        </v-row>

        <v-row>
          <v-col v-for="img in previewImage" v-bind:key="img" class="pa-0 ma-0">
            <v-img :src="img" max-height="300" max-width="300"></v-img>
          </v-col>
        </v-row>

        <v-row>
          <v-spacer></v-spacer>
          <v-col>
            <v-btn rounded="xl" @click="saveUploadedMedia()" color="green">Upload</v-btn>
          </v-col>
          <v-col>
            <v-btn rounded="xl" @click="resetMedia()" color="red">Reset</v-btn>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card v-if="liveStream" class="pa-0 ma-0">
      <video autoplay controls :src="videoData"></video>
      <canvas ref="previewEl"></canvas>
    </v-card>

    <v-card class="pa-0 pb-4 mt-4" rounded="xl">
      <v-sheet color="background">
      <v-card-title class="pa-0 mb-4">
        Uploaded Media
      </v-card-title>
      <v-card-text class="pa-0 ma-0">
        <v-row>
          <v-col md="2" xs="12" v-for="image in props.mediaFiles" v-bind:key="image.URL" class="pa-0 ma-0">
            <v-card class="pa-0 pb-4 ma-0">
              <v-card-subtitle>By {{ image.UserName }}</v-card-subtitle>
              <v-img
                width="350"
                aspect-ratio="1"
                :src="image.URL"
                @click="showMedia(image)"
                v-on:error="image.URL='/missing.png'">
              </v-img>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-sheet>
    </v-card>

    <v-dialog
      v-model="mediaShow"
    >
      <v-card class="pa-0 pt-4 pb-4 ma-0" rounded="xl">
        <v-card-text>
          <v-img
                aspect-ratio="1"
                :src="showImage.URL"
                cover
                v-on:error="showImage.URL='/missing.png'">
              </v-img>
        </v-card-text>
        <!-- <v-card-actions> -->
        <v-row>
          <v-spacer></v-spacer>
          <v-col>
            <v-btn @click="mediaShow=false" color="blue">Close</v-btn>
          </v-col>
          <v-col>
            <v-btn v-if="store.auth.user.uid === showImage.UserID" @click="deleteImage()" color="red">Delete</v-btn>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <!-- </v-card-actions> -->
      </v-card>
    </v-dialog>

  </v-sheet>
</template>

<script setup lang="ts">
import { ref } from '@vue/reactivity'
import { useGameState } from '../store/index'
import { defineProps } from 'vue'
const store = useGameState()

const props = defineProps(['mediaFiles'])
// This script will upload media to firebase files

// also create a way to live stream to our rtmp server ?
// Amazon IVS

const videoData = ref('')
const liveStream = ref(false)
const uploadMedia = ref(false)
const previewImage = ref([])
// const imageData = []
const imageProcessing = ref()
const imageUpload = ref('')

const toggleMediaUpload = () => {
  uploadMedia.value = !uploadMedia.value
}

const toggleLiveStream = () => {
  liveStream.value = !liveStream.value
  if (liveStream.value) {
    startCamera()
  }
}
const mediaUpload = []
const mediaShow = ref(false)
const showImage = ref({})

const saveUploadedMedia = async () => {
  imageProcessing.value = true
  // This needs to push the files to a bucket
  await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/gm/saveGameMedia/' + store.GameID, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'BEARER: ' + store.AuthToken },
    body: JSON.stringify(mediaUpload)
  })
  resetMedia()
}

const resetMedia = () => {
  // set media to be empty
  previewImage.value = []
  mediaUpload.values = []
  // imageData.values = []
  imageUpload.value = ''
  imageProcessing.value = false
}

const uploadMediaFiles = (files) => {
  const uploads = files.target.files
  // console.log(uploads)
  for (let i = 0; i < uploads.length; i++) {
    const fname = uploads[i].name
    const fdata = new FileReader()
    fdata.readAsDataURL(uploads[i])
    fdata.addEventListener('load', () => {
      const imageUrl = fdata.result
      // const imageFile = uploads[i] // this is an image file that can be sent to server...
      // console.log('file: ')
      // console.log(imageUrl)
      previewImage.value.push(imageUrl)
      const m = {
        Filename: fname,
        FileData: imageUrl,
        GameID: store.GameID,
        UserID: store.auth.user.uid
      }
      mediaUpload.push(m)

      // imageData.push(imageFile)
    })
    console.log(fname)
  }
}

const showMedia = (img) => {
  showImage.value = img
  mediaShow.value = true
}

const closeMedia = () => {
  mediaShow.value = false
  showImage.value = {}
}

const deleteImage = async () => {
  console.log('would delete image ')
  console.log(showImage.value.ID)

  await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/gm/removeGameMedia/' + store.GameID + '/' + showImage.value.ID, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'BEARER: ' + store.AuthToken },
    body: JSON.stringify(mediaUpload)
  })

  closeMedia()
}

const startCamera = async () => {
  let permissions = {
    audio: false,
    video: false
  }
  try {
    const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true })
    for (const track of stream.getTracks()) {
      track.stop()
    }
    permissions = { video: true, audio: true }
  } catch (error) {
    permissions = { video: false, audio: false }
    console.log(error)
  }
  // If we still don't have permissions after requesting them display the error message
  if (!permissions.video) {
    console.error('Failed to get video permissions.')
  } else if (!permissions.audio) {
    console.error('Failed to get audio permissions.')
  }

  // navigator.permissions.query({
  //   name: 'camera'
  // })
  //   .then(function (result) {
  //     if (result.state === 'granted') {
  //       alert('granted')
  //     // } else if (result.state === 'prompt') {
  //     } else if (result.state === 'denied') {
  //       alert('denied')
  //     }
  //     result.onchange = function () {
  //       console.log('changed')
  //     }
  //   })

  await navigator.mediaDevices.getUserMedia(permissions)
    .then(stream => {
      // where #preview is an existing <canvas> DOM element on your page
      let previewEl = document.getElementById('preview')
      previewEl = stream
    })
    .catch(error => {
      console.log('error loading camera. ' + error)
    })
}

const stopCamera = () => {
  // const tracks =
}
</script>

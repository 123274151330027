<template>
  <v-container class="pa-0 ma-0">

    <v-card v-if="!showEdit" class="pa-0 ma-0">
      <v-card-text class="pa-0 ma-0">
        <v-data-table
          :headers="coachHeaders"
          :items="coaches"
          class="elevation-1 py-0 px-0"
          :items-per-page="15"
          mobile-breakpoint="0"
          :fixed-header=true
          dense
        >

        <template v-slot:top>
          <v-toolbar flat>
            <div class="d-flex w-100">
              <v-btn
                color="primary"
                @click="addCoach">
                <v-icon dark>mdi-plus</v-icon>Add Coach
              </v-btn>
            </div>
          </v-toolbar>
        </template>

        <template v-slot:[`item.tname`]="{ item }">
          <v-text-field v-model="editedCoach.name" :hide-details="true" dense single-line :autofocus="true" v-if="item.id === editedCoach.id"></v-text-field>
          <span v-else>{{item.name}}</span>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon color="green" class="mr-3" @click="editCoach(item.raw.id, item.raw.name)">
            mdi-pencil
          </v-icon>
          <v-icon color="red" @click="deleteCoach(item.raw.id, item.raw.name)">
            mdi-delete
          </v-icon>
        </template>

      </v-data-table>
      </v-card-text>
    </v-card>

    <v-card v-if="showEdit" class="pa-0 ma-0">
        <v-card-title>
            {{ action }}
        </v-card-title>
        <v-card-text>

          <v-row>
            <v-col cols="3">
              <v-text-field v-model="editTeamName" label="Team Name" filled></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field v-model="headCoach" label="Head Coach" filled></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field v-model="assistantCoaches" label="Assistant Coach(es)" filled></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3">
              <v-text-field v-model="minage" label="Minimum Age" filled @change="getPlayerList()" type="number"></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field v-model="maxage" label="Maximum Age" filled @change="getPlayerList()" type="number"></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-select v-model="gender" label="Gender" :items="genders" @change="getPlayerList()" filled></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3">
              <v-select v-model="numPlayers" label="Max players on field" filled @change="getFormations()" :items="numPlayersList" ></v-select>
            </v-col>
            <v-col cols="3">
              <v-select v-model="normalFormation" label="Normal Formation" :items="formations" filled item-text="Name"></v-select>
            </v-col>
            <v-col cols="3">
              <v-text-field v-model="normalTimeOfGame" label="Game Length" filled type="number"></v-text-field>
            </v-col>
          </v-row>

        </v-card-text>

        <v-card-actions>
          <v-btn @click="cancelEdit()">Cancel</v-btn>
          <v-btn @click="save()">Save</v-btn>
        </v-card-actions>
    </v-card>

  </v-container>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue'
// import { useRoute, useRouter } from 'vue-router'
// import { useGameState } from '../store/index'
// const store = useGameState()
// const route = useRoute()
// const router = useRouter()

const coachHeaders = [
  { title: 'Name', key: 'Name', align: 'start', sortable: true },
  { title: 'Coaching Level', key: 'Level', align: 'start', sortable: true },
  { title: 'State', key: 'State', align: 'start', sortable: true },
  { title: 'Actions', key: 'actions', sortable: false, width: '100px' }
]

const editedCoach = {
  id: 0,
  name: ''
}
const showEdit = ref(false)
const action = ref('Add Coach')
const headCoach = ref('')
const reallyEdit = ref(false)
const coaches = ref([])

const getCoachList = async () => {
  // coaches.value = await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/getOrgCoaches' + '/' + store.OrgID + '/' + encodeURIComponent(store.TeamSeason)).then((res) => res.json())

  // const c = {
  //   Name: 'Joe Test',
  //   Level: '9v9'
  // }
  // const clist = []
  // clist.push(c)
  // const c2 = {
  //   Name: 'Sam Taylor',
  //   Level: '9v9'
  // }
  // clist.push(c2)

  // coaches.value = JSON.stringify(clist)
  coaches.value = [
    { Name: 'Joe Test', Level: '9v9', State: 'InActive' },
    { Name: 'Sam Taylor', Level: '9v9', State: 'Active' }
  ]
  console.log(coaches.value)
}

const addCoach = () => {
  showEdit.value = true
  action.value = 'Add Coach'
}

const editCoach = async (id, name) => {
  // const index = teams.value.indexOf(item)
  // console.log(item)
  // console.log(item.value)
  console.log(id)
  console.log(name)

  action.value = 'Edit ' + name // item.name

  // const data = await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/getTeamInfo' + '/' + store.OrgID + '/' + id).then((res) => res.json())

  // headCoach.value = data.head_coach
  // assistantCoaches.value = data.assistant_coaches
  // minage.value = data.min_age
  // maxage.value = data.max_age
  // gender.value = data.gender
  // normalFormation.value = data.normal_formation
  // numPlayers.value = data.max_players
  // normalTimeOfGame.value = data.normal_time_of_game
  // console.log('formation: ' + normalFormation.value)
  // getPlayerList()

  showEdit.value = true
  reallyEdit.value = true
}

const deleteCoach = async (item) => {
  // const index = teams.value.indexOf(item)
  const del = confirm('Are you sure you want to delete the coach ' + item.name + '?')

  if (del) {
    // await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/removeTeam/' + item.id).then((res) => res.json())

    // // FIXME check for errors?
    // showEdit.value = false
    // selectPlayers.value = false
    // reallyEdit.value = false
    // updateTeamList()
  }
}

const cancelEdit = () => {
  showEdit.value = false
  reallyEdit.value = false
}

const save = async () => {
  // perform save
  // const Gamedata = {
  //   SeasonID: store.TeamSeason,
  //   OrgID: store.OrgID,
  //   TeamName: editTeamName.value,
  //   TeamID: Number(teamid.value),
  //   HeadCoach: headCoach.value,
  //   AssistantCoaches: assistantCoaches.value,
  //   MinAge: Number(minage.value),
  //   MaxAge: Number(maxage.value),
  //   Gender: gender.value,
  //   Players: selectedPlayers.value,
  //   Edit: reallyEdit.value,
  //   NumPlayers: Number(numPlayers.value),
  //   NormalFormation: normalFormation.value,
  //   NormalTimeOfGame: Number(normalTimeOfGame.value)
  // }
  // const dataJson = JSON.stringify(Gamedata)

  // console.log(dataJson)

  // await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/addTeam', {
  //   method: 'POST',
  //   headers: { 'Content-Type': 'application/json', 'Authorization': 'BEARER: ' + store.AuthToken },
  //   body: dataJson
  // }).then((res) => res.json())

  // FIXME check for errors?
  showEdit.value = false
  reallyEdit.value = false
}

onMounted(() => {
  getCoachList()
})

</script>

import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
// import store from './store'

import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

// Firebase
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as baseComponents from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import '@mdi/font/css/materialdesignicons.css'
// vue-datepicker
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

/* code from our Firebase console */
const firebaseConfig = {
  apiKey: 'AIzaSyBELCH33CBSOtiXqIuFAz1GsoFs85yyqs0',
  authDomain: 'tryouts-dev.firebaseapp.com',
  projectId: 'tryouts-dev',
  storageBucket: 'tryouts-dev.appspot.com',
  messagingSenderId: '528013085121',
  appId: '1:528013085121:web:a52bd589b5a819f5fc0740',
  measurementId: 'G-D5830M120C'
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

// initialize firebase auth
const auth = getAuth(app)
export { app, auth }

const vuetify = createVuetify({
    components: {
      // ...labs,
      ...baseComponents,
      VueDatePicker
    },
    directives,
    icons: {
      defaultSet: 'mdi',
      aliases,
      sets: {
        mdi
      }
    },
    theme: {
      defaultTheme: 'light',
      themes: {
        dark: {
          dark: true,
          colors: {
            background: '#616161', // background
            surface: '#424242', // foreground
            primary: '#0096FF',
            secondary: '#03DAC6'
          }
        },
        light: {
          dark: false,
          colors: {
            background: '#FFFFFF', //'#C0C8D1', //'#EEEEEE', // '#FFFFFF', // background
            surface: '#ABC8CE', //'#C0C8D1', //'#E0E0E0', // foreground
            primary: '#1867C0', //'#0096FF',
            secondary: '#5CBBF6', // '#03DAC6'
            sidebar: '#ABC8CE',
          }
        }
      }
    }
  })

createApp(App).use(router).use(pinia).use(vuetify).mount('#app')

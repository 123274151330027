<template>
  <v-container class="pa-0 ma-0">

    <v-card class="pa-0 ma-0">
      <v-card-title>Settings for the {{orgData.name}} {{season}} season</v-card-title>
      <v-card-text>

        <v-row>
          <v-col cols="auto" xs="2">
            <v-item-group>
              <v-item>
                <v-chip @click="switchTab('teams')">Team Management</v-chip>
              </v-item>
              <v-item>
                <v-chip @click="switchTab('coaches')">Coaches Management</v-chip>
              </v-item>
              <v-item>
                <v-chip @click="switchTab('players')">Player Management</v-chip>
              </v-item>
              <v-item>
                <v-chip @click="switchTab('season')">Season Management</v-chip>
              </v-item>
              <v-item>
                <v-chip @click="switchTab('formations')">Formation Management</v-chip>
              </v-item>
              <v-item>
                <v-chip @click="switchTab('users')">User Management</v-chip>
              </v-item>
            </v-item-group>
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>

    <!-- <v-card class="pa-0 ma-0">
      <v-card-text> -->
        <OrgTeam v-if="displayTab == 'teams'"></OrgTeam>
        <OrgPlayers v-if="displayTab == 'players'"></OrgPlayers>
        <OrgCoaches v-if="displayTab == 'coaches'"></OrgCoaches>
        <OrgSeason v-if="displayTab == 'season'"></OrgSeason>
        <OrgFormations v-if="displayTab == 'formations'"></OrgFormations>
        <OrgUsers v-if="displayTab == 'users'"></OrgUsers>
      <!-- </v-card-text>
    </v-card> -->

  </v-container>
</template>

<script lang="ts" setup>
import OrgTeam from '../components/OrgTeam.vue'
import OrgPlayers from '../components/OrgPlayers.vue'
import OrgCoaches from '../components/OrgCoaches.vue'
import OrgSeason from '../components/OrgSeason.vue'
import OrgUsers from '../components/OrgUsers.vue'
import OrgFormations from '../components/OrgFormations.vue'
import { onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useGameState } from '../store/index'
import { getPerms } from '../composables/userPerms'
import { logActivity } from '@/composables/telemetry'
const store = useGameState()
const route = useRoute()
const router = useRouter()

const orgData = ref({})
let season = ''
const displayTab = ref('')

const switchTab = async (tab) => {
  displayTab.value = tab
}

const getOrgInfo = async () => {
  orgData.value = await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/getOrgInfo' + '/' + store.OrgID).then((res) => res.json())
}

const checkPermissions = async () => {
  const res = await getPerms(store)

  if ((res.RBAC !== 'OrgManager') && (res.RBAC !== 'GlobalAdmin')) {
    alert('Sorry, you don\'t have permission to be here..')
    router.push({ path: '/' })
  }
}

onMounted(() => {
  checkPermissions()
  season = store.TeamSeason
  displayTab.value = 'teams'
  store.setActivePage(route.name)

  getOrgInfo()

  logActivity(store, route.name, 'myOrg.vue')
})
</script>

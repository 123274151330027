<template>
    <v-card class="pa-0 ma-0">
                <v-card-title class="pa-0 ma-1" elevation="0">
                    Sub out {{Player.name || Player.position}}
                    <!--
                    <v-spacer></v-spacer>
                    <v-btn @click="close()" color="amber" text>Close</v-btn>
                    -->
                </v-card-title>
                <v-card-text class="pa-0 ma-0">

                    <v-spacer>&nbsp;&nbsp;</v-spacer>

                    <v-card class="pa-0 ma-1" elevation="0">
                        <v-card-title class="pa-0 ma-0">
                            Bench Players
                        </v-card-title>
                        <v-card-text>
                            <v-row v-for="r in Bench" v-bind:key="r.RowID" class="pa-0 ma-0">
                                <v-col v-for="c in r.Cols" v-bind:key="c.id" class="pa-0 ma-0">
                                    <div @click="subIn(r, c, false)" v-if="!c.Subbing">
                                        <playerChip :Player="c" />
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>

                    <v-spacer>&nbsp;&nbsp;</v-spacer>

                    <v-card class="pa-0 ma-1" elevation="0">
                        <v-card-title class="pa-0 ma-0">
                            In Game Subs
                        </v-card-title>
                        <v-card-text class="pa-0 ma-0">
                            <v-row v-for="r in InGame" v-bind:key="r.RowID" class="pa-0 ma-0">
                                <v-col v-for="c in r.Cols" v-bind:key="c.id" class="pa-0 ma-0">
                                    <div @click="subIn(r, c, true)" class="pa-0 ma-0" v-if="!c.Subbing">
                                        <playerChip :Player="c" />
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>

                </v-card-text>
            <!--
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="close()" color="amber" text>Close</v-btn>
                </v-card-actions>
            -->
            </v-card>
</template>

<script lang="ts" setup>
import { useGameState } from '../store/index'
import { defineProps, defineEmits } from 'vue'
import playerChip from './playerChip.vue'

const store = useGameState()

const props = defineProps(['Player', 'InGame', 'Bench'])
const emits = defineEmits(['cb'])
// const props = {
//   Player: {
//     RowID: Number,
//     id: Number,
//     name: String,
//     position: String
//   },
//   InGame: [{}],
//   Bench: [{}]
// }

const subIn = (r, p, inGameSub) => {
  if (p.Subing) {
    // user already subbing
    return
  }

  const subs = store.subs || []

  const subdata = {
    PositionX: props.Player.RowID,
    PositionY: props.Player.id,
    OldPositionID: p.id,
    OldPlayer: props.Player.name,
    OldPlayerNewPosition: p.position,
    // NewPlayerPositionID: this.Player.id,
    NewPlayer: p.name,
    NewPosition: props.Player.position
  }
  subs.push(subdata)

  if (inGameSub) {
    // set the inverse of previous sub
    const subdata = {
      PositionX: r.RowID,
      PositionY: p.id,
      OldPositionID: props.Player.id,
      OldPlayer: p.name,
      OldPlayerNewPosition: props.Player.position,
      // NewPlayerPositionID: p.id,
      NewPlayer: props.Player.name,
      NewPosition: p.position
    }
    subs.push(subdata)
  }
  store.subPush(subs)
  emits('cb', p)
}
</script>

<template>
<v-container class="pa-4">
  <!-- <v-sheet color="background"> -->

    <!-- <v-row class="pa-0 ma-0">
      <v-col class="pa-0 ma-0" cols="12"> -->
        <GameLogStatInput v-if="!viewonly"/>
      <!-- </v-col>
    </v-row> -->

    <!-- <v-row>
      <v-col>
        <v-spacer>
        </v-spacer>
      </v-col>
    </v-row> -->

    <!-- <v-row class="pa-0 ma-0">
      <v-col class="pa-1 ma-0" cols="12">
        <GameInfo />
      </v-col>
    </v-row> -->

  <!-- </v-sheet> -->
</v-container>
</template>

<script setup lang="ts">
// import GameInfo from './GameInfo.vue'
import GameLogStatInput from './GameLogStatInput.vue'
import { ref } from '@vue/reactivity'
import { useGameState } from '../store/index'
import { onMounted } from 'vue'
const store = useGameState()

const viewonly = ref(false)

onMounted(() => {
  viewonly.value = store.ViewOnly
})
</script>

<template>
  <v-container class="pa-0 ma-0">

    <v-card v-if="!showEdit" class="pa-0 ma-0">
        <v-card-text class="pa-0 ma-0">
            <v-data-table
              :headers="formationHeaders"
              :items="formations"
              class="elevation-1 py-0 px-0"
              :items-per-page="15"
              mobile-breakpoint="0"
              :fixed-header=true
              :sort-by="[{key: 'sortBy', order: 'asc'}]"
              dense
            >
            <!-- group-by="NumPlayers" -->

                <template v-slot:top>
                  <v-toolbar flat>
                    <div class="d-flex w-100">
                      <v-btn
                        rounded="xl"
                        color="primary"
                        @click="addFormation">
                        <v-icon dark>mdi-plus</v-icon>Add Formation
                      </v-btn>
                    </div>
                  </v-toolbar>
                </template>

                <template v-slot:[`item.formname`]="{ item }">
                  <v-text-field v-model="editedFormation.name" :hide-details="true" dense single-line :autofocus="true" v-if="item.id === editedFormation.id"></v-text-field>
                  <span v-else>{{item.name}}</span>
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon color="green" class="mr-3" @click="editFormation(item)">
                    mdi-pencil
                  </v-icon>
                  <v-icon color="red" @click="deleteFormation(item)">
                    mdi-delete
                  </v-icon>
                </template>

            </v-data-table>
        </v-card-text>
    </v-card>

    <v-card v-if="showEdit" class="pa-0 ma-0">
        <FormationAdd :Org="true" :NumPlayers="numPlayers" :ID="formationID" />
    </v-card>

  </v-container>
</template>

<script lang="ts" setup>
import FormationAdd from './FormationAdd.vue'
import { ref } from '@vue/reactivity'
// import { useRoute } from 'vue-router'
import { useGameState } from '../store/index'
import { onMounted } from 'vue-demi'
const store = useGameState()
// const route = useRoute()

const formationHeaders = [
  { title: 'Formation Name', key: 'Name', align: 'start', sortable: true, width: '25px' },
  { title: 'Is Template', key: 'Template', sortable: true, width: '25px' },
  { title: 'Number of Players', key: 'NumPlayers', sortable: true, width: '25px' },
  { title: 'Actions', key: 'actions', sortable: false, width: '25px' }
]
const formations = ref([])
const editedFormation = {
  id: 0,
  name: ''
}
const showEdit = ref(false)
const editCurrent = ref(false)
const formationID = ref(0)
const editFormationName = ref('')
const action = ref('Add Formation')
const numPlayers = ref(4)

const getBaseFormations = async () => {
  // get updated current state
  formations.value = await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/gm/getBaseFormations/' + store.OrgID + '/all').then((res) => res.json())
}

const addFormation = () => {
  showEdit.value = true
  action.value = 'Add Formation'
}

const editFormation = (item) => {
  // alert('editFormation() needs work')
  action.value = 'Edit ' + item.raw.Formation
  editFormationName.value = item.raw.Formation
  numPlayers.value = item.raw.NumPlayers
  formationID.value = item.raw.ID
  showEdit.value = true
  editCurrent.value = true
}

const deleteFormation = (item) => {
  const del = confirm('Are you sure you want to delete this item?')
  if (del) {
    alert('delete ' + item.Formation)
  }
  alert('Deleting formations is not working yet')
}

// const cancelEdit = () => {
//   showEdit.value = false
//   editCurrent.value = false
// }

// const save = () => {
// perform save
// let Gamedata = {
//   NewSeason: this.editSeasonName,
//   OrgID: store.OrgID,
//   SeasonID: store.SeasonName,
//   Edit: this.editCurrent
// }
// let dataJson = JSON.stringify(Gamedata)
// try {
//   this.$fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/createSeason', dataJson)
// } catch (err) {
//   console.log(err)
// } finally {
//   this.showEdit = false
//   this.editCurrent = false
// }

onMounted(() => {
  getBaseFormations()
})

</script>

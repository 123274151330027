<template>
  <v-card class="pa-0 ma-" color="background" rounded="xl" elevation="0">
    <v-card-title class="pa-0 ma-0">
      {{ Team }} vs {{ opponent }}
    </v-card-title>

    <v-card-text class="pa-0 ma-4">
      <v-row>
        <v-col md="auto" lg="5" cols="12">
          <v-card class="pa-0 ma-4" rounded="xl" max-width="500">
            <v-card-text>
              <v-text-field v-model="opponent" label="Opponent" variant="underlined" ></v-text-field>
              <v-text-field v-model="GameField" label="Field" variant="underlined"></v-text-field>
              <v-text-field v-model="datePicker2" label="Game Date" variant="underlined" @click="showDatePick = !showDatePick"></v-text-field>
              <VueDatePicker v-model="datePicker2"  inline text-input inline-with-input v-if="showDatePick" @update:model-value="closeDate()"/>
              <v-select v-model="formation" :items="formations" item-title="Name" item-value="ID" label="Game Formation" variant="underlined"></v-select>
              <v-checkbox v-model="scrimmage" label="Scrimmage"></v-checkbox>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col md="auto" lg="6" cols="12">
          <v-card class="pa-0 ma-4" rounded="xl" max-width="600">
            <v-card-text>
              <PlayerList @roster="getRoster" />
              <br />
              <v-btn rounded="xl" @click="createGame()" color="green" :disabled="formation === ''">Create Game</v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      
      <!-- <v-spacer >&nbsp;</v-spacer> -->

      
    </v-card-text>
  </v-card>
</template>

<script lang="ts" setup>
import PlayerList from './playerList.vue'
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useGameState } from '../store/index'
const store = useGameState()
// const route = useRoute()
const router = useRouter()

const Team = ref('')
const opponent = ref('')
const GameField = ref('')
const showDatePick = ref(false)
const datePicker2 = ref()
const teamRoster = ref([{
  player_id: '',
  name: '',
  first_name: '',
  last_name: '',
  jersey_number: 0,
  player_status: '',
  guest_player: false,
  Team: '',
  age: 0,
  gender: ''
}])
const formation = ref('')
const formations = ref([])
const scrimmage = ref(false)

const getRoster = (data) => {
  teamRoster.value = data
}

const datePicker = (d) => {
//   const d = new Date(2010, 7, 5)
  const ye = new Intl.DateTimeFormat('en', { year: 'numeric', timeZone: store.timeZone }).format(d)
  const mo = new Intl.DateTimeFormat('en', { month: 'short', timeZone: store.timeZone }).format(d)
  const da = new Intl.DateTimeFormat('en', { day: 'numeric', timeZone: store.timeZone }).format(d)
  const t = new Intl.DateTimeFormat('en', { hour: 'numeric', minute: 'numeric', timeZone: store.timeZone }).format(d)
  return da + '-' + mo + '-' + ye + ' ' + t // hr + ':' + mn
}

const createGame = async () => {
  const Gamedata = {
    opponent: opponent.value,
    game_date: datePicker(datePicker2.value),
    game_field: GameField.value,
    formationid: formation.value,
    scrimmage: scrimmage.value
  }
  const dataJson = JSON.stringify(Gamedata)
  let newGameID = {}

  try {
    newGameID = await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/gm/createGame/' + store.OrgID + '/' + store.TeamID, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'BEARER: ' + store.AuthToken },
      body: dataJson
    }).then((res) => res.text())
  } catch (e) {
    console.log(e)
  } finally {
    const roster = JSON.stringify(teamRoster.value)

    // set the roster for the game
    await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/gm/createGameRoster/' + store.OrgID + '/' + store.TeamID + '/' + newGameID, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'BEARER: ' + store.AuthToken },
      body: roster
    }).then((res) => res.json())
  }

  router.push({ path: '/' })
}

const closeDate = () => {
  showDatePick.value = false
}

const getFormations = async () => {
  // get updated current state
  // formations.value = await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/gm/getFormations/' + store.OrgID + '/' + store.TeamID).then((res) => res.json())
  formations.value = await fetch(process.env.VUE_APP_API_BACKEND_URL + '/api/gm/getFormations/' + store.OrgID + '/' + store.teamFormationSize).then((res) => res.json())
}

onMounted(() => {
  Team.value = store.TeamName
  getFormations()
})

</script>

<template>
<div v-if="Player.position != null">
  <playerChip :Player="Player" @cb="viewPlayer" />

  <v-dialog
    v-model="playerDialog"
    max-width="350"
  >
    <playerInfo :Player="Player" @cb="infoCallback"/>
  </v-dialog>

  <v-dialog
    v-model="subDialog"
    max-width="350"
  >
    <subDialogComp :Player="Player" :InGame="InGame" :Bench="Bench" @cb="subCallback"/>
  </v-dialog>
</div>
</template>

<script lang="ts" setup>
import playerInfo from './playerInfo_dialog.vue'
import subDialogComp from './sub_dialog.vue'
import playerChip from './playerChip.vue'
import { ref } from '@vue/reactivity'
import { defineProps } from 'vue'

const props = defineProps(['Player', 'InGame', 'Bench'])
// const props = {
//   Player: {
//     position: String
//   },
//   InGame: [{}],
//   Bench: [{}]
// }

const subDialog = ref(false)
const playerDialog = ref(false)

const viewPlayer = () => {
  playerDialog.value = true
}

const subPlayer = () => {
  playerDialog.value = false
  subDialog.value = true
}

const infoCallback = (action, player) => {
  if (action === 'subout') {
    subPlayer()
  } else if (action === 'close') {
    close()
  }
  // console.log('action: ')
  // console.log(action)
  // console.log('player: ')
  // console.log(player)
}

const subCallback = () => {
  close()
}

const close = () => {
  playerDialog.value = false
  subDialog.value = false
}
</script>
